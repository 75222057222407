import { Box, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import debtReliefOnTheWay from "@assets/debtReliefLady_optimized.jpg";
import { Text, PlanCardByJourney } from "@components/common";
import { useStore } from "@stores";
import { theme } from "@styles";

export const HelpViewB = observer(() => {
  const { t } = useTranslation("call_for_help_pageB");
  const {
    profileStore: { profile },
  } = useStore();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    localStorage.removeItem("hasSeenConfetti");
  }, []);

  return (
    <Box position={{ xs: "static", md: "relative" }}>
      <Box
        position="absolute"
        top={0}
        left={0}
        bottom="300px"
        width="100%"
        height="500px"
        sx={{
          backgroundImage: `url(${debtReliefOnTheWay})`,
          backgroundPosition: { xs: "20% 20%", md: "0% 25%" },
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          "@media (width: 375px)": {
            height: "75vh",
          },
          "@media (min-width: 390px) and (max-width: 420px)": {
            height: "65vh",
          },
          [theme.breakpoints.up("md")]: {
            maxHeight: "100%",
          },
        }}
      />

      <Box
        position="relative"
        alignItems="center"
        gap={1}
        sx={{
          [theme.breakpoints.up("md")]: {
            mx: "auto",
            width: "80%",
            maxWidth: "900px",
            padding: "1.5rem",
          },
        }}
      >
        <Box display="flex" flexDirection="column" gap={3} width={{ xs: "100%", md: "45%" }} mt={2}>
          {isDesktop ? (
            <Text fontSize="xxxlarge" fontWeight="semiBold">
              {t("header")}
            </Text>
          ) : (
            <Text fontSize="xxxxlarge" fontWeight="semiBold" lineHeight="xxxxxlarge" whiteSpace="pre-line" width="65%">
              {t("header")}
            </Text>
          )}

          <Text
            fontSize="xlarge"
            fontWeight="light"
            lineHeight="medium"
            whiteSpace="pre-line"
            width="75%"
            sx={{
              "@media (min-width: 768px) and (max-width: 900px)": {
                width: "55%",
              },
            }}
          >
            {t("subHeader", {
              servicer: profile?.servicer?.displayName,
            })}
          </Text>

          <PlanCardByJourney showStatus condensed showConfetti={false} />
        </Box>
      </Box>
    </Box>
  );
});
