export class ErrorBase<T extends string> extends Error {
  name: T;
  message: string;
  cause: Error | undefined;

  constructor({ name, message, cause }: { name: T; message: string; cause?: Error }) {
    super();
    this.name = name;
    this.message = message;
    this.cause = cause;
  }
}
