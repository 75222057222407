import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    padding: "16px 0px",
    textAlign: "center",
    margin: "0 auto",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  linkContainer: {
    display: "flex",
    gap: "1rem",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px",
    color: theme.palette.brandGray.main,
  },
}));
