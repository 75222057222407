import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  sidebarViewContainer: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    boxShadow: theme.customShadows.header,
    alignItems: "flex-start",
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      margin: "1rem",
      alignSelf: "flex-start",
      textAlign: "center",
      minHeight: "calc(100vh - 2rem)",
      minWidth: "304px",
      height: "100%",
      width: "100%",
      flex: "0 0 18rem",
      borderRadius: "20px",
      flexDirection: "column",
      padding: "40px",
      boxShadow: theme.customShadows.clickable,
    },
  },

  svgContainer: {
    alignItems: "center",
    position: "relative",
    display: "flex",

    "&:hover": {
      cursor: "pointer",
    },
    "& .svgElement": {
      background: "url(logoMobile.svg)",
      height: "111px",
      width: "320px",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
      "& .svgElement": {
        background: "url(logo.svg)",
        height: "105px",
        width: "155px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "155px 155px",
        backgroundPosition: "center",
      },
    },
  },

  svgText: {
    paddingLeft: "19px",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
      paddingLeft: "unset",
      paddingTop: "24px",
    },
  },

  caption: {
    paddingTop: "unset",
    [theme.breakpoints.up("md")]: {
      paddingTop: "8px",
    },
  },

  svgBrandLogo: {
    position: "relative",
    display: "flex",
    "& .svgElement": {
      background: "url(dcom.svg)",
      height: "70px",
      width: "70px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "115px 115px",
      backgroundPosition: "center",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "auto",
      display: "flex",
      marginLeft: "-24px",
      "& .svgElement": {
        background: "ur(dcom).svg)",
        width: "89px",
        height: "44px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "89px 44px",
        backgroundPosition: "center",
      },
    },
  },
  copyright: {
    marginBottom: "1.5vh",
  },
}));
