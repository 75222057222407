import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import { Resolver } from "react-hook-form";
import * as Yup from "yup";

import { BudgetItemData, BudgetType, IBudgetItemData } from "./budgetTool";

export type Address = {
  street_line: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
  entries: number;
};

export interface base {}
export interface CreditAuthForm extends base {
  address: Address;
  dob: string;
  ssn: string;
}

export interface PlanSelectionForm extends base {
  plan: { id: string; description: string };
}

export interface BudgetItemForm extends base {
  data: BudgetItemData;
}

export interface PartialCreditAuthFormType {
  address: {
    street_line: string;
    secondary: string;
    city: string;
    state: string;
    zipcode: string;
    entries: number;
  };
  dob: string;
  ssn: string;
}

export type FORM_TYPES = PartialCreditAuthFormType | PlanSelectionForm | CreditAuthForm | BudgetType<IBudgetItemData[]>;

export interface FormResolver {
  defaultValues: base | undefined;
  resolver: Resolver<FORM_TYPES>;
}

export interface PlanSelectionFormResolver {
  defaultValues: base | undefined;
  resolver: Resolver<PlanSelectionForm>;
}

export interface BudgetToolFormResolver {
  defaultValues: base | undefined;
  resolver: Resolver<BudgetType<IBudgetItemData[]>>;
}

export const creditAuthFormValue = {
  address: {
    street_line: "",
    secondary: "",
    city: "",
    state: "",
    zipcode: "",
    entries: 0,
  },
  dob: "",
  ssn: "",
};

export const selectPlanFormValue = {
  plan: {
    id: "",
    description: "",
  },
};

export const budgetToolFormValue = {
  income: [],
  debt: [],
  expenses: [],
};

export const creditAuthFormSchema = (t: TFunction, enableSsnValidation: boolean) => {
  // MM/DD/YYYY
  const DATE_REGEX = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

  return Yup.object<CreditAuthForm>().shape({
    address: Yup.object<Address>()
      .shape({
        street_line: Yup.string(),
        secondary: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zipcode: Yup.string(),
        entries: Yup.number(),
      })
      .required()
      .label("Address")
      .test("test-name", "test", function (value) {
        const { createError } = this;
        if (!value.street_line) {
          return createError({ message: "Address is required" });
        }
        return true;
      }),
    dob: Yup.string()
      .label(t("dobLabel"))
      .trim()
      .required(t("form.dob:required"))
      .matches(DATE_REGEX, t("form.dob:invalidDate")),
    ssn: Yup.string()
      .label("SSN/ITIN")
      .trim()
      .required(t("form.ssn:required"))
      .length(9, t("form.ssn:invalid"))
      .test("test-name", "Some message", function (value) {
        const regex = /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;
        if (!enableSsnValidation) {
          return true;
        } else {
          if (!regex.test(value)) {
            return this.createError({ message: t("form.ssn:invalid") });
          } else {
            return true;
          }
        }
      }),
  });
};
export const planSelectionFormSchema = () => {
  return Yup.object<PlanSelectionForm>().shape({
    plan: Yup.object().shape({ id: Yup.string() }),
  });
};

export const budgetToolFormSchema = () => {
  const itemSchema = Yup.object().shape({
    category: Yup.string().required(),
    amount: Yup.number().required().min(0),
    frequency: Yup.string().required(),
  });

  return Yup.object().shape({
    income: Yup.array().of(itemSchema),
    debt: Yup.array().of(itemSchema),
    expenses: Yup.array().of(itemSchema),
  });
};
export const budgetItemFormSchema = (t: TFunction) =>
  Yup.object().shape({
    id: Yup.string(),
    subCategory: Yup.string(),
    frequency: Yup.string(),
    type: Yup.string(),
    name: Yup.string().required(t("requiredError")).max(40, t("nameMaxError")),
    amount: Yup.mixed<string | number>()
      .test("custom-validation", t("requiredError"), function (v) {
        if (v === "") {
          return true; // Allow empty string
        }
        v = parseInt(`${v}`);
        if (isNaN(v as number)) {
          return this.createError({ message: t("requiredError") });
        }
        if (typeof v === "number") {
          if (v < 0) {
            return this.createError({ message: t("amountMinError") });
          }
          if (v > 2000000) {
            return this.createError({
              message: t("amountMaxError"),
            });
          }
          return true;
        }
        return false; // For all other cases, return false
      })
      .required(t("requiredError")),
  });

const getSchema = (name: string, t: TFunction, enableSsnValidation: boolean) => {
  let schema;
  let defaultValues;
  switch (name) {
    case "CreditModule":
      schema = creditAuthFormSchema(t, enableSsnValidation);
      defaultValues = creditAuthFormValue;
      return { defaultValues, resolver: yupResolver(schema) };
    case "BudgetTool":
      schema = budgetToolFormSchema();
      defaultValues = budgetToolFormValue;
      return { defaultValues, resolver: yupResolver(schema) };
    case "SelectPlan":
      defaultValues = selectPlanFormValue;
      schema = planSelectionFormSchema();
      return { defaultValues, resolver: yupResolver(schema) };
  }
};

export const initializeForm = (name: string, t: TFunction, enableSsnValidation: boolean): FormResolver => {
  return <FormResolver>getSchema(name, t, enableSsnValidation);
};
