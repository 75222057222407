import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import helpOnTheWay from "../../../assets/callforhelplady.png";

export const useStyles = makeStyles((theme: Theme) => ({
  helpViewBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: "300px",
    width: "100%",
    height: "50vh",
    backgroundImage: `url(${helpOnTheWay})`,
    backgroundPosition: "28% 18%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("md")]: {
      backgroundPosition: "0% 26%",
      backgroundSize: "cover",
      position: "relative",
      maxHeight: "100%",
      height: "40vh",
      overflow: "hidden",
    },
  },
  mainContainer: {
    width: "100%",
    height: "500px",
    alignItems: "center",
    gap: "1rem",
    marginTop: "100px",
    padding: "0rem 3rem 0rem 3rem",
    maxHeight: "450px",
    overflow: "auto",
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
      width: "80%",
      maxWidth: "900px",
      padding: "1.5rem",
      gap: "2rem",
    },
  },

  helpContent: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    minWidth: "100%",
    width: "50%",
    [theme.breakpoints.up("md")]: {
      minWidth: "50%",
    },
  },

  header: {
    width: "100%",
    [theme.breakpoints.up("md")]: {},
  },
  container: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "8px",
    boxShadow: theme.customShadows.clickable,
    minWidth: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  slogan: {
    width: "75%",
  },
  newLine: {
    whiteSpace: "pre-line",
  },
}));
