import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  errorContainer: {
    justifyContent: "flex-start",
    gap: "8px",
    marginLeft: "0px",
    margin: "16px 24px",
    padding: "16px 22px",
    width: "100%",
    borderRadius: "4px",
    border: "solid 1px #e71815",
    backgroundColor: "#fff4f4",
  },
  errorHeader: {
    fontWeight: 500,
    fontSize: "16px !important",
    lineHeight: 1.38,
    color: `${theme.palette.error.main} !important`,
  },
  errorDescription: {
    lineHeight: 1.43,
    fontWeight: "normal",
    letterSpacing: "normal",
  },
}));
