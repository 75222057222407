import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import { VerificationHeader } from '@components/layout';
import { useStore } from '@stores';

import { useStyles } from './VerificationWrapper.styles';

export const VerificationWrapper = observer(() => {
  const { profileStore } = useStore();
  const { tempUser } = profileStore;
  const updateBackground = !!tempUser.email || !!tempUser.phone;
  const classes = useStyles({ updateBackground });

  return (
    <div className={classes.rootContainer}>
      <div className={classes.cardContainer}>
        <VerificationHeader />
        <Outlet />
      </div>
    </div>
  );
});
