import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    position: "absolute",
    top: "150px",
    gap: "8px",
    width: "100%",
    fontFamily: "Poppins",
    [theme.breakpoints.up("md")]: {
      top: 0,
      maxWidth: "500px",
    },
  },
}));
