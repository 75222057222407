import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    width: "100%",
  },

  inputWidth: {
    width: "100%",
  },
}));
