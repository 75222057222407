import { loadAll } from "@tsparticles/all";
import { tsParticles, Particle, Container } from "@tsparticles/engine";
import Particles from "@tsparticles/react";
import { useEffect, useState } from "react";
import "./Confetti.css";

interface CustomParticle extends Particle {
  wobble: {
    moveSpeed: number;
  };
  tilt: {
    velocity: number;
  };
  rotate: {
    velocity: number;
  };
}

export const Confetti = () => {
  const [init, setInit] = useState(false);

  const load = async () => {
    await loadAll(tsParticles);
    setTimeout(() => localStorage.setItem("hasSeenConfetti", "true"), 3000);
    setInit(true);
  };

  useEffect(() => {
    load();
  }, []);

  const slowDownParticles = async (container?: Container) => {
    if (container) {
      //@ts-expect-error _array is a private property
      const particles = container.particles._array as CustomParticle[];

      setTimeout(() => {
        const interval = setInterval(() => {
          let allStopped = true;

          // slows down particles until they almost reach 0
          particles.forEach((p) => {
            if (
              p.velocity.x >= 0.01 ||
              p.velocity.y >= 0.01 ||
              p.roll!.speed >= 0.01 ||
              p.tilt.velocity >= 0.01 ||
              p.rotate.velocity >= 0.01
            ) {
              p.velocity.x *= 0.75;
              p.velocity.y *= 0.75;
              p.wobble.moveSpeed *= 0.85;
              p.roll!.speed *= 0.85;
              p.tilt.velocity *= 0.85;
              p.rotate.velocity *= 0.85;

              allStopped = false;
            }
          });

          if (allStopped) {
            clearInterval(interval);
            container.pause();
          }
        }, 100);
      }, 2000); // Start decay after 2 seconds
    }
  };

  if (!init) return <></>;

  return (
    <Particles
      id="tsparticles"
      particlesLoaded={slowDownParticles}
      options={{
        id: "tsparticles",
        fullScreen: {
          zIndex: 0,
          enable: false,
        },
        particles: {
          color: {
            value: ["#4589FF", "#ffdb17", "#ff8f00", "#d0000b", "#00a21f"],
          },
          move: {
            direction: "bottom",
            enable: true,
            outModes: {
              default: "out",
            },
            size: true,
            speed: {
              min: 3,
              max: 5,
            },
          },
          opacity: {
            value: 1,
            animation: {
              enable: false,
              startValue: "max",
              destroy: "min",
              speed: 0.3,
              sync: true,
            },
          },
          rotate: {
            value: {
              min: 0,
              max: 360,
            },
            direction: "random",
            animation: {
              enable: true,
              speed: 60,
            },
          },
          tilt: {
            direction: "random",
            enable: true,
            value: {
              min: 0,
              max: 360,
            },
            animation: {
              enable: true,
              speed: 60,
            },
          },
          number: { value: 75 },
          shape: {
            type: ["circle", "square"],
          },
          size: {
            value: {
              min: 5,
              max: 7,
            },
          },
          roll: {
            enlighten: {
              enable: true,
              value: 10,
            },
            enable: true,
            speed: {
              min: 15,
              max: 25,
            },
          },
          wobble: {
            enable: true,
            speed: {
              min: -15,
              max: 15,
            },
          },
        },
      }}
    />
  );
};
