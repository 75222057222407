import { Grid, Button, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { patternFormatter } from 'react-number-format';

import { ButtonBranded, Text } from '@components/common';
import { CloseButton } from '@components/common/CloseButton';
import { ScrollIndicator } from '@components/common/ScrollIndicator';
import { useStore } from '@stores';
import { FORM_TYPES, JourneyId, VTPageTitlesEnum } from '@types';
import {
  GTMCreditConsent,
  getServicerLogo,
  getServicerPhoneNumber,
  handlePercentageAndPageCompleted,
} from '@utils';

import { DrawerModal } from '../../../common/DrawerModal';
import { TermsEnVersion, TermsEsVersion } from '../../../layout/Footer';

interface IInfoShareAgreementModalProps {
  showModal?: boolean;
  methods?: UseFormReturn<FORM_TYPES>;
  onSubmit?: SubmitHandler<FORM_TYPES> | (() => void);
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InfoShareAgreementModal = ({
  showModal = false,
  methods,
  onSubmit,
  setShowModal,
}: IInfoShareAgreementModalProps) => {
  const { t } = useTranslation('info_share_agreement');
  const theme = useTheme();
  const {
    profileStore,
    engagementStore,
    remoteConfigStore: { featureFlags },
  } = useStore();
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAgreementRead, setIsAgreementRead] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const servicer = profileStore.profile!.servicer!;

  const servicerLogo = getServicerLogo(servicer!.id);

  const handleClickAway = () => {
    setShowTermsOfUse(false);
    setShowPrivacyPolicy(false);
    setShowModal?.(true);
  };

  const displayTermsLangVersion = () => {
    if (engagementStore.engagement?.profile.language === 'es') {
      return <TermsEsVersion hideModal={handleClickAway} />;
    }
    return <TermsEnVersion hideModal={handleClickAway} />;
  };

  const displayPrivacyPolicyLangVersion = () => {
    if (engagementStore.engagement?.profile.language === 'es') {
      return (
        <TermsEsVersion
          hideModal={handleClickAway}
          scrollToPrivacyPolicyRef={true}
        />
      );
    }
    return (
      <TermsEnVersion
        hideModal={handleClickAway}
        scrollToPrivacyPolicyRef={true}
      />
    );
  };

  const displayServicerConsent = () => (
    <Grid
      container
      spacing={2}
      direction="column"
      height={isDesktop ? '100vh' : '75vh'}
      wrap="nowrap"
    >
      <Grid item>
        <Text
          fontSize="xlarge"
          fontWeight="semiBold"
          lineHeight="medium"
          color="darkerGray"
          sx={{ marginBottom: '1rem' }}
        >
          {t('header')}
        </Text>
      </Grid>
      <Grid item>
        <Text
          fontSize="small"
          fontWeight="light"
          lineHeight="xxsmall"
          color="darkerGray"
          mt="-.5rem !important"
        >
          {t('subHeader')}
        </Text>
      </Grid>
      <Grid item>
        <img
          src={servicerLogo}
          alt="servicerLogo"
          style={{
            maxWidth: '195px',
          }}
        />
      </Grid>
      <Grid
        item
        display="flex"
        bgcolor={theme.palette.brandGray.lighter}
        m={2}
        data-cy="disclaimerIdText"
        overflow="auto"
        id="disclaimerIdText"
      >
        <Text
          fontSize="xsmall"
          fontWeight="light"
          lineHeight="xxsmall"
          color="darkerGray"
          pr={2}
        >
          <Trans
            t={t}
            i18nKey="disclaimer"
            components={[
              <Button
                variant="text"
                key={0}
                href="mailto:admin@debt.com"
                sx={{
                  fontSize: theme.fontSizes.xsmall,
                }}
              />,
              <Button
                variant="text"
                onClick={() => {
                  setShowTermsOfUse(true);
                  setShowPrivacyPolicy(false);
                }}
                key={1}
              />,
              <Button
                variant="text"
                onClick={() => {
                  setShowPrivacyPolicy(true);
                  setShowTermsOfUse(false);
                }}
                key={2}
              />,
            ]}
            values={{
              servicer: servicer.displayName,
              buttonLabel: t('buttonLabel'),
              servicerPhone: patternFormatter(
                getServicerPhoneNumber().replace(/\D/g, ''),
                {
                  format: '#-###-###-####',
                }
              ),
              consumerName: profileStore.username,
            }}
          />
        </Text>
      </Grid>
      <Grid item pb={2}>
        <ButtonBranded
          data-cy="info-share-agreement-cont"
          disabled={!isAgreementRead}
          type="submit"
          label={t('buttonLabel')}
          loading={loading}
          onClick={async () => {
            setLoading(true);
            if (profileStore.journeyId === JourneyId.DEBT_SETTLEMENT) {
              GTMCreditConsent(servicer.displayName);
              await handlePercentageAndPageCompleted(
                featureFlags.enableBudgetTool,
                VTPageTitlesEnum.CREDIT_AUTHORIZATION,
                profileStore
              );
              if (onSubmit) {
                await methods?.handleSubmit(onSubmit)();
              }
            } else {
              (onSubmit as () => void)?.();
            }
            setShowModal?.(false);
            setLoading(false);
          }}
        />
      </Grid>
      <ScrollIndicator
        atBottomCallback={() => setIsAgreementRead(true)}
        spaceFromBottom={100}
        scrollDivId={'disclaimerIdText'}
      />
    </Grid>
  );

  const renderContent = () => {
    if (showTermsOfUse) return displayTermsLangVersion();
    if (showPrivacyPolicy) return displayPrivacyPolicyLangVersion();
    return displayServicerConsent();
  };

  return (
    <DrawerModal show={showModal} contentSX={{ overflow: 'hidden' }}>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '1rem 0',
        }}
      >
        <CloseButton onClick={() => setShowModal?.(false)} />
        {renderContent()}
      </Box>
    </DrawerModal>
  );
};

export default InfoShareAgreementModal;
