import { Chip, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@assets/icons/check.svg?react';
import CircleIcon from '@assets/icons/circle.svg?react';
import SquareIcon from '@assets/icons/square.svg?react';
import { theme } from '@styles';
import { BudgetStatusEnum } from '@types';

interface BudgetStatusProps {
  type: BudgetStatusEnum;
  'data-cy'?: string;
}

const statusStyles = {
  pending: {
    styles: {
      backgroundColor: alpha(theme.palette.brandBlue.dark!, 0.1),
      color: theme.palette.brandBlue.darker,
    },
    icon: <CircleIcon />,
  },
  started: {
    styles: {
      backgroundColor: theme.palette.brandBlue.dark,
      color: theme.palette.common.white,
    },
    icon: <SquareIcon />,
  },
  completed: {
    styles: {
      backgroundColor: theme.palette.brandGreen.dark,
      color: theme.palette.common.white,
    },
    icon: <CheckIcon />,
  },
};

export const BudgetStatus = ({
  type = BudgetStatusEnum.PENDING,
  'data-cy': dataCy,
}: BudgetStatusProps) => {
  const { t } = useTranslation('budget_tool', { keyPrefix: 'status' });

  return (
    <Chip
      sx={{
        ...statusStyles[type].styles,
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: 14,
        height: 24,
        '& .MuiChip-label': {
          marginTop: 0.25,
        },
        '& .MuiChip-icon': {
          color: statusStyles[type].styles.color,
          fontSize: 14,
          marginLeft: 1.5,
        },
      }}
      icon={statusStyles[type].icon}
      data-cy={`${dataCy}-${type.toString()}`}
      label={t(type)}
    />
  );
};
