import { Backdrop, Card, useMediaQuery } from '@mui/material';
import { FC } from 'react';

import { Loader, Slider } from '@components/common';
import { useStore } from '@stores';
import { theme } from '@styles';

import { useStyles } from './SlideModal.styles';

type SlideModalVariant = 'offRamp' | 'slide';

interface SlideModalPropsBase {
  children: React.ReactElement;
  show: boolean;
  variant?: SlideModalVariant;
}
interface SlideModalPropsWithoutClickAway extends SlideModalPropsBase {
  allowClickAway?: never;
  setShow?: never;
}
interface SlideModalPropsWithClickAway extends SlideModalPropsBase {
  allowClickAway: boolean;
  setShow?: () => void;
}

type SlideProps =
  | SlideModalPropsWithoutClickAway
  | SlideModalPropsWithClickAway;

export const SlideModal: FC<SlideProps> = ({
  allowClickAway = false,
  variant = 'slide',
  show,
  setShow,
  children,
}: SlideProps) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const { sessionStore } = useStore();

  const isLoading = sessionStore.loader.isLoading.get();

  return (
    <Backdrop
      className={classes.backdrop}
      open={show}
      onClick={() => allowClickAway && setShow?.()}
    >
      <Slider
        direction={isDesktop ? 'left' : 'up'}
        show={show}
        transitionTime={500}
      >
        <Card
          className={
            variant == 'offRamp' && isDesktop
              ? classes.offRampCard
              : classes.slideCard
          }
          elevation={4}
        >
          {isLoading && <Loader size={'4rem'} />}
          {!isLoading && children}
        </Card>
      </Slider>
    </Backdrop>
  );
};

export default SlideModal;
