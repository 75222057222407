import './styles/fonts';
import './styles/reset.css';
import './styles/freshchat.css';
import '../i18n.config.ts';

import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { Loader } from '@components/index.ts';
import { StoreContext, store } from '@stores';
import { theme } from '@styles';

import App from './App.tsx';
import { rollbar } from '../rollbarConfig';

createRoot(document.getElementById('root')!).render(
  <RollbarProvider instance={rollbar}>
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <StoreContext.Provider value={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StoreContext.Provider>
      </Suspense>
    </ErrorBoundary>
  </RollbarProvider>
);
