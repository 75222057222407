import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      gap: "1rem",
    },
    gap: "1.5rem",
  },
  button: {
    [theme.breakpoints.up("md")]: {
      padding: "12px 24px !important",
      fontSize: "1rem !important",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      gap: "0.5rem",
    },
    gap: "1rem",
  },
  timer: {
    backgroundColor: theme.palette.brandGray.other,
    padding: "2rem 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
