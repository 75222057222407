import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Grid, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text } from "@components/common";
import { useStore } from "@stores";
import { theme } from "@styles";

export const ChartNavigation = observer(() => {
  const { budgetStore } = useStore();
  const { t } = useTranslation("budget_tool");
  const { currGaugeCategoryIdx, gaugeCategories } = budgetStore;

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: "-110px" }} position="relative">
      <Grid item xs={2}>
        <IconButton
          aria-label="left"
          onClick={() =>
            budgetStore.setCurrGaugeCategory(
              (currGaugeCategoryIdx - 1) % gaugeCategories.length < 0
                ? gaugeCategories.length - 1
                : currGaugeCategoryIdx - 1,
            )
          }
          size="small"
          data-cy="budget-gauge-nav-left-button"
          sx={{
            backgroundColor: `${theme.palette.brandGreen.dark} !important`,
          }}
        >
          <ChevronLeftIcon sx={{ color: theme.palette.common.white }} />
        </IconButton>
      </Grid>
      <Grid item xs={4}>
        <Text
          data-testid="pie-chart-nav-amount-section"
          textTransform="uppercase"
          textAlign="center"
          fontWeight="semiBold"
          fontSize="xsmall"
        >
          {t(`gaugeNav.${gaugeCategories[currGaugeCategoryIdx]}`)}
        </Text>
      </Grid>
      <Grid item xs={2}>
        <IconButton
          aria-label="rigth"
          data-cy="budget-gauge-nav-right-button"
          size="small"
          onClick={() => budgetStore.setCurrGaugeCategory((currGaugeCategoryIdx + 1) % gaugeCategories.length)}
          sx={{
            backgroundColor: `${theme.palette.brandGreen.dark} !important`,
          }}
        >
          <ChevronRightIcon sx={{ color: theme.palette.common.white }} />
        </IconButton>
      </Grid>
    </Grid>
  );
});
