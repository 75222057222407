import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { LoaderProps } from ".";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: ({ fullScreen }: LoaderProps) => (fullScreen ? theme.palette.common.white : "transparent"),
    position: ({ fullScreen }: LoaderProps) => (fullScreen ? "fixed" : "relative"),
    zIndex: 999,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },

  content: {
    display: "flex",
    flexDirection: ({ labelPosition }: LoaderProps) => (labelPosition === "bottom" ? "column" : "column-reverse"),
    gap: 40,
    alignItems: "center",
  },

  loader: {
    border: "16px solid #f3f3f3",
    borderRadius: "50%",
    borderTop: "16px solid #7cb9e8",
    width: "120px",
    height: "120px",
    animation: `$Spinner 1s linear infinite`,
  },
  "@keyframes Spinner": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "30%": {
      borderTop: "16px solid black",
    },
    "60%": {
      borderTop: "16px solid green",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
