import { Box, Stack } from '@mui/material';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Text, UserPlanTag } from '@components/index.ts';
import { useStore } from '@stores';
import { theme } from '@styles';
import {
  JourneyName,
  MyPlanWorkflowOptions,
  MyPlanWorkflowOptionsEnum,
  UserPlanCardType,
} from '@types';
import {
  determineMyPlansPageVariant,
  formatAmountWithCurrencyUnit,
  formatDate,
  formatDateMMDDYYY,
  getServicerLogo,
  isPlanSelectedByUser,
  isPlanSelectionAbandoned,
  shouldShowMyPlanNavItem,
} from '@utils';

import { useStyles } from './UserPlans.styles.ts';

export const UserPlans = observer(() => {
  const [userPlanCardData, setUserPlanCardData] = useState<UserPlanCardType>();
  const [pageVariant, setPageVariant] = useState<MyPlanWorkflowOptions>(
    MyPlanWorkflowOptionsEnum.DEFAULT
  );
  const { profileStore } = useStore();
  const classes = useStyles({
    pageVariant,
    isSelected: false,
    pendingEnrollment: false,
    dropShadow: false,
  });

  const navigate = useNavigate();
  const { t } = useTranslation('user_plans');

  const leadType = profileStore.profile?.engagementLeadType;

  useEffect(() => {
    autorun(() => {
      const profile = profileStore.profile;
      if (profile) {
        if (!shouldShowMyPlanNavItem(profile)) {
          navigate('/dashboard');
          return;
        }
        setPageVariant(determineMyPlansPageVariant(profile));
        const debtSettlementJourney = profile.journeys?.['debt-settlement'];
        const debtManagementJourney = profile.journeys?.['debt-management'];
        if (debtSettlementJourney && debtSettlementJourney.selectedPlan) {
          const { totalEligibleDebt, submittedDate, selectedPlan } =
            debtSettlementJourney;
          setUserPlanCardData({
            planType: t('debtSettlement'),
            fileNumber: profile.fileNumber,
            submittedDate,
            profileCreatedDate: profile.profileCreatedDate,
            totalEligibleDebt,
            programTimePeriod: selectedPlan.programTimePeriod,
            monthlyPayment: selectedPlan.estimatedPmtWithLegal,
            servicerName: profile.servicer?.displayName,
            servicerLogo: getServicerLogo(profile.servicer!.id),
            isPlanSelectionAbandoned: isPlanSelectionAbandoned(profile),
            isPlanSelectedByUser: isPlanSelectedByUser(profile),
          });
        }
        else if (debtManagementJourney && debtManagementJourney.selectedPlan) {
          const { totalEligibleDebt, submittedDate, selectedPlan } =
            debtManagementJourney;
          setUserPlanCardData({
            planType: t('debtManagement'),
            fileNumber: profile.fileNumber,
            submittedDate,
            profileCreatedDate: profile.profileCreatedDate,
            totalEligibleDebt,
            programTimePeriod: selectedPlan.inputParameters.programLength,
            estimatedSavings: selectedPlan.estimatedSavings,
            monthlyPayment: selectedPlan.debtManagementPayment.monthlyPayment,
            servicerName: profile.servicer?.displayName,
            servicerLogo: getServicerLogo(profile.servicer!.id),
            isPlanSelectionAbandoned: isPlanSelectionAbandoned(profile),
            isPlanSelectedByUser: false,
          });
        }
      }
    });
  }, [profileStore.profile, navigate, t]);

  return (
    <Box>
      <Box className={classes.image}></Box>
      <Box className={classes.planCardWrapper}>
        <Box className={classes.content} data-cy="user-plan-card">
          <Box className={classes.card}>
            <Box
              display="flex"
              alignItems="center"
              bgcolor={theme.palette.brandDarkBlue.other}
              gap="8px"
              padding="8px 10px"
            >
              <Box
                display="block"
                bgcolor={theme.palette.brandYellow.main}
                borderRadius="50%"
                width="10px"
                height="10px"
              />
              <Text
                color="white"
                fontSize="xsmall"
                fontWeight="semiBold"
                data-cy="plan-card-heading"
              >
                {userPlanCardData?.isPlanSelectionAbandoned
                  ? t('idaDeclined')
                  : t('pending')}
              </Text>
            </Box>
            <Box px="16px" py="24px">
              {pageVariant === MyPlanWorkflowOptionsEnum.POSITIVE ? (
                <Stack gap="24px">
                  <Stack gap="8px">
                    <Text fontSize={'xxlarge'} fontWeight={'semiBold'}>
                      {t('myRelief')}
                    </Text>
                    <Text fontSize="small" color="darkerGray">
                      { leadType === JourneyName.DEBT_MANAGEMENT ? t('reliefDesc', { leadType: t('debtManagement')}) : t('reliefDesc', { leadType: t('debtSettlement') })}
                    </Text>
                  </Stack>
                  <UserPlanTag
                    data-cy="select-plan-type-tag"
                    planType={userPlanCardData?.planType || ''}
                    variant="tertiary"
                  />
                  <Box fontSize="14px" lineHeight={'18px'}>
                    <Trans
                      t={t}
                      i18nKey={ leadType === JourneyName.DEBT_MANAGEMENT ? 'dmpPositivePlanDesc' : 'planDesc' }
                      values={{
                        totalEligibleDebt: formatAmountWithCurrencyUnit(
                          userPlanCardData?.totalEligibleDebt ?? 0
                        ),
                        estimatedSavings: formatAmountWithCurrencyUnit(
                          userPlanCardData?.estimatedSavings ?? 0
                        ),
                        programPeriod: userPlanCardData?.programTimePeriod,
                        monthlyPayment: formatAmountWithCurrencyUnit(
                          userPlanCardData?.monthlyPayment ?? 0
                        ),
                        servicer: userPlanCardData?.servicerName,
                      }}
                    />
                  </Box>
                  <Box pb="10px">
                    <Text color="gray" fontSize="small">
                      {t('applicationId')}:{' '}
                      {userPlanCardData?.fileNumber || t('generating')}
                    </Text>
                    <Text color="gray" fontSize="small" lineHeight="medium">
                      {formatDate(userPlanCardData?.submittedDate || '')}
                    </Text>
                  </Box>
                </Stack>
              ) : pageVariant === MyPlanWorkflowOptionsEnum.SEMI_POSITIVE ? (
                <Stack gap="24px">
                  <Stack gap="8px">
                    <Text fontSize={'xxlarge'} fontWeight={'semiBold'}>
                      {t('myRelief')}
                    </Text>
                    <Text fontSize="small" color="darkerGray">
                    { leadType === 'Debt Management' ? t('reliefDesc', { leadType: t('debtManagement')}) : t('reliefDesc', { leadType: t('debtSettlement') })}
                    </Text>
                  </Stack>
                  <UserPlanTag
                    data-cy="select-plan-type-tag"
                    planType={userPlanCardData?.planType || ''}
                    variant="tertiary"
                  />
                  <Box fontSize="14px" lineHeight={'18px'}>
                    <Trans
                      t={t}
                      i18nKey="semiPositiveDesc"
                      values={{
                        totalEligibleDebt: formatAmountWithCurrencyUnit(
                          userPlanCardData?.totalEligibleDebt ?? 0
                        ),
                        programPeriod: userPlanCardData?.programTimePeriod,
                        monthlyPayment: formatAmountWithCurrencyUnit(
                          userPlanCardData?.monthlyPayment ?? 0
                        ),
                        servicer: userPlanCardData?.servicerName,
                      }}
                    />
                  </Box>
                  <Box pb="10px">
                    <Text color="gray" fontSize="small">
                      {t('applicationId')}:{' '}
                      {userPlanCardData?.fileNumber || t('generating')}
                    </Text>
                    <Text color="gray" fontSize="small" lineHeight="medium">
                      {formatDate(userPlanCardData?.submittedDate || '')}
                    </Text>
                  </Box>
                </Stack>
              ) : pageVariant === MyPlanWorkflowOptionsEnum.NEGATIVE ? (
                <Stack gap="24px">
                  <Text fontSize={'xxlarge'} fontWeight={'semiBold'}>
                    {t('myPlan')}
                  </Text>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pb="16px"
                    borderBottom={`1px solid ${theme.palette.brandGray.light}`}
                  >
                    <Text fontSize={'medium'} fontWeight={'semiBold'}>
                      {t('planStarted')}
                    </Text>
                    <Text fontSize={'medium'} fontWeight={'semiBold'}>
                      {formatDateMMDDYYY(
                        String(userPlanCardData?.profileCreatedDate)
                      )}
                    </Text>
                  </Box>
                  <Stack gap="8px">
                    <Text fontSize={'medium'} fontWeight={'semiBold'}>
                      {t('whatHappensNow')}
                    </Text>
                    <Text fontSize={'small'} color="darkerGray">
                      {t('whatHappensNowDesc')}
                    </Text>
                  </Stack>
                </Stack>
              ) : (
                <Stack gap="24px">
                  <Text fontSize={'xxlarge'} fontWeight={'semiBold'}>
                    {t('myPlan')}
                  </Text>
                  <UserPlanTag
                    data-cy="select-plan-type-tag"
                    planType={userPlanCardData?.planType || ''}
                    variant="tertiary"
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pb="16px"
                    borderBottom={`1px solid ${theme.palette.brandGray.light}`}
                  >
                    <Text fontSize={'medium'} fontWeight={'semiBold'}>
                      {t('planStarted')}
                    </Text>
                    <Text fontSize={'medium'} fontWeight={'semiBold'}>
                      {formatDateMMDDYYY(
                        (userPlanCardData?.submittedDate ||
                          String(userPlanCardData?.profileCreatedDate)) ??
                          ''
                      )}
                    </Text>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text fontSize={'small'} color="gray">
                      {t('planServicer')}
                    </Text>
                    <Text fontSize={'medium'} fontWeight={'semiBold'}>
                      <img
                        src={userPlanCardData?.servicerLogo}
                        height="26px"
                        width="91px"
                        alt="servicerLogo"
                      />
                    </Text>
                  </Box>
                </Stack>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
