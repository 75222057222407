import { CircularProgress, useTheme } from '@mui/material';
import { useEffect } from 'react';

import { Text } from '@components/common';

import { useStyles } from './Loader.styles';

export interface LoaderProps {
  label?: string;
  labelPosition?: 'top' | 'bottom';
  fullScreen?: boolean;
  size?: string;
  showOneColorSpinner?: boolean;
}

/**
 * Renders a loading animation.
 * @param label - The label to render above/below the loading animation
 * @param labelPosition - ("top" | "bottom") The position of the label
 * @param fullScreen - (boolean) If the loader should take up the full screen
 * @param size - (string) Custom loader size
 * @param showButtonLoadingSpinner
 */

export const Loader: React.FC<LoaderProps> = ({
  label,
  labelPosition = 'top',
  fullScreen,
  size = '10rem',
  showOneColorSpinner = false,
}) => {
  const classes = useStyles({ labelPosition, fullScreen });
  const theme = useTheme();

  useEffect(() => {
    const root = document.getElementById('root');

    if (root) {
      if (fullScreen) {
        root.style.overflow = 'hidden';
      } else {
        root.style.overflow = 'visible';
      }
    }
  }, [fullScreen]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {showOneColorSpinner ? (
          <CircularProgress
            size={size}
            sx={{ color: theme.palette.common.white }}
          />
        ) : (
          <div className={classes.loader} />
        )}
        {label && (
          <Text fontWeight="bold" fontSize="xlarge">
            {label}
          </Text>
        )}
      </div>
    </div>
  );
};
