import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Typography,
  Slide,
  Button,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { BouncingDots, ButtonBranded } from '@components/common';
import { useStore } from '@stores';
import { theme } from '@styles';
import { GTMEsignButtonClicked, GTMEsignSkipped, GTMEsignViewed } from '@utils';

import { ESignThankYou } from './ESignThankYou';

interface ESignProps {
  handleSubmit: (directConnect?: boolean) => void;
  isLoading: boolean;
  t: (key: string) => string;
}

export const ESign: React.FC<ESignProps> = observer(
  ({ handleSubmit, isLoading, t }) => {
    const {
      remoteConfigStore: { featureFlags },
      profileStore,
      digitalSignatureStore,
      modalStore,
    } = useStore();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const [showIframe, setShowIframe] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);

    const uploadCalled = useRef(false);
    const esignViewed = useRef(false);

    const { isWithinServicerHours, declineAgentDirectContact } = modalStore;

    const { enableDeclineDigitalSignature, enableAgentDirectContact } =
      featureFlags;

    const {
      url,
      loadingGeneratingUrl,
      loadingCreatingDocument,
      errorGeneratingUrl,
      errorCreatingDocument,
    } = digitalSignatureStore;

    useEffect(() => {
      const handleIframeMessage = (event: MessageEvent) => {
        if (event.data.type === 'ESIGN_SUCCESS') {
          if (profileStore.profile?.id && !uploadCalled.current) {
            digitalSignatureStore.updateDigitalSignDocument(
              profileStore.profile.id,
              'NTLOI',
              {
                status: 'signed',
              }
            );
            uploadCalled.current = true;
          }

          setShowThankYou(true);
        }
      };

      window.addEventListener('message', handleIframeMessage);

      return () => {
        window.removeEventListener('message', handleIframeMessage);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleESignClick = async () => {
      GTMEsignButtonClicked();
      const documentType = 'NTLOI';
      const profileId = profileStore.profile?.id;

      if (profileId) {
        await digitalSignatureStore.getDigitalSignDocument(
          profileId,
          documentType
        );
        if (!esignViewed.current) {
          GTMEsignViewed();
          esignViewed.current = true;
        }
        setShowIframe(true);
      }
    };

    const handleCloseIframe = () => {
      setShowIframe(false);
    };

    const isDirectContact =
      isWithinServicerHours &&
      enableAgentDirectContact &&
      declineAgentDirectContact === false;

    // GTM Skip ESign
    const skipId = isDirectContact
      ? 'skip-declines-direct-connect-on-hours'
      : isWithinServicerHours && enableAgentDirectContact
        ? 'skip-direct-connect-on-hours'
        : 'skip-direct-connect-off-hours';

    return (
      <Box px={4} py={3}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 2,
            gap: 2,
          }}
        >
          <Box
            sx={{
              border: '1px solid lightgray',
              borderRadius: '50%',
              padding: '6px',
              display: 'inline-flex',
            }}
          >
            <AccountCircleOutlinedIcon sx={{ fontSize: '36px' }} />
          </Box>
          <Box>
            <BouncingDots />
          </Box>
          <Box
            sx={{
              border: '1px solid lightgray',
              borderRadius: '50%',
              paddingX: '8px',
              paddingY: '9px',
              height: '50px',
              width: '50px',
            }}
          >
            <svg
              width="31"
              height="26"
              viewBox="0 0 31 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4586_9872)">
                <path
                  d="M18.1664 25.5831H0.230469L5.1223 0.854538H12.7554L9.28236 18.4064L18.1664 25.5831Z"
                  fill="#2A353D"
                />
                <path
                  d="M30.6908 0.854538L25.799 25.5831H18.1659L21.6389 8.03343L12.7549 0.854538H30.6908Z"
                  fill="#44ACCD"
                />
              </g>
              <defs>
                <clipPath id="clip0_4586_9872">
                  <rect
                    width="30.1538"
                    height="24.6154"
                    fill="white"
                    transform="translate(0.230469 0.692307)"
                  />
                </clipPath>
              </defs>
            </svg>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              color: theme.palette.brandBlue.darker,
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
          >
            {t('beforeWeFinishConnectingYou')}
          </Typography>
          <Typography>{t('seamlessEnrollment')}</Typography>
        </Box>

        {loadingGeneratingUrl || loadingCreatingDocument ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 5,
            }}
          >
            <CircularProgress sx={{ color: '#578641' }} />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              mt: 5,
            }}
          >
            <ButtonBranded
              type="submit"
              variant="primary"
              onClick={handleESignClick}
              disabled={errorCreatingDocument || errorGeneratingUrl}
            >
              {t('esignNow')}
            </ButtonBranded>

            {enableDeclineDigitalSignature && (
              <ButtonBranded
                variant="textonly"
                fontSize={theme.fontSizes.large}
                lineHeight={theme.fontLineHeights.xxxsmall}
                onClick={() => {
                  GTMEsignSkipped();
                  handleSubmit(isDirectContact);
                }}
                id={skipId}
              >
                {isDirectContact && !isDesktop
                  ? t('skipOnHours')
                  : t('skipOffHours')}
              </ButtonBranded>
            )}
            {(errorCreatingDocument || errorGeneratingUrl) && (
              <Typography sx={{ color: 'red', textAlign: 'center' }}>
                {t('somethingWentWrong')}
              </Typography>
            )}
          </Box>
        )}

        <Slide
          direction="up"
          in={showIframe && !errorGeneratingUrl}
          mountOnEnter
          unmountOnExit
        >
          <Box
            sx={{
              position: 'fixed',
              top: 48,
              left: 0,
              width: '100%',
              height: isDesktop ? 'calc(100% - 48px)' : 'calc(100% - 15px)',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 1300,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                position: 'relative',
              }}
            >
              {showThankYou ? (
                <ESignThankYou
                  isLoading={isLoading}
                  onClose={() => handleSubmit(isDirectContact)}
                />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      backgroundColor: 'white',
                      zIndex: 1300,
                      pointerEvents: 'none',
                    }}
                  ></Box>

                  <iframe
                    title="eSign Document"
                    src={url}
                    width="100%"
                    height="100%"
                    style={{
                      border: 'none',
                      position: isDesktop ? 'initial' : 'absolute',
                      top: isDesktop ? 'initial' : '-40px',
                    }}
                  ></iframe>
                </Box>
              )}

              <Box
                sx={{
                  position: 'absolute',
                  top: '-48px',
                  left: 0,
                  background: 'white',
                  width: '100%',
                  minHeight: '48px',
                  boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.12)',
                  display: 'flex',
                }}
              >
                <Button onClick={handleCloseIframe} variant="text">
                  <ArrowBackIcon
                    sx={{
                      color: '#578641',
                    }}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </Slide>
      </Box>
    );
  }
);
