import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    marginTop: "24px",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
}));
