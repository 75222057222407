import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useDebtReviewStyles = makeStyles((theme: Theme) => ({
  fontDarkGray: {
    color: `${theme.palette.brandGray.darker} !important`,
  },
  headerReview: {
    display: "flex",
    alignItems: "flex-end",
    paddingTop: "2rem",
    paddingBottom: "2rem",
    [theme.breakpoints.up("md")]: {
      paddingTop: "unset",
    },
  },
  container: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    flex: "0 1 35%",
    marginBottom: "1.25rem",
  },
  contentBox: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    gap: 20,
  },

  contentChild: {
    flex: "0 1 auto",
    display: "flex",
    flexDirection: "column",
  },

  tradeLinesContainer: {
    flex: "0 1 auto",
    display: "flex",
    flexDirection: "column",
  },
  tradeLineContent: {
    flex: "0 1 auto",
    minHeight: "0",
  },
}));
