import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { default as MuiTooltip } from '@mui/material/Tooltip';
import { useState, FC, ReactElement, cloneElement } from 'react';

import { GTMPlanOptionsToolTip } from '@utils';

interface TooltipProps {
  title: ReactElement | string;
  children: ReactElement;
}
export const Tooltip: FC<TooltipProps> = ({ title, children }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const toggleTooltip = () => {
    setOpen(!open);
  };

  const closeTooltip = () => {
    setOpen(false);
  };

  const tooltipTop = {
    '& .MuiTooltip-tooltip': {
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
      background: '#FFFFFF',
    },
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      {isDesktop && (
        <ClickAwayListener onClickAway={closeTooltip}>
          <MuiTooltip
            PopperProps={{
              disablePortal: true,
              sx: tooltipTop,
            }}
            onClose={closeTooltip}
            open={open}
            disableHoverListener
            disableTouchListener
            title={title}
          >
            {cloneElement(children, {
              onClick: () => {
                toggleTooltip();
                GTMPlanOptionsToolTip();
              },
            })}
          </MuiTooltip>
        </ClickAwayListener>
      )}
      {!isDesktop && (
        <ClickAwayListener onClickAway={closeTooltip}>
          <MuiTooltip
            PopperProps={{
              disablePortal: true,
              sx: tooltipTop,
            }}
            onClose={closeTooltip}
            open={open}
            disableFocusListener
            disableHoverListener
            title={title}
          >
            {cloneElement(children, {
              onClick: () => {
                toggleTooltip();
                GTMPlanOptionsToolTip();
              },
            })}
          </MuiTooltip>
        </ClickAwayListener>
      )}
    </>
  );
};
