import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    width: "100%",
    borderRadius: "8px",
    boxShadow: theme.customShadows.clickable,
    overflow: "hidden",
    "& img": {
      width: "100%",
      objectFit: "fill",
      objectPosition: "left top",
    },
    [theme.breakpoints.up("md")]: {
      "& img": {
        width: "50%",
        objectFit: "cover",
        objectPosition: "center center",
      },
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    padding: "1.5rem 1rem",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "4rem .5rem 4rem 2rem",
    },
    "& ul": {
      listStyle: "none",
      padding: 0,
      marginTop: "1rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      lineHeight: "100%",
      fontWeight: "400",
      "& li": {
        fontSize: theme.fontSizes.medium,
        fontWeight: theme.fontWeights.regular,
        lineHeight: theme.fontLineHeights.medium,
        display: "flex",
        alignItems: "center",
      },
      "& li::before": {
        content: '"\\2022"',
        color: theme.palette.brandBlue.darker,
        marginRight: theme.spacing(1),
        fontSize: theme.fontSizes.xxlarge,
      },
    },
  },

  setButtonWidth: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "10rem",
    },
  },

  setButtonPadding: {
    [theme.breakpoints.up("md")]: {
      padding: "12px 24px",
    },
  },

  setButtonFont: {
    [theme.breakpoints.up("md")]: {
      fontSize: theme.fontSizes.medium,
      lineHeight: theme.fontLineHeights.xxxsmall,
      whiteSpace: "nowrap",
    },
  },
}));
