import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    padding: "23px 19px",
    gap: "18px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "1rem",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexBasis: "100%",
      gap: "1rem",
    },
  },
  button: {
    width: "100%",
    marginTop: "1rem",
    borderColor: theme.palette.brandDarkBlue.main,
    borderWidth: "2px",
    borderStyle: "solid",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.brandDarkBlue.main,
    fontSize: theme.fontSizes.xlarge,
    fontWeight: theme.fontWeights.bold,
    padding: "18px 32px",
    borderRadius: "144px",
  },
  setButtonWidth: {
    [theme.breakpoints.up("md")]: {
      width: "200px",
      padding: "12px 24px",
    },
  },

  setButtonFont: {
    [theme.breakpoints.up("md")]: {
      fontSize: theme.fontSizes.medium,
      lineHeight: theme.fontLineHeights.xxxsmall,
    },
  },

  profileName: {
    textTransform: "capitalize",
  },
}));
