import { observer } from "mobx-react-lite";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { useStore } from "@stores";
import { JourneyId } from "@types";
import { formatDollar, ProfileSubStatusEnum } from "@utils";

import { useStyles } from "./SelectedPlanDetailsCard.styles";
import { MonthTag } from "../MonthTag";
import { Text } from "../Text";

export interface SelectedPlanDetailsCard {
  children?: React.ReactNode;
  description?: string;
  dropShadow?: boolean;
  pendingEnrollment: boolean;
}

interface PlanCardProps extends SelectedPlanDetailsCard {
  months: number;
  estimatedPayment: number;
  pendingEnrollment: boolean;
}

/** Displayed on the done/confirmation page */
export const SelectedPlanDetailsCard = observer((props: SelectedPlanDetailsCard) => {
  const {
    profileStore: { journeyId },
    callToActionsStore: { debtManagementPlan, debtSettlementPlan },
  } = useStore();

  if (journeyId === JourneyId.DEBT_MANAGEMENT && debtManagementPlan) {
    return (
      <PlanCard
        months={debtManagementPlan.inputParameters.programLength}
        estimatedPayment={debtManagementPlan.debtManagementPayment.monthlyPayment}
        {...props}
      />
    );
  }

  if (journeyId === JourneyId.DEBT_SETTLEMENT && debtSettlementPlan) {
    return (
      <PlanCard
        months={debtSettlementPlan.programTimePeriod}
        estimatedPayment={debtSettlementPlan.estimatedPmtWithLegal}
        description={debtSettlementPlan.description}
        {...props}
      />
    );
  }

  return <></>;
});

const PlanCard = observer(
  ({ months, children, description, estimatedPayment, pendingEnrollment, dropShadow = true }: PlanCardProps) => {
    const { profileStore } = useStore();
    const classes = useStyles({ pendingEnrollment, dropShadow });
    const { t } = useTranslation("plan_option");
    const declined = profileStore.profile?.subStatus === ProfileSubStatusEnum.IDA_DECLINED_SELECTION;

    return (
      <div className={classes.card}>
        {(declined || pendingEnrollment) && (
          <div className={classes.pending}>
            <span className={classes.pendingDot} />
            <Text color="white" fontSize="xsmall" fontWeight="semiBold" data-cy="plan-card-heading">
              {declined ? t("idaDeclined") : t("pending")}
            </Text>
          </div>
        )}
        <div className={classes.content}>
          <div className={classes.header}>
            <MonthTag
              data-cy="select-plan-month-tag"
              month={months}
              variant={pendingEnrollment ? "tertiary" : "primary"}
            />
            <div className={classes.monthlyPayment}>
              <Trans
                data-cy="select-plan-monthly-amount"
                t={t}
                i18nKey="monthlyPayment"
                components={[
                  <span className={classes.monthlyPaymentAmount} key={0} />,
                  <span className={classes.slash} key={1} />,
                ]}
                values={{
                  amount: formatDollar(estimatedPayment),
                }}
              />
            </div>
          </div>
          {description && <div className={classes.savings}>{description}</div>}
          {children}
        </div>
      </div>
    );
  },
);
