import { Box, OutlinedInputProps, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useState } from "react";

import { useStyles } from "./InnerTopLabelTextField.styles";

const CustomTextField = styled((props: TextFieldProps) => (
  <TextField
    inputProps={{
      inputMode: "decimal",
    }}
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    overflow: "hidden",
    borderRadius: 6,
    backgroundColor: theme.palette.common.white,
    border: "1px solid",
    borderColor: theme.palette.brandGray.main,
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

interface InnerTopLabelTextFieldProps {
  label: string;
  length: number;
  spaceAfter: number;
  autoFocus?: boolean;
  onCodeChange: (str: string) => void;
}
export default function InnerTopLabelTextField({
  label,
  onCodeChange,
  length,
  spaceAfter,
  ...props
}: InnerTopLabelTextFieldProps) {
  const classes = useStyles();
  const [val, setVal] = useState("");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const str = e.target.value.replace(/\D/g, "").split(" ").join("").split("");
    if (str.length > length) {
      return;
    }
    const formatted = [];
    while (str.length) {
      for (let i = 0; i < spaceAfter && str.length; i++) {
        formatted.push(str.shift());
      }
      if (str.length) formatted.push(" ");
    }
    setVal(formatted.join(""));

    if (formatted.length == 7) {
      onCodeChange(formatted.join(""));
    } else {
      onCodeChange("");
    }
  };
  return (
    <Box className={classes.box}>
      <CustomTextField
        label={label}
        id="text-input"
        variant="filled"
        onChange={onChange}
        value={val}
        required
        error={!val}
        className={classes.inputWidth}
        autoFocus
        {...props}
      />
    </Box>
  );
}
