import LocationOn from '@mui/icons-material/LocationOn';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { useRollbar } from '@rollbar/react';
import debounce from 'lodash.debounce';
import {
  BaseSyntheticEvent,
  KeyboardEvent,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Control,
  Controller,
  UseFormResetField,
  UseFormTrigger,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddressAutocompleteProps } from '@components/common/AutoCompleteAddress/autocompleteprops.ts';
import { AxiosAgent } from '@services';
import { CreditAuthForm, Address } from '@types';

interface ListItemProps extends HTMLAttributes<HTMLLIElement> {
  key?: string | number;
}

interface AddressFormProps extends AddressAutocompleteProps {
  control: Control<CreditAuthForm>;
  trigger: UseFormTrigger<CreditAuthForm>;
  resetField: UseFormResetField<CreditAuthForm>;
}

export const AutoCompleteAddress = ({
  label,
  control,
  autoFocus,
  resetField,
}: AddressFormProps) => {
  const rollbar = useRollbar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<string>('');
  const [displayOptions, setDisplayOptions] = useState<boolean>(true);
  const [suggestions, setSuggestions] = useState<Address[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<Address | undefined>(
    undefined
  );
  const {
    i18n: { language },
  } = useTranslation();

  const highlightedOptionRef = useRef<Address | null>(null);

  const setStateForNoSuggestions = useCallback(() => {
    setSuggestions([]);
    setSelectedOption(undefined);
    setOpen(false);
    control.setError('address.street_line', {
      type: 'validate',
      message:
        language === 'en' ? 'Address not found' : 'Dirección no encontrada',
    });
    control._formState.isValid = false;
    control._updateValid(false);
  }, [control, language]);
  const fetchSuggestions = useCallback(
    async (search: string, selected?: string) => {
      setLoading('loading');
      try {
        const data = await AxiosAgent.AdressLookup.getAddress(search, selected);
        setLoading('');
        if (data['suggestions']?.length) {
          setSuggestions(data['suggestions']);
        } else {
          setStateForNoSuggestions();
        }
      } catch (error) {
        rollbar.error('Address autocomplete error', error as Error);
        setStateForNoSuggestions();
      }
    },
    [setStateForNoSuggestions, rollbar]
  );

  const constructAddressString = (address: Address) => {
    const { street_line, secondary, city, state, zipcode } = address;
    let addressString = `${street_line}`;

    if (secondary !== undefined && secondary !== '') {
      addressString += ` ${secondary},`;
    } else {
      addressString += ',';
    }

    addressString += ` ${city}, ${state}, ${zipcode}`;
    return addressString;
  };

  const getOptionLabel = (option: Address | string) => {
    let entriesText = '';
    if (!inputValue) {
      return '';
    }
    if (typeof option === 'object') {
      const { street_line, secondary, city, state, zipcode, entries } =
        option as Address;
      if (entries > 1) {
        entriesText = ' (' + entries + ' more entries) ';
        return `${street_line} ${secondary}, ${city}, ${state}, ${zipcode} ${entriesText}`;
      }
      return constructAddressString(option);
    }
    return option;
  };

  const debouncedHandleInputChange = debounce(async (search, selected) => {
    await fetchSuggestions(
      search,
      selected ? getOptionLabel(selected) : undefined
    );
  });

  useEffect(() => {
    if (selectedOption) {
      setDisplayOptions(false);
      setSuggestions([]);
    } else {
      setDisplayOptions(true);
    }
    return () => setDisplayOptions(true);
  }, [selectedOption]);

  const handleOnOptionSelect = useCallback(
    async (
      option: Address,
      onchangeCallback: (arg0: Address | undefined) => void
    ) => {
      if (option) {
        const { street_line, secondary, city, state, zipcode, entries } =
          option as Address;
        if (entries > 1) {
          await debouncedHandleInputChange(
            `${street_line} ${secondary}`,
            `${street_line} ${secondary} (${entries}) ${city}, ${state}, ${zipcode}`
          );
          setDisplayOptions(true);
          setInputValue(`${street_line} ${secondary}`);
        } else {
          setInputValue(constructAddressString(option));
          setSelectedOption(option);
          setInputValue(constructAddressString(option));
          setDisplayOptions(false);
          onchangeCallback(option);
          setOpen(false);
        }
      }
    },
    [debouncedHandleInputChange]
  );

  const handleOnInputChange = useCallback(
    async (inputText: string) => {
      if (!inputText) {
        setOpen(false);
      } else {
        setOpen(true);
        setSelectedOption(undefined);
        debouncedHandleInputChange.cancel();
        await debouncedHandleInputChange(inputText, '');
      }
    },
    [debouncedHandleInputChange]
  );

  const renderOption = (props: ListItemProps) => {
    return (
      <li {...props}>
        <Grid alignItems="center" container data-cy="address-options">
          <Grid item>
            <Box component={LocationOn} sx={{ mr: 2 }} />
          </Grid>
          <Grid item xs>
            <span data-cy="options">{props.key}</span>
          </Grid>
        </Grid>
      </li>
    );
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent, onChange: (value: Address | undefined) => void) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (highlightedOptionRef.current) {
          handleOnOptionSelect(highlightedOptionRef.current, onChange);
        }
      }
    },
    [handleOnOptionSelect]
  );

  return (
    <>
      <Controller
        control={control}
        name="address"
        render={({
          field: { onChange },
          formState: { errors },
          fieldState: { error },
        }) => {
          const renderHelperText = () => {
            const { address } = errors || {};

            if (address) {
              const { message, street_line } = address;

              if (message) {
                return message.includes('Address is required')
                  ? language === 'en'
                    ? 'Address is required'
                    : 'Dirección es requerida'
                  : message;
              }

              if (street_line && street_line.message) {
                return street_line.message;
              }
            }
          };

          return (
            <>
              <Autocomplete
                disableCloseOnSelect={displayOptions}
                open={open}
                inputValue={inputValue}
                options={suggestions}
                onChange={async (_e: BaseSyntheticEvent, option) => {
                  if (_e.target.textContent) {
                    await handleOnOptionSelect(option as Address, onChange);
                  }
                }}
                onBlur={async (e: BaseSyntheticEvent) => {
                  if (!e.target.value) {
                    if (selectedOption) {
                      setSelectedOption(undefined);
                      resetField('address');
                    }
                    onChange({});
                  }
                }}
                disableClearable
                getOptionLabel={getOptionLabel}
                loading={!!loading} // Optional loading indicator
                isOptionEqualToValue={(option, value) => {
                  if (value.entries > 1) {
                    return option.street_line === value.street_line;
                  } else {
                    return (
                      option.street_line.includes(value.street_line) ||
                      option.secondary.includes(value.secondary) ||
                      option.city.includes(value.city) ||
                      option.state.includes(value.state) ||
                      option.zipcode.includes(value.zipcode)
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    value={selectedOption}
                    {...params}
                    fullWidth
                    autoFocus={autoFocus}
                    label={label}
                    error={!!error}
                    helperText={renderHelperText()}
                    data-cy="credit-authorization-address-input"
                    onChange={async (e) => {
                      setInputValue(e.target.value);
                      await handleOnInputChange(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyDown(e, onChange)}
                  />
                )}
                renderOption={(props, option) => {
                  highlightedOptionRef.current = option;
                  if (inputValue) {
                    return renderOption(props);
                  }
                  return null;
                }}
              />
            </>
          );
        }}
      />
      <div>{loading}</div>
    </>
  );
};
