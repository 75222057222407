import { createContext, useContext } from "react";

import BudgetStore from "./budgetStore.ts";
import ChatStore from "./chatStore.ts";
import CommonStore from "./commonStore";
import DigitalSignStore from "./digitalSignStore.ts";
import EngagementStore from "./engagementStore";
import LoadingStore from "./LoadingStore.ts";
import ModalStore from "./modalStore.tsx";
import ProfileCallToActions from "./profileCallToActions.ts";
import ProfileStore from "./profileStore";
import RemoteConfigStore from "./remoteConfigStore.ts";
import SessionStore from "./sessionStore.ts";

export interface Store {
  commonStore: CommonStore;
  profileStore: ProfileStore;
  engagementStore: EngagementStore;
  sessionStore: SessionStore;
  loadingStore: LoadingStore;
  modalStore: ModalStore;
  callToActionsStore: ProfileCallToActions;
  remoteConfigStore: RemoteConfigStore;
  budgetStore: BudgetStore;
  chatStore: ChatStore;
  digitalSignatureStore: DigitalSignStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  profileStore: new ProfileStore(),
  engagementStore: new EngagementStore(),
  sessionStore: new SessionStore(),
  loadingStore: new LoadingStore(),
  modalStore: new ModalStore(),
  callToActionsStore: new ProfileCallToActions(),
  remoteConfigStore: new RemoteConfigStore(),
  budgetStore: new BudgetStore(),
  chatStore: new ChatStore(),
  digitalSignatureStore: new DigitalSignStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
