import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonBranded } from '@components/common';
import { ConfirmationModalView } from '@components/layout/Footer/ConfirmationModalView.tsx';
import { useStyles } from '@components/layout/Form/form.styles.ts';
import { TotalDebt } from '@components/views/Modules/DebtReview/TotalDebt.tsx';
import { useStore } from '@stores';
import { theme } from '@styles';
import { VTPageTitlesEnum } from '@types';
import {
  capitalize,
  CTAModuleID,
  CTAModulesEnum,
  getFormattedTodayDateUS,
  GTMOffRampClicked,
  GTMOffRampConfirmed,
  GTMSeePlanOptions,
  handlePercentageAndPageCompleted,
  JourneyCTAEnum,
  ProfileSubStatusEnum,
} from '@utils';

import { FooterLegalInfo } from '../../common/FooterLegalInfo';
interface IDebtReviewFooter {
  name: string;
}

export const DebtReviewFooter = observer(({ name }: IDebtReviewFooter) => {
  const { t } = useTranslation('debt_review');
  const classes = useStyles({ name });
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    profileStore,
    modalStore: {
      openModal,
      showOffRampModal,
      setShowOffRampModal,
      setClickSource,
    },
    callToActionsStore,
    remoteConfigStore: { featureFlags },
    digitalSignatureStore,
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleOffRamp = () => {
    GTMOffRampClicked('Debt-Review');
    setClickSource('footer');
    setShowOffRampModal(!showOffRampModal);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    GTMSeePlanOptions();

    await handlePercentageAndPageCompleted(
      featureFlags.enableBudgetTool,
      VTPageTitlesEnum.DEBT_REVIEW,
      profileStore
    );

    if (featureFlags.enableBudgetTool) {
      openModal(CTAModulesEnum['complete-your-budget' as CTAModuleID]);
    } else {
      openModal(CTAModulesEnum['select-plan' as CTAModuleID]);
    }

    if (profileStore.profile?.id) {
      await callToActionsStore.updateJourneyCta(
        profileStore.profile.id,
        profileStore.journeyId,
        JourneyCTAEnum['review-debt-eligibility']
      );

      // This is a background call to create a digital signature document to speed up the process of generating signature link
      if (featureFlags.enableDigitalSignature) {
        const plans =
          callToActionsStore.creditResponse?.plans?.['debt-settlement']
            ?.planDetails;

        const longestPlan = Array.isArray(plans)
          ? plans
              .slice()
              .sort((a, b) => b.programTimePeriod - a.programTimePeriod)[0]
          : null;

        const firstName = profileStore.profile?.firstName || '';
        const lastName = profileStore.profile?.lastName || '';
        const full_name = `${capitalize(firstName)} ${capitalize(lastName)}`;

        const payment = longestPlan?.estimatedPmtWithLegal
          ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(longestPlan.estimatedPmtWithLegal)
          : '';
        const amount = callToActionsStore.getTotalDebt?.() || '';
        const date = getFormattedTodayDateUS();

        const payload = {
          document_type: 'NTLOI',
          prefill_data: {
            client_timestamp: Math.floor(Date.now() / 1000).toString(),
            data: [{ full_name }, { payment }, { amount }, { date }],
          },
        };

        digitalSignatureStore.createDigitalSignDocument(
          profileStore.profile.id,
          payload
        );
      }
    }
    setIsLoading(false);
  };

  return (
    <div className={classes.footer}>
      {!isDesktop && (
        <div className={classes.totalDebtBorderBottom}>
          <TotalDebt />
        </div>
      )}

      <div className={classes.footerButtonContainer}>
        {featureFlags.enableReviewDebtOffRamp && (
          <div className={classes.callMeButton}>
            <ButtonBranded
              variant="textonly"
              fontSize={theme.fontSizes.medium}
              fontWeight={theme.fontWeights.medium}
              lineHeight={theme.fontLineHeights.xxxsmall}
              className={classes.debtReviewCallButton}
              onClick={handleOffRamp}
              data-cy="callMeButton"
            >
              {t('footer.secondaryButtonLabel')}
            </ButtonBranded>
          </div>
        )}

        <div className={classes.nextActionButton}>
          <ButtonBranded
            loading={isLoading}
            data-cy="see-plan-options"
            variant="primary"
            className={classes.nextActionButton}
            onClick={handleSubmit}
          >
            {featureFlags.enableBudgetTool
              ? t('footer.primaryButtonBudgetLabel')
              : t('footer.primaryButtonLabel')}
          </ButtonBranded>
        </div>

        <ConfirmationModalView
          isOpen={showOffRampModal}
          handlePrimary={async () => {
            GTMOffRampConfirmed('Debt-Review');
            setShowOffRampModal(false);
            await profileStore.updateEnrollmentStatus(
              ProfileSubStatusEnum.IDA_DECLINED_SELECTION
            );
            openModal(CTAModulesEnum['plan-selected']);
          }}
          handleSecondary={() => {
            setShowOffRampModal(false);
          }}
          handleCancel={() => {
            setShowOffRampModal(false);
          }}
          name={'debt-review'}
        />
      </div>
      <FooterLegalInfo />
    </div>
  );
});
