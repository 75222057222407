import { Alert, Backdrop } from '@mui/material';
import { RecaptchaVerifier } from 'firebase/auth';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthenticationModal, SignUpForm, SignInForm } from '@components/views';
import { useStore } from '@stores';
import { Engagement, StoredProfile } from '@types';
import { getErrorMessage, GTMInitiate, setLanguage } from '@utils';

import { useStyles } from './Authentication.styles.ts';
import { useAuthVerification } from '../../../../utils/hooks/authVerification.ts';

export const AuthenticationForm: FC = observer(() => {
  const [error, setError] = useState('');

  const alertContainerRef = useRef<HTMLDivElement>(null);
  const [phone, setPhone] = useState('');
  const { sessionStore } = useStore();
  const recaptchaWrapperRef = useRef<HTMLDivElement>(null);
  const recaptchaVerifier = useRef<RecaptchaVerifier>();
  const classes = useStyles();
  const [storedProfile, setStoredProfile] = useState<StoredProfile>();
  const { t, i18n } = useTranslation('verification_page');
  const { profileStore } = useStore();

  const { tempUser, setTempUser } = profileStore;

  const navigate = useNavigate();

  const lastUserStored = window.localStorage.getItem('lastUsedProfile');
  const engagementInLocalStorage =
    window.localStorage.getItem('engagementData');

  useEffect(() => {
    if (!tempUser.phone && !tempUser.email && engagementInLocalStorage) {
      const parsedEngagement = JSON.parse(engagementInLocalStorage);
      setTempUser(parsedEngagement as Engagement);
    } else if (lastUserStored) {
      const parsedJson = JSON.parse(lastUserStored) as StoredProfile;
      setLanguage(parsedJson.preferredLanguage);
      setStoredProfile(parsedJson);
    }
  }, [
    engagementInLocalStorage,
    lastUserStored,
    setTempUser,
    tempUser.email,
    tempUser.phone,
    i18n,
  ]);

  const verification = useAuthVerification({
    recaptchaWrapperRef,
    recaptchaVerifier,
    storedProfile,
    phone,
  });

  const scrollToElement = () => {
    alertContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (sessionStore.profileError?.message) {
      setError(
        t(getErrorMessage(sessionStore.profileError.name)) ||
          sessionStore.profileError?.message
      );
    }
  }, [t, sessionStore.profileError]);

  useEffect(() => {
    if (error) scrollToElement();
  }, [error]);

  useEffect(() => {
    if (profileStore.profile?.id) {
      navigate('/dashboard');
    }
  }, [navigate, profileStore.profile?.id]);

  useEffect(() => {
    if (engagementInLocalStorage) {
      const parsedEngagement = JSON.parse(engagementInLocalStorage);
      const ga_client_id = parsedEngagement?.ga_client_id;
      if (ga_client_id) {
        GTMInitiate(ga_client_id);
      }
    }
  }, [engagementInLocalStorage]);

  const addErrorElement = (
    <>
      {(verification.error || error) && (
        <div className={classes.alertBox} ref={alertContainerRef}>
          {(verification.error || error) && (
            <Alert severity="error">{verification.error || error}</Alert>
          )}
        </div>
      )}
    </>
  );

  const renderAuthModal =
    !verification.error &&
    !profileStore.profile?.id &&
    (verification.verificationSent === 'Sending' ||
      verification.verificationSent === 'Sent');

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div ref={recaptchaWrapperRef}>
        <div id="recaptcha" />
      </div>
      {tempUser.phone || tempUser.email ? (
        <SignUpForm
          storedProfile={storedProfile}
          sendSms={() => verification.sendSMS()}
          verifyUser={() => verification.verifyUser()}
        >
          {addErrorElement}
        </SignUpForm>
      ) : (
        <SignInForm
          storedProfile={storedProfile}
          sendSms={verification.sendSMS}
          setPhone={setPhone}
          verifyUser={verification.verifyUser}
        >
          {addErrorElement}
        </SignInForm>
      )}
      {renderAuthModal && (
        <div className={classes.slideContainer}>
          <Backdrop className={classes.backdrop} open>
            <AuthenticationModal
              storedProfile={storedProfile ?? undefined}
              smsVerificationId={verification.smsVerificationId}
              closeSlider={verification.reset}
              resendSMS={verification.sendSMS}
            />
          </Backdrop>
        </div>
      )}
    </div>
  );
});
