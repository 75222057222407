import { ArrowDropDown } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { theme } from '@styles';

import { DebtInfo, DebtInfoPropsBase, Text } from '../..';

export const IneligibleDebtsAccordion: React.FC<{
  data: DebtInfoPropsBase[];
}> = ({ data }) => {
  const { t } = useTranslation('debt_review', { keyPrefix: 'modal' });

  return (
    <Accordion
      elevation={0}
      disableGutters
      sx={{
        background: 'none',
        '&:before': {
          display: 'none',
        },
      }}
      data-cy="accordionItemContent"
    >
      <AccordionSummary
        expandIcon={
          <ArrowDropDown sx={{ color: theme.palette.brandBlue.dark }} />
        }
        sx={{
          paddingX: 0,
          paddingY: 2,
          display: 'flex',
          '& .MuiAccordionSummary-content': {
            justifyContent: 'flex-end',
          },
        }}
      >
        <Text
          fontSize="medium"
          fontWeight="medium"
          lineHeight="xxxsmall"
          sx={{ color: theme.palette.brandBlue.dark }}
          data-cy="ineligibleTradelinesToggleBtn"
        >
          {t('ineligibleDebts')} ({data.length})
        </Text>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingX: 0 }}>
        <DebtInfo data-cy="debt-review-ineligible-debt" data={data} />
      </AccordionDetails>
    </Accordion>
  );
};
