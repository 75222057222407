import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  centerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    paddingBottom: "2rem",
    marginBottom: "80px",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "32px",
  },

  miniDashboardWrapper: (props: { marginTop: number }) => {
    return {
      marginTop: `${props.marginTop || 0}px`,
      padding: "24px",
      [theme.breakpoints.up("md")]: {
        marginTop: "unset",
        margin: "0 auto",
        width: "80%",
        maxWidth: "900px",
      },
    };
  },

  callToActionsContainer: {
    margin: "0 auto",
    width: "100%",
    display: "flex",
    gap: "24px",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      width: "80%",
      maxWidth: "900px",
    },
  },

  contenthelpA: {
    [theme.breakpoints.up("md")]: {
      marginTop: "unset",
      // padding: '24px',
      width: "100%",
    },
  },
}));
