import { To } from "react-router-dom";

// TODO: These are pseudo routes, the id's are correct but the paths will not work
// implement once these paths exist

// TODO: check if CTA ID maps to a route or a module
// if route -> navigate to the route
// if module -> launch the modal
export enum CTARoutesEnum {
  "verify-phone" = "/auth/phone",
  "verify-email" = "/auth/email",
}

export type CTARouteID = keyof typeof CTARoutesEnum;

export const getCTARoute = (ctaID: CTARouteID): To => {
  if (!CTARoutesEnum[ctaID]) {
    return "/";
  }
  return CTARoutesEnum[ctaID];
};

export const MODULE_SELECT_PLAN = "MODULE_SELECT_PLAN";
export const MODULE_BUDGET_TOOL = "MODULE_BUDGET_TOOL";
export const MODULE_VERIFY_CREDIT = "MODULE_VERIFY_CREDIT";
export const MODULE_AUTH_CREDIT = "MODULE_AUTH_CREDIT";
export const MODULE_REVIEW_DEBT = "MODULE_REVIEW_DEBT";
export const MODULE_PLAN_SELECTED = "MODULE_PLAN_SELECTED";
export const MODULE_CALL_FOR_HELP = "MODULE_CALL_FOR_HELP";

export type CTAModuleID = keyof typeof CTAModulesEnum;

export type CTAModule =
  | typeof MODULE_SELECT_PLAN
  | typeof MODULE_BUDGET_TOOL
  | typeof MODULE_VERIFY_CREDIT
  | typeof MODULE_AUTH_CREDIT
  | typeof MODULE_REVIEW_DEBT
  | typeof MODULE_PLAN_SELECTED
  | typeof MODULE_CALL_FOR_HELP;

export enum CTAModulesEnum {
  "select-plan" = MODULE_SELECT_PLAN,
  "verify-credit" = MODULE_VERIFY_CREDIT,
  "authorize-credit-pull" = MODULE_AUTH_CREDIT,
  "review-debt-eligibility" = MODULE_REVIEW_DEBT,
  "plan-selected" = MODULE_PLAN_SELECTED,
  "call-for-help" = MODULE_CALL_FOR_HELP,
  "complete-your-budget" = MODULE_BUDGET_TOOL,
}

export enum JourneyCTAEnum {
  "debt-settlement-id" = "debt-settlement",
  "review-debt-eligibility" = "review-debt-eligibility",
  "select-plan" = "select-plan",
  "budget-tool" = "budget-tool",
  "complete-your-budget" = "complete-your-budget",
}
