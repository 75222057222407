import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "20px 20px",
    gap: "1rem",
  },
  alertBox: {
    paddingTop: "20px",
    borderRadius: "2px",
    width: "100%",
  },
  setButtonWidth: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "8rem",
    },
  },
  resendButton: {
    padding: "13px 0",
  },
}));
