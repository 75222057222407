import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  useTheme,
  FormHelperText,
} from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import { useState } from 'react';

type ISelectBranded = SelectProps & {
  options: { value: string; label: string }[];
  label: string;
  error?: boolean;
  helperText?: string;
};

export const SelectBranded = ({
  options,
  label,
  disabled,
  helperText,
  error,
  ...props
}: ISelectBranded) => {
  const theme = useTheme();
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <FormControl
      error={error}
      sx={{
        width: '100%',
        backgroundColor: theme.palette.common.white,
        '.Mui-focused': {
          color: `${theme.palette.brandGray.dark} !important`,
          borderColor: theme.palette.brandGray.main,
        },
        '.Mui-error': {
          color: `${theme.palette.error.main} !important`,
          borderColor: `${theme.palette.error.main} !important`,
        },
        '& fieldset': {
          border: 'none',
        },
        '& .MuiSelect-select': {
          padding: '22px 32px 7px 14px',
        },
        '[data-shrink="true"]': {
          paddingTop: '20px !important',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: `theme.palette.brandGray.dark`,
        },
        '& > div > div': {
          backgroundColor: 'transparent !important',
        },
      }}
      onClick={() => setOpen(!isOpen)}
      disabled={disabled}
    >
      <InputLabel
        sx={{
          '[data-shrink="true"]': {
            color: `${theme.palette.brandGray.dark} !important`,
            paddingTop: '20px !important',
            textTransform: 'uppercase',
            fontWeight: 'bold',
          },
          label: {
            '-webkit-text-fill-color': `rgba(0, 0, 0, 0.38)`,
          },
        }}
        disabled={disabled}
      >
        {label}
      </InputLabel>
      <Select
        sx={{
          borderRadius: 1,
          border: '1px solid',
          '& .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'transparent !important', // Prevents the background color change on hover
            borderColor: 'red',
          },
        }}
        {...props}
        open={isOpen}
        MenuProps={{
          BackdropProps: {
            style: {
              opacity: 0,
            },
          },
        }}
        IconComponent={() =>
          isOpen ? (
            <KeyboardArrowUpIcon
              onClick={() => setOpen(!isOpen)}
              sx={{ margin: 1, cursor: 'pointer' }}
            />
          ) : (
            <KeyboardArrowDownIcon
              onClick={() => setOpen(!isOpen)}
              sx={{ margin: 1, cursor: 'pointer' }}
            />
          )
        }
      >
        {options.map((o) => (
          <MenuItem
            key={o.value}
            value={o.value}
            data-cy={`select-attribute-${o.value.replaceAll(' ', '-')}`}
          >
            {o.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
