import { useStyles } from './ProgressBar.styles';

export interface ProgressBarProps {
  value: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
}: ProgressBarProps) => {
  const classes = useStyles({ value });

  return (
    <div className={classes.container}>
      <div className={classes.bar}></div>
    </div>
  );
};
