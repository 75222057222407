import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import callNow from '@assets/selectPlanCallLady.svg';
import { Text } from '@components/common';
import { useStore } from '@stores';
import { JourneyId } from '@types';

export const NextStep = () => {
  const { t } = useTranslation('plan_option', { keyPrefix: 'nextStep' });
  const {
    remoteConfigStore: { featureFlags },
    profileStore: { journeyId },
  } = useStore();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gap={1}
      alignItems="center"
    >
      <Stack gap={1.5}>
        <Text fontWeight="bold" textTransform="uppercase" color="accent">
          {t('nextStep')}
        </Text>
        <Text fontWeight="bold">{t('completeEnrollment')}</Text>
        <Text lineHeight="small">
          {featureFlags.enableAgentDirectContact
            ? t(
                `${journeyId === JourneyId.DEBT_SETTLEMENT ? 'dsp' : 'dmp'}Description`
              )
            : t('callLaterDescription')}
        </Text>
      </Stack>
      <img src={callNow} />
    </Box>
  );
};
