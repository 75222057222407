import { Text } from '../Text';

export interface CopyrightProps {
  companyName?: string;
}

export const Copyright: React.FC<CopyrightProps> = ({
  companyName = 'Debt.com',
}) => {
  const currentYear = new Date().getFullYear();

  return (
    <Text fontSize="xsmall" color="gray">
      &copy; {currentYear} {companyName}
    </Text>
  );
};
