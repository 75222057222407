import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  isAtBottom: boolean;
  spaceFromBottom: number;
  language: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      visibility: "hidden",
    },
    position: "absolute",
    bottom: ({ spaceFromBottom }: Props) => spaceFromBottom,
    right: 24,
    zIndex: 9999,
    backgroundColor: theme.palette.brandDarkBlue.dark,
    width: "56px",
    height: "56px",
    padding: ({ language }: Props) => (language === "es" ? "10px" : "5px"),
    borderRadius: "50%",
    display: ({ isAtBottom }: Props) => (isAtBottom ? "none" : "flex"),
    justifyContent: "center",
    flexDirection: "column",
    gap: "2px",
    alignItems: "center",
    transition: "transform 0.3s ease",
    textAlign: "center",
  },
}));
