import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import { Loader } from "@components/common";
import { useStore } from "@stores";

import { auth } from "../../firebase.config.ts";

export const ProtectedRouteOutlet: React.FC = observer(() => {
  const navigate = useNavigate();
  const { commonStore, sessionStore, profileStore, engagementStore, callToActionsStore, remoteConfigStore } =
    useStore();
  const { authUser, onAuthStateChange } = sessionStore;
  const [searchParams] = useSearchParams();
  const {
    t,
    i18n: { language },
  } = useTranslation("verification_page");

  const { updateProfile } = profileStore;
  const token: string | null = searchParams.get("token");

  useEffect(() => {
    autorun(() => {
      if (sessionStore.isLoggingOut) {
        const redirectUrl = language === "es" ? `${remoteConfigStore.redirectUrl}/es` : remoteConfigStore.redirectUrl;
        window.location.replace(redirectUrl);
      } else if (auth.currentUser?.isAnonymous && engagementStore.engagement) {
        navigate("/auth");
      } else {
        if (sessionStore.authUserLoaded === "EMPTY" && !engagementStore.engagement && !profileStore.profile) {
          navigate("/auth");
        } else if (sessionStore.authUserLoaded && profileStore.profile) {
          navigate(location.pathname);
        }
      }
    });
  }, [
    navigate,
    profileStore.profile,
    sessionStore.authUserLoaded,
    sessionStore.isLoggingOut,
    engagementStore.engagement,
    sessionStore.authUser,
    remoteConfigStore.redirectUrl,
    language,
  ]);

  useEffect(() => {
    updateProfile();
  }, [language, updateProfile]);

  useEffect(() => {
    if (commonStore.isMissingCreditReport) {
      callToActionsStore.getCreditReport(profileStore.profile!.id!);
    }
  }, [callToActionsStore, commonStore.isMissingCreditReport, profileStore.profile]);

  useEffect(() => {
    const unsubscribe = onAuthStateChange(token || "");
    return () => unsubscribe();
  }, [onAuthStateChange, token]);

  useEffect(() => {
    /**
     *After authentication if page is refreshed with just root url, redirect to dashboard
     */
    autorun(() => {
      if (profileStore.profile && location.pathname === "/") {
        navigate("/dashboard");
      }
    });
  }, [navigate, profileStore.profile]);

  useEffect(() => {
    autorun(() => {
      if (sessionStore.profileError) {
        auth.signOut().then(() => {
          navigate("/auth");
        });
      }
    });
  }, [navigate, sessionStore.profileError, t]);

  if (commonStore.loadingEngagementOrProfile) {
    return <Loader />;
  } else {
    const profileExists = profileStore.profile;
    if (profileExists) {
      if (!authUser?.isAnonymous) {
        return <Outlet />;
      }
    }
  }
});
