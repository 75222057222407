/// <reference types="vite-plugin-svgr/client" />
import { useTranslation } from 'react-i18next';

import { useStyles } from './MonthTag.styles';
import { Text } from '../Text';

export type MonthTagVariants = 'primary' | 'secondary' | 'tertiary';

export interface MonthTagProps {
  month: number;
  variant: MonthTagVariants;
}

export const MonthTag: React.FC<MonthTagProps> = ({ month, variant }) => {
  const classes = useStyles({ variant });
  const { t } = useTranslation('plan_option');

  return (
    <div className={`${classes.tag} ${classes[variant]}`}>
      <Text
        fontSize="xsmall"
        fontWeight="bold"
        color={variant == 'primary' ? 'accent' : 'white'}
      >
        {month} {t('tag')}
      </Text>
    </div>
  );
};
