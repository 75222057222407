import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      padding: 0,
      paddingRight: "2.5rem",
    },
    width: "100%",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginBottom: "2.5rem",
    [theme.breakpoints.up("md")]: {
      marginBottom: "1.125rem",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  supportMaterial: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
  supportMaterialItem: {
    whiteSpace: "pre-line",
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
  },
  svgContainer: {
    alignItems: "flex-start",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: 5,

    "& img": {
      height: "30px",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      "& img": {
        height: "30px",
      },
    },
  },
}));
