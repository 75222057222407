import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    boxShadow: theme.customShadows.clickable,
    borderRadius: "8px",
    padding: "0.25rem 0.75rem 0.25rem 0.75rem",
    margin: "0 24px",
    backgroundColor: theme.palette.common.white,
  },
  svgIconLogo: {
    width: "120px !important",
    height: "38px !important",
    alignSelf: "end",
  },
}));
