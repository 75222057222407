export interface ServicerSettings {
  states: string[];
  enabled: boolean;
  crmRoute: string;
  programType: string;
  dspAnalysisRules: string[];
  servicingCriteria: string[];
  servicerWeblink: string;
  calculatorConstantOverrides: { [id: string]: number };
}

export interface Servicer {
  id: ServicerId;
  displayName: string;
  logo: string;
  legalName: string;
  settings: ServicerSettings;
}

export interface ServicerOpenStatus {
  isOpen: boolean;
}

export enum ServicerIdEnum {
  LIGHT_HOUSE_FINANCIAL = "LighthouseFinanceSolutions",
  CONSOLIDATED_CREDIT = "ConsolidatedCredit",
  NO_SERVICER = "NoServicer",
}

export type ServicerId = (typeof ServicerIdEnum)[keyof typeof ServicerIdEnum];
