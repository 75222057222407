import { Box, ButtonGroup, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { observer } from 'mobx-react-lite';
import {
  FC,
  ReactElement,
  Children,
  cloneElement,
  Fragment,
  ReactNode,
} from 'react';

import { NavItemPropsBase } from '@components/common';
import { Copyright } from '@components/common/Copyright';
import { TermsAndPolicy } from '@components/common/TermsAndPolicy';
import { useStore } from '@stores';

import { useStyles } from './NavBar.styles';
import { ContinueChatButton } from '../ContinueChatButton';

export interface NavBarProps {
  children: ReactNode;
}

export const NavBar: FC<NavBarProps> = observer(({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const {
    modalStore,
    chatStore: { hasActiveChat },
  } = useStore();

  const renderChildrenWithDividers = () => {
    const childrenArray = Children.toArray(children);

    return childrenArray.map((child, index) => {
      const childWithProp = cloneElement(
        child as ReactElement<NavItemPropsBase>,
        {
          orientation: 'horizontal',
        }
      );

      return <Fragment key={index}>{childWithProp}</Fragment>;
    });
  };

  const desktopNav = () => {
    return (
      <div className={classes.sideBarWrapper}>
        <div className={classes.sidebarViewContainer}>
          <Box padding={5}>
            <div className={classes.svgContainer}>
              <div className="svgElement"></div>
            </div>

            <ButtonGroup data-cy="navigation" className={classes.group}>
              {renderChildrenWithDividers()}
            </ButtonGroup>
          </Box>
          <Box flexGrow={1} />

          {!modalStore.display && hasActiveChat && (
            <ContinueChatButton
              sx={{
                m: 'auto',
                width: 'calc(100% - 32px)',
                py: 2,
                bgcolor: (theme) => `${theme.palette.brandBlue.dark}19`,
                mb: 4,
                '&:hover': {
                  bgcolor: (theme) => `${theme.palette.brandBlue.dark}33`,
                },
              }}
            />
          )}
          <Stack px={5} pb={5} pt={0} gap={1}>
            <Copyright companyName="Debt.com" /> <TermsAndPolicy />
          </Stack>
        </div>
      </div>
    );
  };

  const mobileNav = () => {
    return (
      <Box m={0}>
        {hasActiveChat && (
          <Box
            bgcolor="white"
            sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
          >
            <ContinueChatButton
              fullWidth
              sx={{
                py: 2,
                bgcolor: (theme) => `${theme.palette.brandBlue.dark}19`,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                '&:hover': {
                  bgcolor: (theme) => `${theme.palette.brandBlue.dark}33`,
                },
                borderBottom: (theme) =>
                  `1px solid ${theme.palette.brandGray.light}`,
                boxShadow: (theme) => theme.customShadows.navBottom,
              }}
            />
          </Box>
        )}
        <ButtonGroup
          data-cy="navigation"
          fullWidth
          className={classes.group}
          sx={{
            padding: '0.75rem',
            background: 'white',
            boxShadow: (theme) =>
              !hasActiveChat ? theme.customShadows.navBottom : 'none',
          }}
        >
          {children}
        </ButtonGroup>
      </Box>
    );
  };

  return isDesktop ? desktopNav() : mobileNav();
});
