import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import userPlanImg from "@assets/MyPlansReEntryImageOptimized.jpg";

interface Props {
  pageVariant: string;
  isSelected: boolean;
  pendingEnrollment: boolean;
  dropShadow: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: "300px",
    width: "100%",
    height: "57vh",
    backgroundImage: (props: Props) => (props.pageVariant !== "negative" ? `url(${userPlanImg})` : ""),
    backgroundPosition: "center",
    backgroundSize: "140%",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("md")]: {
      backgroundPosition: "40% 40%",
      backgroundSize: "cover",
      backgroundImage: `url(${userPlanImg})`,
      position: "relative",
      marginLeft: "-7rem",
      width: "115%",
    },
  },
  img: {
    backgroundPosition: "center",
    backgroundSize: "auto 150%",
    backgroundRepeat: "no-repeat",
    objectFit: "contain",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "8px",
    boxShadow: theme.customShadows.clickable,
    padding: "0 1rem",
    [theme.breakpoints.up("md")]: {
      boxShadow: "none",
      padding: 0,
    },
    overflow: "hidden",
    position: "relative",
    zIndex: "1",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginTop: "300px",
    [theme.breakpoints.up("md")]: {
      marginTop: "unset",
    },
  },
  setButtonWidth: {
    width: "100%",
  },
  setButtonPadding: {
    [theme.breakpoints.up("md")]: {
      padding: "12px 1px",
    },
  },
  setButtonFont: {
    [theme.breakpoints.up("md")]: {
      fontSize: theme.fontSizes.medium,
      lineHeight: theme.fontLineHeights.xxxsmall,
    },
  },
  planCardWrapper: {
    display: "flex",
    flexDirection: "column-reverse",
    // height: '100vh',
    margin: "0 auto",
    bottom: "100px",
    position: "relative",
    maxWidth: "366px",
    [theme.breakpoints.up("md")]: {
      margin: "-200px 0px 0px 200px",
      width: "366px",
      position: "relative",
      height: "auto",
    },
  },
  card: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "8px",
    outline: (props: Props) =>
      `${props.isSelected && !props.pendingEnrollment ? "2px" : "0px"} solid ${theme.palette.brandHoverAccent.main}`,
    boxSizing: "border-box",
    boxShadow: (props: Props) => (props.dropShadow ? theme.customShadows.clickable : "unset"),
    overflow: "hidden",
    "&:hover": {
      cursor: (props: Props) => `${props.pendingEnrollment ? "" : "pointer"}`,
      backgroundColor: (props: Props) => `${props.pendingEnrollment ? "" : "theme.palette.brandGray.lighter"}`,
    },
  },
}));
