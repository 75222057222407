import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Grid, IconButton, alpha } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common';
import { useStore } from '@stores';
import { theme } from '@styles';
import {
  BudgetTypeEnum,
  BudgetItemTypeEnum,
  IBudgetItemData,
  JourneyId,
} from '@types';
import { formatNumberToCurrencyString } from '@utils';

const headerStyles = {
  optional: {
    bgcolor: alpha(theme.palette.brandGreen.dark!, 0.1),
    color: theme.palette.brandGreen.dark,
  },
  custom: {
    bgcolor: alpha(theme.palette.brandBlue.dark!, 0.1),
    color: theme.palette.brandBlue.darker,
  },
};

export const BudgetItem = observer(
  ({
    data,
    budgetType,
  }: {
    data: IBudgetItemData;
    budgetType: BudgetTypeEnum;
  }) => {
    const { t } = useTranslation('budget_tool');
    const {
      amount,
      subCategory,
      type: dataType,
      name,
      frequency,
    } = data;
    const { budgetStore, profileStore } = useStore();
    const isDMP = profileStore.journeyId === JourneyId.DEBT_MANAGEMENT;
    const isDSP = profileStore.journeyId === JourneyId.DEBT_SETTLEMENT;
    const isIncome = budgetType === BudgetTypeEnum.INCOME;
    const isDebt = budgetType === BudgetTypeEnum.DEBT;
    const isExpense = budgetType === BudgetTypeEnum.EXPENSE;

    const editBudgetItem = () => {
      budgetStore.setItemData(data);
      budgetStore.setItemBudgetType(budgetType);
      budgetStore.setIsBudgetEdit(true);
    };
    const formatedAmount =
      amount === null || typeof amount === 'string'
        ? '--'
        : formatNumberToCurrencyString(amount);

    return (
      <Box
        boxShadow={theme.customShadows.clickable}
        borderRadius={2}
        sx={{ background: theme.palette.common.white }}
      >
        {(isDMP || !isDebt) && (
          <Box
            height={18}
            textTransform="uppercase"
            fontSize={10}
            fontWeight={600}
            pl={2.5}
            pt={0.25}
            sx={{
              bgcolor: theme.palette.brandGreen.dark,
              color: theme.palette.common.white,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              ...((dataType === BudgetItemTypeEnum.OPTIONAL ||
                dataType === BudgetItemTypeEnum.CUSTOM) &&
                headerStyles[dataType]),
            }}
          >
            {dataType && t(dataType)}
          </Box>
        )}
        <Box px={2} py={subCategory ? 0.5 : 0}>
          <Grid
            container
            justifyContent="space-between"
            alignItems={subCategory ? 'start' : 'center'}
            padding={1}
          >
            <Grid item xs={8} maxWidth={150}>
              <Box
                display="flex"
                alignItems={subCategory && isExpense ? 'start' : 'center'}
              >
                {(isDMP || !isDebt) && (
                  <IconButton
                    edge="start"
                    aria-label="edit"
                    sx={{
                      color: theme.palette.brandGreen.dark,
                      marginRight: 1,
                      marginTop: subCategory ? -1 : 0,
                    }}
                    onClick={editBudgetItem}
                    data-cy={`edit-budget-item-${name?.replaceAll(' ', '-')}`}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                )}
                <div>
                  <Text fontWeight="semiBold">{t(name || '')}</Text>
                  {isDSP && subCategory && !isIncome && (
                    <Text
                      variant="caption"
                      fontSize="xsmall"
                      textTransform="uppercase"
                    >
                      {t(
                        `categoryValues.${subCategory}`
                      )}
                    </Text>
                  )}
                </div>
              </Box>
            </Grid>

            <Grid item>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Text fontWeight="semiBold" data-cy="budget-item-amount">
                  ${formatedAmount}/{t(`frequencyLabel.Abb.${frequency}`)}.
                </Text>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
);
