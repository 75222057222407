import { makeAutoObservable } from "mobx";

import { DocumentPayload } from "@types";

import { rollbar } from "../../rollbarConfig";
import { AxiosAgent } from "../services";

export default class DigitalSignStore {
  url: string = "";
  loadingCreatingDocument: boolean = false;
  loadingGeneratingUrl: boolean = false;
  errorCreatingDocument: boolean = false;
  errorGeneratingUrl: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async createDigitalSignDocument(profileId: string, body: DocumentPayload) {
    try {
      this.loadingCreatingDocument = true;
      await AxiosAgent.DigitalSignature.createESignDocument(profileId, body);
    } catch (error) {
      rollbar.error("Error creating document", error as Error);
      this.errorCreatingDocument = true;
    } finally {
      this.loadingCreatingDocument = false;
    }
  }

  async getDigitalSignDocument(profileId: string, type: string) {
    try {
      this.loadingGeneratingUrl = true;
      const response = (await AxiosAgent.DigitalSignature.generateESignLink(profileId, type)) as { url: string };
      const { url } = response;
      this.url = url;
    } catch (error) {
      rollbar.error("Error getting document", error as Error);
      this.errorGeneratingUrl = true;
    } finally {
      this.loadingGeneratingUrl = false;
    }
  }

  updateDigitalSignDocument(profileId: string, type: string, body: { [key: string]: string }) {
    try {
      AxiosAgent.DigitalSignature.updateESignDocument(profileId, type, body);
    } catch (error) {
      rollbar.error("Error uploading document", error as Error);
    }
  }
}
