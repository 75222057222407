import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  buttonStyle: {
    "&.MuiButton-root": {
      textDecoration: "underline",
      textTransform: "capitalize",
      padding: 0,
      display: "inline",
      color: "blue",
    },
  },

  container: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    top: "150px",
    left: 0,
    zIndex: 110,
    right: 0,
    height: "calc(100vh - 150px)",
    backgroundColor: theme.palette.background.default,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "500px",
      right: 0,
      top: 0,
      padding: 0,
      height: "100%",
    },
    "& a": {
      wordWrap: "break-word",
    },
  },

  header: {
    alignSelf: "flex-end",
    padding: "10px",
  },
  content: {
    flex: 1,
    overflowY: "auto",
    padding: "10px",
  },
}));
