import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, Button, Fade, useMediaQuery } from '@mui/material';
import Lottie from 'lottie-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import checkAnimation from '@assets/lottie/check.json';
import { ButtonBranded, FooterLegalInfo } from '@components/common';
import { useStore } from '@stores';
import { theme } from '@styles';
import { GTMEsignCompleted } from '@utils';

interface ESignThankYouProps {
  isLoading: boolean;
  onClose: () => void;
}

export const ESignThankYou: React.FC<ESignThankYouProps> = ({
  isLoading,
  onClose,
}) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const {
    modalStore,
    remoteConfigStore: { featureFlags },
  } = useStore();

  const { isWithinServicerHours, declineAgentDirectContact } = modalStore;
  const { enableAgentDirectContact } = featureFlags;

  const { t } = useTranslation('plan_option');

  const [showContent, setShowContent] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const handleLottieComplete = () => {
    setShowContent(true);
  };

  const handleClose = () => {
    GTMEsignCompleted();
    setIsDisable(true);
    onClose();
  };

  let callStatus;

  if (isWithinServicerHours && enableAgentDirectContact) {
    if (declineAgentDirectContact || isDesktop) {
      callStatus = 'onHoursDecline';
    } else {
      callStatus = 'onHours';
    }
  } else {
    callStatus = 'offHours';
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 1300,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            background: 'white',
            width: '100%',
            minHeight: '48px',
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.12)',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button variant="text" disabled={isDisable}>
            <CloseIcon sx={{ color: '#578641' }} onClick={handleClose} />
          </Button>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 16px',
          }}
        >
          <Lottie
            animationData={checkAnimation}
            loop={false}
            onComplete={handleLottieComplete}
            style={{ height: '200px', width: '200px' }}
          />
          <Fade in={showContent} timeout={0}>
            <Typography
              variant="h4"
              sx={{ textAlign: 'center', fontWeight: 'bold', mb: 2 }}
            >
              {t('thankYou')}
            </Typography>
          </Fade>
          <Fade in={showContent} timeout={0}>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', maxWidth: '300px' }}
            >
              {callStatus === 'onHoursDecline'
                ? t('thankYouOnHoursDeclineDescription')
                : callStatus === 'onHours'
                  ? t('thankYouOnHoursDescription')
                  : t('thankYouOffHoursDescription')}
            </Typography>
          </Fade>
        </Box>

        <Box
          sx={{
            padding: '16px',
          }}
        >
          <Fade in={showContent} timeout={500}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '600px',
                mx: 'auto',
                px: 2,
              }}
            >
              <ButtonBranded
                id={callStatus}
                type="submit"
                variant="primary"
                loading={isLoading}
                disabled={isDisable}
                onClick={handleClose}
                sx={{
                  width: '100%',
                }}
              >
                {callStatus === 'onHours'
                  ? t('thankYouOnHours')
                  : t('thankYouOffContinue')}
              </ButtonBranded>
            </Box>
          </Fade>
        </Box>
        <FooterLegalInfo />
      </Box>
    </Box>
  );
};
