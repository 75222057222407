import { makeStyles } from "@mui/styles";

import { theme } from "@styles";

export const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      padding: "2rem",
      width: "75%",
    },
  },
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    display: "flex",
    justifyContent: "left",
    flexDirection: "column",
    padding: "1rem 1rem",
    borderRadius: "8px",
    gap: "1rem",
  },
  contentWrapper: {
    width: "100%",
    boxShadow: theme.customShadows.clickable,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
      boxShadow: "unset",
      gap: "1.5rem",
    },
  },
  marginBottom: {
    marginBottom: "2rem",
  },
  nameContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "left",
    flexBasis: "50%",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      gap: ".5rem",
      justifyContent: "center",
    },
  },
  textWrap: {
    overflowWrap: "anywhere",
    whiteSpace: "nowrap",
    display: "block",
  },
  icon: {
    width: "76px !important",
    height: "76px !important",
    backgroundColor: theme.palette.brandGray.lighter,
    borderRadius: "40px",
    padding: "1rem",
    color: theme.palette.brandGray.main,
  },
  iconContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "1.5rem 1rem 1rem 1rem",
    backgroundColor: theme.palette.common.white,
    borderRadius: "8px",
    gap: "2rem",
    [theme.breakpoints.up("md")]: {
      flexBasis: "30%",
      flexDirection: "column",
      boxShadow: theme.customShadows.clickable,
      height: "fit-content",
      paddingBottom: "1.5rem",
      gap: ".5rem",
    },
  },
  miniDashContainer: {
    [theme.breakpoints.up("md")]: {
      flexBasis: "70%",
      boxShadow: theme.customShadows.clickable,
      backgroundColor: theme.palette.common.white,
      borderRadius: "8px",
    },
  },
  slideContainer: {
    display: "flex",
  },
  backdrop: {
    color: theme.palette.background.overlay,
    zIndex: theme.zIndex.drawer + 1,
    justifyContent: "end",
  },

  alertBox: {
    paddingTop: "20px",
    borderRadius: "2px",
    width: "100%",
  },
}));
