import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type Props = {
  variant: string;
};

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderBottom: (props: Props) =>
      props.variant == "profile" ? `1px solid ${theme.palette.brandGray.light}` : `unset)`,
  },
  header: {
    paddingBottom: "1rem",
  },
  content: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    padding: "1rem 1rem",
    borderRadius: "8px",
    gap: "1rem",
    width: "100%",
  },
  contentText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  icon: {
    color: theme.palette.brandGray.main,
    width: "35px !important",
    height: "35px !important",
  },
  arrow: {
    marginLeft: "auto",
  },
}));
