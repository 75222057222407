import { Button, ButtonProps as MuiButtonProps, SxProps } from '@mui/material';

import { Loader } from '@components/index';
import { theme } from '@styles';
import { GTMButtonClick, GTMDynamicEvent } from '@utils';

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'accent'
  | 'textonly'
  | 'textonlyaccent';

export interface ButtonProps extends Omit<MuiButtonProps, 'variant' | 'color'> {
  variant?: ButtonVariants;
  className?: string;
  label?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  loading?: boolean;
  gtmEvent?: string;
  padding?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  sx?: SxProps;
}

const buttonStyles: { [key in ButtonVariants]: SxProps } = {
  primary: {
    backgroundColor: theme.palette.brandGreen.dark,
    color: theme.palette.common.white,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
      backgroundColor: theme.palette.brandHover.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.buttonDisabledBKG.main,
      color: theme.palette.buttonDisabledText.main,
      pointerEvents: 'none',
    },
  },

  secondary: {
    textDecoration: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.brandGreen.dark,
    border: 'none',
    boxShadow: `inset 0 0 0 2px ${theme.palette.brandGray.light}`,
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
      backgroundColor: theme.palette.brandGreen.dark,
      boxShadow: `inset 0 0 0 2px ${theme.palette.brandGreen.dark}`,
      color: theme.palette.common.white,
    },
    '&:disabled': {
      color: theme.palette.brandGray.dark,
      backgroundColor: 'transparent',
      boxShadow: `inset 0 0 0 2px ${theme.palette.brandGray.main}`,
      pointerEvents: 'none',
    },
  },

  accent: {
    backgroundColor: theme.palette.brandAccent.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.brandHoverAccent.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.brandGray.main,
      color: theme.palette.brandGray.dark,
      pointerEvents: 'none',
    },
  },

  textonlyaccent: {
    color: theme.palette.brandBlue.dark,
    backgroundColor: 'unset',
    border: 'none',
    borderRadius: 'unset',
    width: 'auto',
    padding: 'unset',
    margin: 'unset',
    textAlign: 'left',
    transition: '0.3s',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.brandBlue.darker,
      textDecoration: 'underline',
    },
    '&:disabled': {
      color: `${theme.palette.brandGray.main} !important`,
      pointerEvents: 'none',
    },
  },

  textonly: {
    color: theme.palette.brandGray.main,
    backgroundColor: 'unset',
    border: 'none',
    borderRadius: 'unset',
    width: 'auto',
    padding: 'unset',
    margin: 'unset',
    textAlign: 'left',
    transition: '0.3s',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.brandGray.dark,
      textDecoration: 'underline',
    },
    '&:disabled': {
      color: `${theme.palette.brandGray.main} !important`,
      pointerEvents: 'none',
    },
  },
};

export const ButtonBranded: React.FC<ButtonProps> = ({
  variant = 'primary',
  label,
  loading,
  fontSize = theme.fontSizes.xlarge,
  fontWeight = theme.fontWeights.semiBold,
  lineHeight = theme.fontLineHeights.xsmall,
  padding,
  children,
  gtmEvent,
  onClick,
  sx,
  ...props
}) => {
  const handleOnClick = () => {
    if (loading) return;
    onClick && onClick();
    if (gtmEvent) GTMDynamicEvent(gtmEvent);
    else GTMButtonClick(label ?? children?.toString() ?? '');
  };

  return (
    <Button
      sx={[
        {
          border: 0,
          borderRadius: 1,
          width: '100%',
          fontSize,
          lineHeight,
          fontWeight,
          transition: '0.3s',
          padding: padding || '18px 32px',
          [theme.breakpoints.up('md')]: {
            padding: padding || '12px 24px',
          },
          ...buttonStyles[variant],
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={handleOnClick}
      {...props}
      disabled={!!loading || props.disabled}
    >
      {loading ? (
        <Loader size={'1.5rem'} showOneColorSpinner />
      ) : (
        label || children
      )}
    </Button>
  );
};
