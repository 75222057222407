import { useStore } from "@stores";

import { GTMPlanSubmitted, GTMSessionExtended, GTMSessionNotExtended, GTMSessionTimeoutPrompted } from "../gtm";
import { ProfileSubStatusEnum } from "../subStatus";

/**
 * Custom hook to manage session timeout with live countdown.
 *
 * This hook starts a timer for user sessions/absolute timer and updates a `timeLeft` variable
 * which can be used to display the remaining time to the user in a "mm:ss" format.
 *
 * @returns An object containing the `startTimer` function, `extendSession` function, and `timeLeft` state.
 */

export const useSessionTimer = () => {
  const {
    modalStore,
    profileStore,
    remoteConfigStore,
    sessionStore: { sessionTimer, clearSessionTimer, setSessionTimer, setTimeLeft, logout },
  } = useStore();
  const sessionTimeout = remoteConfigStore.timers.sessionTimeout || 15;
  const allowedIdleTime = remoteConfigStore.timers.allowedIdleTime || 1;

  const enrollmentIsInProgress =
    profileStore.profile?.subStatus === ProfileSubStatusEnum.IDA_ENROLLMENT_STARTED ||
    profileStore.profile?.subStatus === ProfileSubStatusEnum.IDA_CREDIT_AUTH;

  /**
   * Formats the time left as a string.
   *
   * @param minutes The minutes left.
   * @param seconds The seconds left.
   * @returns A string formatted as "mm:ss".
   */
  const formatTimeLeft = (minutes: number, seconds: number) => {
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  /**
   * Starts the session timer and updates the `timeLeft` state.
   */
  const startTimer = () => {
    setTimeLeft(formatTimeLeft(sessionTimeout, 0));
    const sessionEndTime = new Date();
    sessionEndTime.setMinutes(sessionEndTime.getMinutes() + sessionTimeout);

    const sessionEndTimeMilliseconds = sessionEndTime.getTime();

    const profileExpirationDateMilliseconds = new Date(profileStore.profile!.expirationDate).getTime();

    const sessionEndsAfterProfileExpiration = sessionEndTimeMilliseconds > profileExpirationDateMilliseconds;

    if (sessionEndsAfterProfileExpiration && enrollmentIsInProgress) {
      profileStore.updateProfileExpirationDate(new Date(sessionEndTime.setMilliseconds(0)));
    }

    const updateTimer = async () => {
      const now = new Date();
      const sessionTimeRemaining = sessionEndTime.getTime() - now.getTime();
      const minutes = Math.floor(sessionTimeRemaining / 1000 / 60);
      const seconds = Math.floor((sessionTimeRemaining / 1000) % 60);

      const sessionAboutToExpire = (minutes === allowedIdleTime && seconds === 0) || minutes < allowedIdleTime;

      setTimeLeft(formatTimeLeft(minutes, seconds));

      if (enrollmentIsInProgress) {
        if (now.getTime() >= profileExpirationDateMilliseconds) {
          await profileStore.updateEnrollmentStatus(ProfileSubStatusEnum.IDA_TIMED_OUT);
        }
      }

      if (sessionAboutToExpire && enrollmentIsInProgress) {
        if (!modalStore.showExtendModal) {
          GTMSessionTimeoutPrompted(modalStore.module?.name ?? "");
          modalStore.setShowExtendModal(true);
        }
      }

      if (sessionTimeRemaining <= 0) {
        if (sessionTimer) {
          clearSessionTimer();
        }
        if (modalStore.module?.name === "SelectPlan") {
          GTMPlanSubmitted("abandoned");
        }
        GTMSessionNotExtended();
        logout();
      }
    };

    setSessionTimer(setInterval(updateTimer, 1000));
  };

  const extendSession = async () => {
    const now = new Date();
    const profileExpirationDate = new Date(profileStore.profile!.expirationDate);
    const nextExpirationDate = new Date(now.getTime() + sessionTimeout * 60000);

    const timeUntilProfileExpires = profileExpirationDate.getTime() - now.getTime();

    const allowedIdleMilliseconds = allowedIdleTime * 60000;

    const profileIsAboutToExpire = timeUntilProfileExpires <= allowedIdleMilliseconds;

    if (enrollmentIsInProgress && profileIsAboutToExpire) {
      await profileStore.updateProfileExpirationDate(new Date(nextExpirationDate.setMilliseconds(0)));
    }

    clearSessionTimer();

    setTimeLeft(formatTimeLeft(sessionTimeout, 0));
    startTimer();
    modalStore.setShowExtendModal(false);
    GTMSessionExtended();
  };

  const forceExtendSession = async () => {
    const now = new Date();

    const profileExpirationDate = new Date(profileStore.profile!.expirationDate);

    const nextExpirationDate = new Date(now.getTime() + sessionTimeout * 60000);

    // Don't extend the session if the current expiration time is greater than the extension time
    if (profileExpirationDate > nextExpirationDate) {
      return;
    }

    if (enrollmentIsInProgress) {
      await profileStore.updateProfileExpirationDate(new Date(nextExpirationDate.setMilliseconds(0)));
    }

    clearSessionTimer();

    setTimeLeft(formatTimeLeft(sessionTimeout, 0));
    startTimer();
    modalStore.setShowExtendModal(false);
    GTMSessionExtended();
  };

  return { startTimer, extendSession, forceExtendSession };
};
