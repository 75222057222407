import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common';
import { theme } from '@styles';

import { useCallForHelpStyles } from './CallForHelp.styles.ts';
import callMe from '../../../../assets/callforhelp_optimized.jpg';

export const CallForHelpModule: FC = observer(() => {
  const { t } = useTranslation('call_for_help');
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const classes = useCallForHelpStyles();
  return (
    <>
      <div className={classes.containerWrapper}>
        <div className={classes.container}>
          <div>
            <Text
              fontSize={!isDesktop ? 'xxxxlarge' : 'xxxlarge'}
              fontWeight="bold"
              lineHeight={!isDesktop ? 'xxxxxlarge' : 'xxxlarge'}
              className={classes.fontDarkGray}
            >
              {t('header1')}
            </Text>
          </div>
          {!isDesktop && (
            <div className={classes.paddingTop16}>
              <img
                src={callMe}
                alt={'Call for help'}
                className={classes.image}
                data-cy="call-for-help-image"
              />
            </div>
          )}
          <div className={classes.paddingTop16}>
            <Text
              fontSize="medium"
              fontWeight="semiBold"
              lineHeight="medium"
              className={classes.fontAccent}
            >
              {t('header2')}
            </Text>
          </div>
          <div
            className={isDesktop ? classes.paddingTop16 : classes.paddingTop8}
          >
            <Text
              fontSize="small"
              fontWeight="light"
              lineHeight="xxsmall"
              className={classes.fontDarkGray}
            >
              {t('infobox1')}
            </Text>
          </div>
        </div>
        {isDesktop && (
          <img
            src={callMe}
            alt={'Call for help'}
            className={classes.image}
            data-cy="call-for-help-image"
          />
        )}
      </div>
    </>
  );
});
