import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  slideContainer: {
    display: "flex",
  },

  backdrop: {
    color: theme.palette.background.overlay,
    zIndex: theme.zIndex.drawer + 1,
    justifyContent: "end",
  },

  alertBox: {
    paddingTop: "20px",
    borderRadius: "2px",
    width: "100%",
  },
}));
