import { Close } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { memo, useEffect, useRef } from 'react';

import { useStyles } from '../TermsAndConditions.styles';

export const TermsEnVersion = memo(
  ({
    hideModal,
    scrollToPrivacyPolicyRef,
  }: {
    hideModal: () => void;
    scrollToPrivacyPolicyRef?: boolean;
  }) => {
    const privatePolicyTargetRef = useRef<HTMLDivElement>(null);
    const disputeResolutionRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    useEffect(() => {
      if (scrollToPrivacyPolicyRef) {
        setTimeout(() => {
          privatePolicyTargetRef.current?.scrollIntoView({
            behavior: 'instant',
            block: 'start',
          });
        }, 100);
      }
    }, [scrollToPrivacyPolicyRef]);

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <IconButton aria-label="terms-of-use" onClick={() => hideModal()}>
            <Close />
          </IconButton>
        </div>
        <div className={classes.content}>
          <div style={{ whiteSpace: 'pre-line' }}>
            <h2 style={{ margin: 0 }}>Terms of Use</h2>
          </div>
          <p>
            <b>Last updated: April 19th, 2024. </b>
          </p>
          <h2>
            <span></span>
            AGREEMENT BETWEEN USER AND DEBT.COM
            <span></span>
          </h2>
          <p>
            These Terms and Conditions of Use (“Agreement”) are a legal
            agreement between you and Debt.com, LLC (hereinafter referred to as
            “Website Owner”), the owner and developer of Debt.com (the “Site”).
            By using, registering, or otherwise interacting with the Site for
            any service provided through Debt.com, you agree to be bound by all
            of the terms and conditions (the “Terms”) set forth in this
            Agreement. You understand that you are not able to modify these
            terms and that you must accept all terms “AS-IS.” Should you choose
            not to accept all terms, simply do not register, use, or interact
            with this Site.
          </p>
          <p>
            Our{' '}
            <Button
              className={classes.buttonStyle}
              onClick={() => {
                privatePolicyTargetRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }}
            >
              <b>Privacy Policy</b>
            </Button>
            , hereby incorporated by reference into this Agreement, explains how
            we collect, protect, share and use your information as a part of our
            platform, and all of our product and services.
          </p>
          <p>
            YOU AGREE THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY
            THESE TERMS. This Agreement contains a binding arbitration
            agreement, which provides that you and we agree to resolve all
            disputes through binding individual arbitration, and you and we give
            up any right to have those disputes decided by a judge or a jury.
            You have the right to opt out of our agreement to arbitrate. See the{' '}
            <Button
              className={classes.buttonStyle}
              onClick={() => {
                disputeResolutionRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }}
            >
              Legal Disputes
            </Button>{' '}
            section of this Agreement.
          </p>
          <p>
            Debt.com is a financial education website and referral agency that
            provides educational materials about debt relief financial services
            and connects consumers with financial service providers to help the
            consumers to live healthier financial lives. When you call our
            number, you may be directly connected with one of our partners, or a
            third-party to assist you. Independent service providers may charge
            fees for their services and have their own terms of service.
            Debt.com is not responsible and does not guarantee any outcomes from
            these providers. Services may not be available in all states so
            please call or check our website for details.
          </p>
          <h3>MODIFICATION OF THESE TERMS OF USE</h3>
          <p>
            DEBT.COM RESERVES THE RIGHT, WITH OR WITHOUT NOTICE, TO MAKE CHANGES
            TO THE TERMS AT WEBSITE OWNER’S DISCRETION. YOUR CONTINUED USE OF
            ANY PART OF THIS WEBSITE OR ANY SERVICE CONSTITUTES YOUR ACCEPTANCE
            OF SUCH CHANGES TO THIS AGREEMENT. YOU SHOULD REVIEW THIS AGREEMENT
            PERIODICALLY TO DETERMINE IF ANY CHANGES HAVE BEEN MADE. THE MOST
            CURRENT VERSION OF THESE TERMS, WHICH WILL BE NOTED BY THE “LAST
            UPDATED” DATE HEREIN, SUPERSEDES ALL PREVIOUS VERSIONS.
          </p>
          <h3>LINKS TO THIRD PARTY SITES</h3>
          <p>
            This Site may contain links to external websites (“Linked Sites”).
            The Linked Sites are not under the control or management of
            Debt.com, and Debt.com is not responsible for the contents of any
            Linked Site, including without limitation any links contained in a
            Linked Site, or any changes or updates to a Linked Site. Debt.com is
            not responsible for webcasting or any other form of transmission
            received from any Linked Site. Debt.com is providing these links to
            you only as a convenience, and the inclusion of any link does not
            imply endorsement by Debt.com of the site or any association with
            its operators.
          </p>
          <h3>NO UNLAWFUL OR PROHIBITED USE</h3>
          <p>
            As a condition of your use of the Site, you warrant to Debt.com that
            you will not use the Site for any purpose that is unlawful or
            prohibited by law, regulation, or these terms. You may not use the
            Site in any manner which could damage, disable, overburden, or
            impair the Site or interfere with any other party’s use and
            enjoyment of the Site. You may not obtain or attempt to obtain any
            materials or information from the Site through any means not
            intentionally made available or provided for through the Debt.com on
            this Site.
          </p>
          <h3>YOU ARE RESPONSIBLE FOR YOUR FINANCIAL DECISIONS</h3>
          <p>
            Debt.com , through our Site and affiliates sites, may provide you
            with a venue through which you can obtain educational financial
            information as well as learn about various third-party service
            providers, such as financial institutions, credit card providers,
            debt settlement companies, lenders and other financial professionals
            (“Service Providers”). You understand and acknowledge that Debt.com
            may be compensated by Service Providers for referrals made through
            this Site. Debt.com and Website Owner do not guarantee the accuracy
            or completeness of any of the information provided on the Site or
            with regards to the Service Providers, and are not responsible for
            any loss resulting from your reliance on such information. You
            understand that you are to examine the information provided and
            conduct your own research of whether the services marketed on this
            Site are fitting to your financial situation. We do not endorse or
            recommend the products or services of any Service Provider, and we
            are not an agent or advisor to you or any Service Provider. We do
            not validate or investigate the licensing, certification or other
            requirements and qualifications of Service Providers. It is your
            responsibility to investigate Service Providers. You acknowledge and
            agree that Service Providers are solely responsible for any services
            that they may provide to you. Debt.com is not liable for any losses,
            costs, damages or claims in connection with, arising from, or
            related to, your use of any Service Provider’s products or services.
            We urge you to obtain the advice of qualified professionals (such as
            tax advisor, accountant, lawyer, etc.) who are fully aware of your
            individual circumstances before you make any financial decisions.
            You acknowledge and agree that you rely on your own judgment and
            that of such advisors in selecting any products or services offered
            by Service Providers.
          </p>
          <h3>
            NO GUARANTEE OF QUOTES, FEES, TERMS, RATES, COVERAGE OR SERVICES
          </h3>
          <p>
            We do not make any warranties or representations regarding the
            quotes, fees, terms, rates, coverage or services offered or made
            available by Service Providers through our Site. We do not guarantee
            that quotes, fees, terms, rates, coverage or services offered by
            Service Providers are the best available in the marketplace. You may
            be able to find better fitting offers elsewhere.
          </p>
          <h3>USE OF COMMUNICATION SERVICES</h3>
          <p>
            The Site may contain bulletin board services, chat areas, news
            groups, forums, communities, personal web pages, calendars, and/or
            other message or communication facilities designed to enable you to
            communicate with the public at large or with a group (collectively,
            “Communication Services”), you agree to use the Communication
            Services only to post, send and receive messages and material that
            are proper and related to the particular Communication Service. By
            way of example, and not as a limitation, you agree that when using a
            Communication Service, you will not:
          </p>
          <ul>
            <li>
              Defame, abuse, harass, stalk, threaten or otherwise violate the
              legal rights (such as rights of privacy and publicity) of others.
            </li>
            <li>
              Publish, post, upload, distribute or disseminate any
              inappropriate, profane, defamatory, infringing, obscene, indecent
              or unlawful topic, name, material or information.
            </li>
            <li>
              Upload files that contain software or other material protected by
              intellectual property laws (or by rights of privacy of publicity)
              unless you own or control the rights thereto or have received all
              necessary consents.
            </li>
            <li>
              Upload files that contain viruses, corrupted files, or any other
              similar software or programs that may damage the operation of
              another’s computer.
            </li>
            <li>
              Advertise or offer to sell or buy any goods or services for any
              business purpose, unless such Communication Service specifically
              allows such messages.
            </li>
            <li>
              Conduct or forward surveys, contests, pyramid schemes or chain
              letters.
            </li>
            <li>
              Download any file posted by another user of a Communication
              Service that you know, or reasonably should know, cannot be
              legally distributed in such manner.
            </li>
            <li>
              Falsify or delete any author attributions, legal or other proper
              notices or proprietary designations or labels of the origin or
              source of software or other material contained in a file that is
              uploaded.
            </li>
            <li>
              Restrict or inhibit any other user from using and enjoying the
              Communication Services.
            </li>
            <li>
              Violate any code of conduct or other guidelines which may be
              applicable for any particular Communication Service.
            </li>
            <li>
              Harvest or otherwise collect information about others, including
              e-mail addresses, without their consent.
            </li>
            <li>Violate any applicable laws or regulations.</li>
          </ul>
          <p>
            Debt.com has no obligation to monitor the Communication Services.
            However, Debt.com reserves the right to review materials posted to a
            Communication Service and to remove any materials in its sole
            discretion. Debt.com reserves the right to terminate your access to
            any or all of the Communication Services at any time without notice
            for any reason whatsoever. Debt.com reserves the right at all times
            to disclose any information as necessary to satisfy any applicable
            law, regulation, legal process or governmental request, or refuse to
            post or to remove any information or materials, in whole or in part,
            is Debt.com’s sole discretion.
          </p>
          <p>
            Always use caution when giving out any personally identifying
            information (“PII”) about yourself, your spouse, or your children in
            any Communication Service. Debt.com does not control or endorse the
            content, messages or information found in any Communication Service
            and, therefore, Debt.com specifically disclaims any liability with
            regard to the Communication Services and any actions resulting from
            your participation in any Communication Service. Managers and hosts
            are not authorized Debt.com spokespersons, and their views do not
            necessarily reflect those of Debt.com. Materials uploaded to a
            Communication Service may be subject to posted limitations on usage,
            reproduction and/or dissemination. You are responsible for adhering
            to such limitations if you download the materials.
          </p>
          <h3>
            MATERIALS PROVIDED TO DEBT.COM OR POSTED AT ANY DEBT.COM WEB SITE
          </h3>
          <p>
            Debt.com does not claim ownership of the materials you provide to
            Debt.com (including feedback and suggestions) or post, upload, input
            or submit to any Debt.com Site or its associated services
            (collectively “Submissions”). However, by posting, uploading,
            inputting, providing or submitting your Submission you are granting
            Debt.com, its affiliated companies and necessary sub-licensees
            permission to use your Submission in connection with the operation
            of their Internet businesses including, without limitation, the
            rights to: copy, distribute, transmit, publicly display, publicly
            perform, reproduce, edit, translate and reformat your Submission;
            and to publish your name in connection with your Submission.
          </p>
          <p>
            No compensation will be paid with respect to the use of your
            Submission, as provided herein. Debt.com is under no obligation to
            post or use any Submission you may provide and may remove any
            Submission at any time at Debt.com’s sole discretion.
          </p>
          <p>
            By posting, uploading, inputting, providing or submitting your
            Submission you warrant and represent that you own or otherwise
            control all of the rights to your Submission as described in this
            section including, without limitation, all the rights necessary for
            you to provide, post, upload, input or submit the Submissions.
          </p>
          <h3>CONSENT TO USE OF ELECTRONIC COMMUNICATIONS</h3>
          <p>
            <b>
              All website interactions and phone calls are recorded for
              marketing, compliance and quality assurance purposes.
            </b>
          </p>
          <ol>
            <li>
              Mobile Devices{' '}
              <p>
                By providing us with your name, email, and/or phone number, you
                expressly authorize us to contact you (including using
                autodialers, automated text and pre-recorded messages) via your
                telephone, cellphone, mobile device (including via Short Message
                Service (“SMS”) or wireless internet (“WAP Service”)) and email,
                even if your telephone number is currently listed on any state,
                federal or company’s Do Not Call list. Standard phone and data
                charges will apply. Your consent to the above terms is not
                required as a condition of purchasing or receiving our services.
                You also consent to the recording and monitoring of all calls to
                and from us. You represent you are the owner or authorized user
                of the mobile device and the phone number associated with that
                mobile device on which messages will be received, and that you
                are authorized to approve any applicable charges. You may opt
                out of receiving calls and/or messages to your telephone or
                mobile device at any time by notifying us at{' '}
                <a href="mailto:admin@debt.com">
                  <b>admin@Debt.com</b>
                </a>{' '}
                and you may opt out of receiving emails by clicking the
                “Opt-out” link on the bottom of the email. <br /> Only United
                States residents may use the SMS or WAP Service.
              </p>
            </li>
            <li>
              Data Security{' '}
              <p>
                SMS or WAP Services are provided via wireless systems which use
                radio frequencies (and other means) to transmit communications
                over complex networks. Debt.com do not guarantee your use of the
                SMS or WAP Services will be private or secure, and Debt.com will
                not be liable to you for any lack of privacy or security you may
                experience. You are fully responsible for taking precautions and
                providing security measures appropriate to your situation and
                intended use of the SMS or WAP Service. You acknowledge and
                agree Debt.com may access the content of your account and the
                wireless account with your carrier for the purpose of
                identifying and resolving technical problems and service-related
                complaints. Please review our{' '}
                <Button
                  className={classes.buttonStyle}
                  onClick={() => {
                    privatePolicyTargetRef.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }}
                >
                  Privacy Policy
                </Button>{' '}
                for additional information governing privacy and our SMS or WAP
                services.
              </p>
            </li>
          </ol>
          <h3>LIMITATION OF LIABILITY AND WARRANTY DISCLAIMER</h3>
          <p>
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
            AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES, OLD
            INFORMATION, OR TYPOGRAPHICAL ERRORS. DEBT.COM MAKES PERIODICAL
            CHANGES TO THE INFORMATION AND MATERIALS PROVIDED ON THE SITE.
            DEBT.COM AND/OR ITS SITE ADMINISTRATOR MAY MAKE IMPROVEMENTS AND/OR
            CHANGES TO SITE AT ANY TIME.
          </p>
          <p>
            Opinions, general advice, statements or other comments on the site
            should not necessarily be relied upon and are not to be construed as
            professional advice from Debt.com or the Website Owner. information
            and general advice RECEIVED VIA THE SITE SHOULD NOT BE RELIED UPON
            FOR PERSONAL, MEDICAL, LEGAL OR FINANCIAL DECISIONS AND YOU SHOULD
            CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO
            YOUR SITUATION.
          </p>
          <p>
            DEBT.COM AND/OR ITS SITE ADMINISTRATOR MAKE NO REPRESENTATIONS ABOUT
            THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY
            OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
            GRAPHICS CONTAINED ON OR AVAILABLE THROUGH THE SITE FOR ANY PURPOSE.
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE
            PROVIDED “AS IS” WITHOUT WARRANTY OR CONDITION OF ANY KIND. DEBT.COM
            AND THE WEBSITE OWNER HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS
            WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
            NON-INFRINGEMENT.
          </p>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            DEBT.COM AND/OR ITS WEBSITE OWNER BE LIABLE FOR ANY DIRECT,
            INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR
            ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
            LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH THE USE OR PERFORMANCE OF THE DEBT.COM SITE, WITH THE DELAY OR
            INABILITY TO USE THE DEBT.COM SITE OR RELATED OR OFFERED SERVICES,
            THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
            OBTAINED THROUGH THE DEBT.COM SITE, OR OTHERWISE ARISING OUT OF THE
            USE OF THE DEBT.COM SITE, WHETHER BASED ON CONTRACT, TORT,
            NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF DEBT.COM OR ANY
            OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
            BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
            LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE
            ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH
            ANY PORTION OF THE DEBT.COM SITE, OR WITH ANY OF THESE TERMS OF USE,
            YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE DEBT.COM
            SITE.
          </p>
          <h3>TERMINATION/ACCESS RESTRICTION</h3>
          <p>
            Debt.com may establish areas on the Site which may require obtaining
            access credentials. Should you obtain such access to restricted
            areas on the Site, Debt.com reserves the right, in its sole
            discretion, to terminate your access to the Debt.com Site and the
            related services or any portion thereof at any time, without notice.
          </p>
          <h3>SITE NOT INTENDED FOR USE BY MINORS UNDER THE AGE OF 13</h3>
          <p>
            The Site is not directed to individuals under the age of thirteen
            (13), and we request that these individuals do not provide
            Personally Identifiable Information (PII) through the Site. We do
            not knowingly collect information from children under 13 years of
            age without parental consent. For more information regarding this
            policy please visit our Privacy Policy.
          </p>
          <h3>COPYRIGHT AND TRADEMARK NOTICES:</h3>
          <p>
            Debt.com, and the Debt.com logo are registered trademarks, trade
            names or service marks of Debt.com, LLC or its related companies.
            All other trademarks and service marks presented on the Site are the
            property of their respective owners, and Debt.com has obtained
            permission to use them in association with the educational
            information and referral services provided. You are not permitted to
            use any trademark or service mark displayed on this Site without the
            prior written consent of Debt.com or the owner of such trademark or
            service mark.
          </p>
          <p>
            You acknowledge and understand that you have no rights to any
            trademarks, service marks, copyrights. Debt.com reserves all rights
            to its Intellectual Property on the Site.
          </p>
          <h3>COPYRIGHT INFRINGEMENT POLICY</h3>
          <p>
            In accordance with the requirements set forth in the Digital
            Millennium Copyright Act, Title 17 United States Code Section
            512©(2)(“DMCA”), Debt.com will investigate notices of copyright
            infringement and take appropriate remedial action. If you believe
            that any Content on the Site has been used or copied in a manner
            that infringes your work, please provide a written notification of
            claimed copyright infringement to the Designated Agent for the Site
            containing the following elements as set forth in the DMCA:
          </p>
          <ul>
            <li>
              a physical or electronic signature of the owner of the copyright
              interest that is alleged to have been infringed or the person
              authorized to act on behalf of the owner;
            </li>
            <li>
              identification of the copyrighted work(s) claimed to have been
              infringed, including copyright date;
            </li>
            <li>
              identification of the Content you claim to be infringing and which
              you request be removed from the Site or access to which is to be
              disabled along with a description of where the infringing Content
              is located;
            </li>
            <li>
              information reasonably sufficient to allow us to contact you, such
              as a physical address, telephone number and an email address;
            </li>
            <li>
              a statement by you that you have a good faith belief that the use
              of the Content identified in your written notification in the
              manner complained of is not authorized by you or the copyright
              owner, its agent or the law; and
            </li>
            <li>
              a statement by you that the information in your written
              notification is accurate and that, under penalty of perjury, you
              are the copyright owner or authorized to act on behalf of the
              copyright owner.
            </li>
          </ul>
          <p>
            Debt.com’s designated agent for the written notification of claims
            of copyright infringement can be contacted at the following address:
          </p>
          <p>
            Designated Agent – Copyright Infringement Claims <br /> Debt.com LLC
            <br /> 8220 W. State Road 84, <br /> Fort Lauderdale, FL 33324{' '}
            <br /> Email:{' '}
            <a href="mailto:admin@debt.com">
              <strong>admin@Debt.com</strong>
            </a>
          </p>
          <h3>YOUR INDEMNIFICATION REQUIREMENTS</h3>
          <p>
            You agree to defend, indemnify and hold us and our officers,
            directors, shareholders, employees, independent contractors, agents,
            representatives and affiliates harmless from any and against all
            claims and expenses, including, but not limited to, attorneys’ fees,
            arising out of, or related to: (i) any breach or violation of this
            Agreement by you; (ii) your failure to provide accurate, complete
            and current personally identifiable information (“PII”) in response
            to any request form on our Site; (iii) your access or use of
            Services through our Site; (iv) access to our Site or use of our
            Services under any password that may be issued to you; (v) your
            transmissions, submissions or postings (i.e., your own User
            Generated Content); (vi) the Services you receive from any of the
            Service Providers to which you were referred by us; and/or (vii) any
            personal injury or property damage caused by you.
          </p>
          <h3>GENERAL / MISCELLANEOUS TERMS</h3>
          <p>
            <b>Governing Law, Jurisdiction, Venue</b>. To the maximum extent
            permitted by law, this Agreement is governed by the laws of the
            State of Florida, U.S.A., without regards to any choice of law
            provisions. Should you properly opt out of Arbitration, you hereby
            consent to the exclusive jurisdiction and venue of courts in Broward
            County, Florida, U.S.A. in all disputes arising out of or relating
            to the use of the Debt.com Web Site. If you did not opt out of
            Arbitration, please see the Arbitration provision below for the
            applicable law and jurisdiction governing your arbitration. You and
            we agree to submit to the personal jurisdiction of the courts
            located within Broward County, Florida for the purpose of litigating
            all such claims or disputes.
          </p>
          <p>
            <b>Relationship of the Parties. </b>You agree that no joint venture,
            partnership, employment, or agency relationship exists between you
            and Debt.com as a result of this Agreement or use of the Debt.com
            Site.
          </p>
          <p>
            <b>Applicability of Laws. </b>Debt.com’s performance of this
            agreement is subject to existing laws and legal process, and nothing
            contained in this agreement is in derogation of Debt.com’s right to
            comply with governmental, court and law enforcement requests or
            requirements relating to your use of the Debt.com Site or any
            information provided to or gathered by Debt.com with respect to such
            use. Use of the Debt.com Site is unauthorized in any jurisdiction
            that does not give effect to all provisions of these terms and
            conditions, including without limitation this paragraph.
          </p>
          <p>
            <b>Severability. </b>If any part of this Agreement is determined to
            be invalid or unenforceable pursuant to applicable law including,
            but not limited to, the warranty disclaimers and liability
            limitations set forth above, then the invalid or unenforceable
            provision will be deemed superseded by a valid, enforceable
            provision that most closely matches the intent of the original
            provision and the remainder of the agreement shall continue in
            effect.
          </p>
          <p>
            <b>Entire Agreement. </b>Unless otherwise specified herein, this
            Agreement, along with our Privacy Policy, any Notices and
            Disclosures on our Site, which are incorporated herein by reference,
            together constitute the entire agreement between the You and
            Debt.com with respect to the Site and it supersedes all prior or
            contemporaneous communications and proposals, whether electronic,
            oral or written, between the user and Debt.com with respect to the
            Debt.com Web Site. A printed version of this Agreement and of any
            notice given in electronic form shall be admissible in judicial or
            administrative proceedings based upon or relating to this Agreement
            to the same extent and subject to the same conditions as other
            business documents and records originally generated and maintained
            in printed form. It is the express wish to the parties that this
            Agreement and all related documents be drawn up in English despite
            having these terms in translated to other languages.
          </p>
          <h3>AMENDMENTS TO THIS AGREEMENT</h3>
          <p>
            We reserve the right to update, amend and/or change this Agreement
            at any time in our sole discretion and without notice. Updates to
            this Agreement will be posted here. Amendments will take effect
            immediately upon us posting the updated Agreement on our Services.
            You are encouraged to revisit this Agreement from time to time in
            order to review any changes that have been made. The date on which
            this Agreement was last updated will be noted immediately above this
            Agreement. Your continued access and use of our Services following
            the posting of any such changes shall automatically be deemed your
            acceptance of all changes.
          </p>
          <p id="dispute-resolution" ref={disputeResolutionRef}>
            <b>
              <u>
                Dispute Resolution by Binding Arbitration – IMPORTANT NOTICE
                WAIVING YOUR RIGHT TO BRING A CLAIM BEFORE A JUDGE OR JURY IN
                COURT.
              </u>
            </b>
          </p>
          <p>
            In the event of any controversy between you and Debt.com, including
            but not limited to any demand, claim, suit cross claim,
            counterclaim, or third-party complaint or dispute, whether
            contractual, statutory, in tort or otherwise (including but not
            limited to our designated servicers and any fulfillment provider
            working on your behalf) arising out of, or in any way relating, to
            our relationship, including but not limited to this Agreement, our
            Privacy Policy, Site Disclaimers, use of the Debt.com Site, the
            information on Debt.com’s Site, any referral services provided by
            Debt.com, or the breach, termination, enforcement, interpretation,
            or validity of the Agreement or its performance, including any
            determination of the scope, enforcement or applicability of this
            provision to arbitrate, shall be resolved by binding arbitration in
            Broward County, Florida, or the nearest metropolitan area to the
            county in which you reside, or at such other location upon which the
            parties may agree upon. This provision to arbitrate is governed by
            the Federal Arbitration Act (“FAA”), 9 U.S.C. §1 et sec., which
            shall govern the interpretation and enforcement of our mutual
            agreement to arbitrate, and not by any state rule or statute
            governing arbitration and without respect to any state arbitration
            law. This agreement to arbitrate shall survive termination of the
            Agreement. The Parties agree to the following Arbitration Terms:
          </p>
          <ol>
            <li>
              The Arbitration shall be administered by the American Arbitration
              Association (“AAA”) (
              <a
                href="http://www.adr.org/"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                www.adr.org
              </a>
              ), the Judicial Arbitration Mediation Services (“JAMS”), or
              another nationally known consumer arbitration service agreed by
              the parties in the event AAA and JAMS no longer conduct consumer
              arbitrations. The Party who intends to seek arbitration must first
              send to the other Party a written Notice of Dispute before filing
              it with the arbitration service provider. The Notice must describe
              both the nature and basis of the dispute; and the specific relief
              sought. Any notice shall be deemed to have been given and received
              for all purposes when delivered by hand, a day after receipt
              through a nationally recognized overnight courier, or a day after
              being received through certified or registered mail, postage and
              charges prepaid, return receipt requested, to us at: Debt.com,
              LLC, Attn: Legal Department, 8220 W. State Road 84, Fort
              Lauderdale, FL 33324.
            </li>
            <li>
              <b>Commencement and Rules of Arbitration. </b>If we do not resolve
              a claim within 30 days after receipt of the Notice, the party
              sending the Notice may commence an arbitration proceeding with the
              AAA, JAMS, or another national arbitration provision if AAA or
              JAMS are unavailable, by filing a Demand of Arbitration in effect
              at the time the action is commenced. If you choose to file with
              the AAA, you may download or copy the form to initiate arbitration
              from the following website: http://www.adr.org/consumer. The AAA
              shall provide the parties a list of 5 arbitrators in which the
              parties may agree on an arbitrator. If the parties cannot mutually
              agree on an arbitrator, the parties shall rank the arbitrators and
              the AAA shall appoint a single arbitrator who shall administer the
              arbitration proceeding pursuant to its Commercial Dispute
              Resolution Procedures and the Supplementary Procedures for
              Consumer-Related Disputes (“AAA Rules”), with the exception of
              Mass or Class Arbitration rules. The AAA Rules are available at
              www.adr.org or by calling the AAA at 1-800-778-7879. If you choose
              to file with JAMS, you may download or copy the form to initiate
              arbitration from the following website:&nbsp;
              <a
                href="https://www.jamsadr.com/adr-forms"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                https://www.jamsadr.com/adr-forms
              </a>
              . JAMS shall provide the parties a list of 5 arbitrators in which
              the parties may agree on an arbitrator. If the parties cannot
              mutually agree on an arbitrator, the parties shall rank the
              arbitrators and the JAMS shall appoint a single arbitrator who
              shall administer the arbitration proceeding pursuant to its
              Commercial Dispute Resolution Procedures and the Consumer
              Arbitration Minimum Standards (“JAMS Rules”), with the exception
              of Mass or Class Arbitration rules. In the event another
              arbitration service is provided, the parties agree to follow the
              rules and principles of that arbitration service, except as to any
              rights to Mass or Class Arbitration rules which the Parties
              expressly agree will not apply.
            </li>
            <li>
              <b>The Arbitrator. </b>The selected arbitrator shall be either a
              retired judge or an experienced attorney, shall be neutral and
              independent, and shall comply with the selected arbitration
              service’s code of ethics. The Arbitrator shall conduct the
              arbitration in a civil manner and ensure civility of the parties
              while making their arguments. Additionally, in conducting the
              arbitration hearing, in addition to the selected arbitration
              service rules, the arbitrator shall be guided by the Federal Rules
              of Civil Procedure, the Federal Rules of Evidence, and Florida
              substantive law.
            </li>
            <li>
              <b>The Arbitration Award. </b>The Arbitrator shall issue a written
              reasoned award in which the arbitrator shall address the claims,
              defenses, evidence presented, and legal reasoning in making his
              decision. If the Arbitrator determines that reasonable attorney
              fees and costs may be awarded under applicable law, the parties
              agree that the arbitrator will also determine the amount of
              reasonable attorneys’ fees to be awarded. Any award rendered by
              the arbitrator shall be final and binding and shall not be subject
              to vacation or modification, except as expressly permitted by the
              Federal Arbitration Act. Judgment on the Arbitration Award may be
              entered in the jurisdiction in which the arbitration was commenced
              or in any court having jurisdiction over the Party against whom
              judgment is to be entered.
            </li>
            <li>
              <b>
                Waiver of Right to Bring Mass or Class Actions. The parties
                agree that either party may bring claims against the other only
                in his/her or its individual capacity and not as a plaintiff or
                class member in any purported mass, class, or other
                representative proceeding. Further, the parties agree that the
                arbitrator may not consolidate proceedings of more than one
                person’s claims and may not otherwise preside over any form of
                representative or class proceeding.
              </b>
            </li>
            <li>
              <b>Limitation on Injunctive Relief: </b>The arbitrator may award
              injunctive relief only in favor of an individual party seeking
              relief and only to the extent necessary to provide relief
              warranted by that party’s individual claim.
            </li>
            <li>
              <b>Costs of Arbitration: </b>Payment of all filing, administration
              and arbitrator fees will be governed by the selected arbitration
              service rules. Each party shall bear its own attorneys’ fees, if
              any, unless such fees are expressly provided for by applicable
              law. In the event that a party fails to proceed with Arbitration,
              unsuccessfully challenges the Arbitrator’s award, or fails to
              comply with the Arbitrator’s award, the other party shall be
              entitled to costs of suit, including reasonable attorneys’ fees
              for having to compel Arbitration or defend or enforce the award.
            </li>
            <li>
              <b>Arbitration Opt-Out. </b>YOU MAY OPT-OUT OF THE TERMS OF THIS
              ARBITRATION PROVISION BY PROVIDING DEBT.COM WITH NOTICE IN WRITING
              WITHIN THIRTY (30) DAYS OF FIRST USING OUR SITE. If you do so,
              neither you nor we can require the other to participate in an
              arbitration proceeding. To opt out, you must notify us in writing
              either by U.S. mail delivered to: Attn: Legal Department,
              Debt.com, LLC, 8220 W. State Road 84, Fort Lauderdale, FL 33324 or
              by email delivered to&nbsp;admin@debt.com. You must include: (1)
              your name and residence address; (2) the email address and/or
              mobile telephone number associated with your account; and (3) a
              clear statement that you want to opt out of this agreement to
              arbitrate.
            </li>
            <li>
              <b>
                What does arbitration mean? By agreeing to arbitration, we both
                acknowledge and agree that we are each: (i) waiving the right to
                pursue any other available dispute resolution processes,
                including, but not limited to, a court action or administrative
                proceeding, (ii) waving the right to participate in any class or
                mass action, (iii) waving the right to a trial by either a judge
                or jury, and (iv) giving up any right to appeal the arbitrator’s
                decision, except for a limited range of appealable issues
                expressly provided in the FAA. This section is considered by the
                parties to be an integral and non-voidable requirement and part
                of this Agreement, and the parties agree that prior to signing
                this Agreement including agreeing to this section they: (1) have
                had the opportunity to review such carefully, including with
                their own legal counsel if needed, (2) the parties understand
                the limitations on legal rights contained in this section
                including, but not limited to, the waiver of jury trial, the
                waiver of court proceeding and class actions, and (3) the
                parties consent and agree that this section is fair, reasonable,
                represents the intentions of each party at the time of entry
                into this Agreement, and should not be set aside later for any
                reason by any court. This section and the arbitration
                requirement shall survive termination.
              </b>
            </li>
          </ol>
          <p>
            Notwithstanding any provision in the Agreement to the contrary, you
            and we agree that if we make a change to this agreement to arbitrate
            in the future, that change shall not apply to a claim that was filed
            in a legal proceeding between you and us prior to the effective date
            of the change.
          </p>
          <h2 ref={privatePolicyTargetRef}>Privacy Policy</h2>
          <p>
            <strong>Last updated: April 19th, 2024.</strong>
          </p>
          <p>
            Debt.com, LLC (“Debt.com”) is the owner of this web site. This
            Online Privacy Policy (hereinafter sometimes referred to as the
            “Notice”) applies to this&nbsp;<strong>Debt.com</strong>&nbsp;online
            interface (i.e., website or mobile application) (the “Site”). The
            term&nbsp;
            <strong>Debt.com</strong>&nbsp;or “we” or “us” or “our” in this
            Notice refers to Debt.com, and any of Debt.com’s affiliates or
            subsidiaries that may adopt this Notice. This Notice describes how
            this Site, or any Debt.com landing pages linked to this Site
            (Debt.com’s landing pages and this Site shall collectively refer to
            as “Sites”), may collect, use and share information from or about
            you, and explains how information may be collected and used for
            advertising purposes. Debt.com may provide other online interfaces
            not covered by this Notice. If you visit or access your services
            from one of these sites, please review the online privacy practices
            of that site to understand how your online information may be
            collected, used and shared.
          </p>
          <p>
            <strong>Agreement to this Policy:</strong>&nbsp;By using,
            interacting with, entering information, or viewing information on
            this Site, you expressly acknowledge and consent to this Online
            Privacy Policy, including your consent to our use and disclosure of
            information collected from, or about, you in the manner described
            herein. You agree that Debt.com can rely on your use and interaction
            with this Site as your express consent to the terms of this Online
            Privacy Notice.
          </p>
          <h3>Collecting and Using Information</h3>
          <p>
            <em>Registration and Other Information Provided to Us</em>
          </p>
          <p>
            When you visit and/or subscribe to&nbsp;<strong>Debt.com</strong>,
            you may be asked to disclose Personally Identifiable Information
            (also known as “PII”). You may also choose to submit PII to us when
            you send us an email. You acknowledge and agree that we may use and
            store any such PII about you in accordance with our Privacy Policy.
            We may also process and store your PII on a server located outside
            of your jurisdiction. By providing your PII to us, you consent to
            the transfer of your information across national boundaries. If you
            do not consent to such transfer as well as the processing and
            storage of your personally identifiable information outside your
            jurisdiction, you should not provide such PII to us and end your
            interaction with our Site. Debt.com will not process or transfer PII
            or other information across international boundaries, and does not
            conduct any data processing or transfer with European entities.
          </p>
          <p>
            <em>Personal Information We Collect Online</em>
          </p>
          <p>
            Personal Information means information you provide via forms,
            surveys, applications or other online fields including but not
            limited to the following: any information constituting PII, your
            name, postal or email addresses, telephone, fax or mobile numbers,
            or account numbers.
          </p>
          <h3>How We Use Personal Information</h3>
          <p>We may use Personal Information, including PII, as follow:</p>
          <ul>
            <li>to respond to your inquiries and fulfill your requests;</li>
            <li>
              to inform you about important information regarding the Site,
              products or services for which you may apply or in which you are
              enrolled, changes to terms, conditions, and policies and/or other
              administrative information;
            </li>
            <li>
              to deliver marketing communications that we believe may be of
              interest to you, including, ads or offers tailored to you,
              including ads on other websites, or from affiliated or
              unaffiliated third parties;
            </li>
            <li>to personalize your experience on the Site;</li>
            <li>
              to allow you to apply for various products or services and
              evaluate your eligibility for such products or services;
            </li>
            <li>
              to verify your identity and/or location (or the identity or
              location of your representative or agent) in order to allow access
              to services you may enroll in, conduct online transactions and to
              maintain measures aimed at preventing fraud and protecting the
              security of your Personal Information;
            </li>
            <li>
              to allow you to participate in surveys and other forms of market
              research, sweepstakes, contests and similar promotions, and to
              administer these activities. These activities may have additional
              rules of participation, which may also contain additional policies
              about how the Personal Information provided is used and/or shared;
            </li>
            <li>
              to allow us to verify your identity or other information collected
              from or through our social media pages or campaigns and other
              interaction with online forms generated on our behalf. We may
              combine and use PII you provide in this Site with the information
              collected from our social media pages or from your interaction
              with online forms generated on our behalf.
            </li>
            <li>
              to allow you to enter and participate in our live chat, forums,
              and/or other Bulletin Board that we may have on the site from time
              to time.
            </li>
            <li>
              for business purposes, including data and marketing analysis,
              audits, developing and improving products and services, enhancing
              the Site, identifying usage trends and determining the
              effectiveness of promotional campaigns;
            </li>
            <li>
              for risk control, fraud detection and prevention, to comply with
              laws and regulations, and to comply with other legal process and
              law enforcement requirements;
            </li>
            <li>
              to allow you to utilize features within our Site by granting us
              access to information from your device such as contact lists, or
              geo-location when you request certain services.
            </li>
          </ul>
          <h4>How Personal Information is Shared</h4>
          <p>
            When you submit Personal Information and/or PII to us (which may
            include among other information your first name, last name, email
            address, zip code, contact phone number, other information provided
            by you such as your date of birth or household income), you
            acknowledge and agree that we may share this information with third
            party service providers offering various products and/or services in
            which you were interested in or have requested additional
            information about. You also acknowledge and agree that the Personal
            Information provided may also be used for cross-marketing purposes
            with third party affiliates and to provide you with offers that may
            be of interest or benefit to you based upon your initial request.
            Our affiliates and/or third-party companies may use your email
            address for their own marketing use.
          </p>
          <p>
            We may also share information that is not considered PII collected
            from you, consisting of general information about our user base with
            our advertising affiliates who drive traffic to our website to help
            them identify and target our ideal customer. We may also share your
            contact information with third parties who may contact you for a
            free, no-obligation consultation about services that may be of
            interest to you based on your interaction with our Site. For your
            reference, a non-exclusive list of third party companies with whom
            we may share your information is&nbsp;
            <a
              href="https://www.debt.com/privacy-policy/partners/"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
          <h4>Right to Opt-Out of Sharing</h4>
          <p>
            If you prefer that we not share your PII or additionally collected
            information with third parties for direct marketing purposes, you
            may opt out of such sharing by emailing us your request at&nbsp;
            <a href="mailto:admin@Debt.com">admin@Debt.com</a>
          </p>
          <h4>Other Information We Collect Online</h4>
          <p>
            Other Information is any information, other than Personal
            Information or PII, which does not reveal your specific identity or
            does not directly relate to an individual, such as browser
            information, information collected through cookies, pixel tags and
            other technologies, demographic information, and aggregated and
            de-identified data. In the event that information collected through
            cookies, pixel tags and other technologies, demographic information
            is determined to contain PII, you understand and agree that Debt.com
            may use and transfer such PII in accordance with the terms governing
            PII in this Notice.
          </p>
          <p>How Do We Collect and Use Other Information</p>
          <p>
            We and any of our affiliated third-party service providers may
            collect and use Other Information in a variety of ways, including
            but not limited to the following:
          </p>
          <ul>
            <li>
              <strong>Through your browser or device:</strong>&nbsp;Certain
              information is collected by most browsers and/or through your
              device, such as your Media Access Control (MAC) address, device
              type, screen resolution, operating system version and internet
              browser type and version. While such information is often regarded
              as Other Information, you understand that it may be treated as PII
              and thus, regardless of its nature, it may be used under the PII
              provisions of this Notice. We use this information to ensure Sites
              function properly, for fraud detection and prevention, and
              security purposes.
            </li>
            <li>
              <strong>Using cookies:</strong>&nbsp;Cookies are pieces of
              information stored directly on the device that you are using to
              access our Site. Cookies we use do not contain or capture
              unencrypted Personal Information. Cookies allow us to collect
              information such as browser type, time spent on the Site, pages
              visited, language preferences, and general interaction with our
              Site. We may use the information collected from cookies for
              various purposes including, but not limited to, the following: for
              security purposes, to facilitate navigation, to display
              information more effectively, to personalize/tailor your
              experience while interacting with our Site or engaging with us,
              and to recognize your device in the future for ease of use of our
              online products and services. We, further, collect statistical
              information about the usage of the Site in order to continually
              improve the design and functionality, to monitor responses to our
              advertisements and content, to understand how visitors use the
              Site and to assist us in resolving issues with the Site. We also
              utilize cookies for advertising purposes. Please see the
              Advertising section below for more information. You may refuse to
              accept these cookies and most devices and browsers offer their own
              privacy settings for cookies. You will need to manage your cookie
              settings for each device and browser you use. However, please be
              advised that should you choose not accept these cookies, the Site
              and some online products and services may not work properly as you
              proceed to interact with our Site.
            </li>
            <li>
              <strong>
                Other technologies including pixel tags, web beacons, and clear
                GIFs:
              </strong>
              &nbsp;These may be used in connection with some Site pages,
              downloadable mobile applications, and HTML-formatted email
              messages to measure the effectiveness of our communications, the
              success of our marketing campaigns, to compile statistics about
              usage and response rates, for fraud detection and prevention, for
              security purposes, and for advertising. You understand and agree
              that we may use third party pixel tags, web beacons and/or clear
              GIFs that may give access to third parties to you information,
              including your IP address and browsing activity on pages of our
              Sites where these devices are placed. You understand that we do
              not control any information gathered by such devices or its use by
              such third parties. Please see our Advertising section below for
              more information regarding our use of these and other
              technologies.
            </li>
            <li>
              <strong>Google Analytics:</strong>&nbsp;We use a tool called
              “Google Analytics” to collect information about use of this Site.
              Google Analytics collects information such as how often users
              visit this Site, what pages they visit when they do so, and what
              other Sites they used prior to coming to this Site. We use the
              information we get from Google Analytics only to improve this
              Site. Google Analytics collects only the IP address assigned to
              you on the date you visit this site, rather than your name or
              other identifying information. If the Google Analytics is
              determined to be PII, we will maintain and use the information
              gathered from Google Analytics pursuant to the terms governing PII
              in this Notice. We do not combine the information collected
              through the use of Google Analytics with other PII that you may
              provide on our Site. Although Google Analytics plants a permanent
              cookie on your web browser to identify you as a unique user the
              next time you visit this Site, the cookie cannot be used by anyone
              but Google. Google’s ability to use and share information
              collected by Google Analytics about your visits to this Site is
              restricted by the&nbsp;
              <a
                href="https://www.google.com/analytics/terms/"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                Google Analytics Terms of Use
              </a>
              &nbsp;and the&nbsp;
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                Google Privacy Policy
              </a>
              .&nbsp;You may prevent Google Analytics from recognizing you on
              return visits to this site by&nbsp;
              <a
                href="https://www.usa.gov/optout_instructions.shtml"
                target="_blank"
                rel="noreferrer noopener"
              >
                disabling cookies
              </a>
              &nbsp;on your browser.
            </li>
            <li>
              <strong>IP Address:</strong>&nbsp;Your IP Address is a number that
              is automatically assigned to the device that you are using by your
              Internet Service Provider (ISP). Your IP may be dynamic or static.
              An IP Address is identified and logged automatically in our server
              log files whenever a user visits the Site, along with the time of
              the visit and the page(s) that were visited. Collecting IP
              Addresses is standard practice on the internet and is done
              automatically by many web sites. We will treat your IP as PII if
              it is used to identify you for any purpose in accordance with this
              Notice. Furthermore, we may use IP Addresses for purposes such as
              calculating Site usage levels, helping diagnose server problems,
              advertising, for compliance and security purposes and
              administering the Site. Please see the Advertising section below
              for more information.
            </li>
            <li>
              <strong>Aggregated and De-identified Data:</strong>
              &nbsp;Aggregated and De-identified Data is data that we may create
              or compile from various sources, including but not limited to
              accounts and transactions. This information, which does not
              identify individual PII, may be used for our business purposes,
              which may include offering products or services, research,
              marketing or analyzing market trends, and other purposes
              consistent with applicable laws.
            </li>
          </ul>
          <h4>Additional Information</h4>
          <p>
            <em>Third party widgets</em>&nbsp;– We may allow use of certain
            widgets (e.g., social share buttons) on our Sites that enable users
            to easily share information on another platform, such as the user’s
            social media platform. You understand and agree that the third
            parties’ widgets may give access, and the third parties may be
            granted access, to certain information about your browsing on pages
            of our Sites where these widgets are placed. You understand that we
            do not have control on the information to which the third parties
            receive access and you may wish to review the privacy information at
            the third party site to determine how these third parties collect
            and treat such information.
          </p>
          <p>
            <em>Retargeting</em>&nbsp;– As you browse&nbsp;
            <a href="https://www.debt.com/" target="_blank" rel="noreferrer">
              https://www.debt.com
            </a>
            , advertising cookies will be placed on your computer so that we can
            understand what you are interested in. Our display advertising
            partners, enable us to present you with retargeting advertising on
            other sites based on your previous interaction with
            https://www.debt.com and affiliated pages and sites.
          </p>
          <p>
            <em>Google Interest Based Advertising Program</em>&nbsp;– In order
            to enhance your experience when you visit our Site, we participate
            in the Google Interest-based Advertising Program (“Program”) to
            ensure the advertisements on this Site correspond to your interests.
            We do this by working with Google and other Program participants
            who, like us, place ads on our Site and those of other Program
            Participants. We then share the information we collect using Web
            Cookies placed in your browser in order to provide you with targeted
            advertising when you visit the Site. You may opt out of Google’s use
            of cookies by visiting the&nbsp;
            <a
              href="https://policies.google.com/technologies/ads"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              https://policies.google.com/technologies/ads
            </a>
            &nbsp;Google Advertising Opt-Out Page.
          </p>
          <p>
            <em>Third-Party Web Beacons</em>&nbsp;– We use third-party web
            beacons from Yahoo! to help analyze where visitors go and what they
            do while visiting our website. Yahoo! may also use anonymous
            information about your visits to this and other websites in order to
            improve its products and services and provide advertisements about
            goods and services of interest to you. If you would like more
            information about this practice and to know your choices about not
            having this information used by Yahoo! click here or visit&nbsp;
            <a
              href="https://yahoo.mydashboard.oath.com"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              https://yahoo.mydashboard.oath.com/device
            </a>
            .
          </p>
          <p>
            <em>The Facebook Companies</em>&nbsp;– Facebook owns and operates
            each of the companies listed below, in accordance with their
            respective terms of service and privacy policies. We may share
            information about you within the family of companies to assist in
            advertising our services. For more information on the Facebook
            Companies’ privacy practices and how they treat individuals’
            information, PII or additionally collected information, please visit
            the following links:
          </p>
          <ul>
            <li>
              Facebook Payments Inc.
              <br />
              <div>
                <a
                  href="https://www.facebook.com/payments_terms/privacy"
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  https://www.facebook.com/payments_terms/privacy
                </a>
              </div>
            </li>
            <li>
              Facebook
              <br />
              <a
                href="https://developers.facebook.com/policy"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                (https://developers.facebook.com/policy)
              </a>
            </li>
            <li>
              Facebook
              <br />
              <a
                href="https://www.facebook.com/about/privacy/"
                target="_blank"
                rel="noreferrer noopener"
              >
                (https://www.facebook.com/about/privacy/)
              </a>
            </li>
            <li>
              Facebook
              <br />
              <a
                href="https://www.facebook.com/legal/terms"
                target="_blank"
                rel="noreferrer noopener"
              >
                (https://www.facebook.com/legal/terms)
              </a>
            </li>
            <li>
              Instagram LLC TOS
              <br />
              <a
                href="https://www.instagram.com/about/legal/terms/"
                target="_blank"
                rel="noreferrer noopener"
              >
                (https://www.instagram.com/about/legal/terms/)
              </a>
            </li>
            <li>
              Atlas
              <br />
              <a
                href="https://www.atlashxm.com/privacy-notice"
                target="_blank"
                rel="noreferrer"
              >
                (https://www.atlashxm.com/privacy-notice)
              </a>
            </li>
            <li>
              Instagram LLC
              <br />
              <a
                href="https://instagram.com/about/legal/privacy/"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                (http://instagram.com/about/legal/privacy/)
              </a>
            </li>
            <li>
              Parse
              <br />
              <a
                href="https://opencollective.com/privacypolicy"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                (https://opencollective.com/privacypolicy)
              </a>
            </li>
            <li>
              Oculus
              <br />
              <a
                href="https://www.oculus.com/legal/privacy-policy"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                (https://www.oculus.com/legal/privacy-policy/)
              </a>
            </li>
            <li>
              WhatsApp Inc.
              <br />
              <a
                href="https://www.whatsapp.com/legal/"
                target="_blank"
                rel="noreferrer noopener"
              >
                (https://www.whatsapp.com/legal/)
              </a>
            </li>
          </ul>
          <p>Opt-Out Policy for Newsletter and Email Subscriptions</p>
          <p>
            You may opt-out or unsubscribe from a newsletter or other email list
            at any time by following the instructions at the end of the
            newsletters or emails which you receive. Please allow five to ten
            (5-10) business days for changes to take effect. You understand and
            agree that during that period and while your request is pending you
            may still receive newsletters and emails. Client service-related
            communications are an integral part of the services you receive from
            us, and you may continue to receive such service-related emails
            unless you cancel your account, even if you opt out of the
            newsletters or email list. If you have provided more than one email
            address to us, you may continue to be contacted unless you request
            to unsubscribe each email address you have provided. You may also
            opt-out of receiving our newsletter or marketing emails by
            contacting us at&nbsp;
            <a href="mailto:unsubscribe@Debt.com">
              <strong>unsubscribe@Debt.com</strong>
            </a>
            &nbsp;or by replying to an existing email with your request to be
            removed from the mailing list.
          </p>
          <p>Short Message Service</p>
          <p>
            We may make available a service through which you can receive
            communications your wireless device via short message service (“SMS
            Service”). Data obtained from you in connection with this SMS
            service may include your name, address, cell phone number, your
            provider’s name, and the date, time, and content of your messages.
            By providing your contact information, you affirmatively represent
            that you are 18 years of age and the owner or authorized user of the
            wireless device on which messages will be received, and that you
            understand that such communication may be subject to certain
            applicable charges, and that you approve all such applicable
            charges. In addition to any fee of which you are notified, your
            provider’s standard message and data rates may apply to our SMS
            confirmation and all subsequent SMS correspondence. All charges for
            data rates are billed by, and payable to, your mobile service
            provider. We will not be liable for any delays in the receipt of any
            SMS messages, as delivery is subject to effective transmission from
            your network operator. We will not be liable if SMS are received
            after hours due to such transmission delays. SMS message services
            are provided on an AS IS basis. We may also obtain the date, time,
            and content of your messages in the course of your use of the SMS
            Service. We will use the information we obtain in connection with
            our SMS Service in accordance with this Privacy Policy. We may
            provide your carrier with your applicable information in connection
            with your consent to receive SMS. Your wireless carrier and other
            service providers may also collect data about your wireless device
            usage, and their practices are governed by their own policies. We
            will not be liable for any data use or misuse by your wireless
            carrier.
          </p>
          <p>
            You acknowledge and agree that the SMS Service is provided via
            wireless systems which use radios (and other means) to transmit
            communications over complex networks. You must have a two-way
            text-enabled phone with compatible carrier and plan. Compatible
            major carriers include Alltel Wireless, AT&amp;T, Boost Mobile,
            Nextel, Sprint, T-Mobile, Verizon Wireless and Virgin Mobile USA. We
            do not guarantee that your use of the SMS Service will be private or
            secure, and we are not liable to you for any lack of privacy or
            security you may experience. You are fully responsible for taking
            precautions and providing security measures best suited for your
            situation and intended use of the SMS Service.
          </p>
          <p>SMS and Phone Call Opt-In</p>
          <p>
            By providing your phone number and/or joining our SMS Service, you
            expressly authorize us to contact you (including using autodialers,
            automated text and pre-recorded messages) via your telephone,
            cellphone, mobile device (including SMS and MMS) and email, even if
            your telephone number is currently listed on any state, federal or
            company’s Do Not Call list. Standard phone and data charges will
            apply. Your consent to the above terms is not required as a
            condition of purchasing or receiving our services. You also consent
            to the recording and monitoring of all calls to and from us. You may
            choose to opt-out and remove your authorization to receive such
            calls and/or text messages by emailing us your instructions at&nbsp;
            <a href="mailto:admin@Debt.com">admin@Debt.com</a>. You may also
            opt-out and remove your authorization to receive SMS text messages
            by replying or sending “STOP”, “END”, “QUIT”, “UNSUBSCRIBE”, or
            “CANCEL” to the Company.
          </p>
          <p>
            <strong>Security</strong> At Debt.com, the security of your personal
            information is extremely important to us. We take commercially
            reasonable precautions to protect your personal information and only
            provide employees access to it on a need-to-know basis. In an effort
            to prevent the unauthorized access, disclosure, dissemination,
            misuse, loss, alteration or destruction of your personal
            information, we have employed commercially reasonable physical,
            electronic, and managerial measures to protect your data and we
            periodically review these policies and procedures to prevent these
            types of acts. However, despite our best efforts, no security
            policy, procedures or practices are adequate to protect against all
            types of threats or data losses and no type of data transmission
            method is impenetrable to interception.
          </p>
          <p>
            <strong>Advertising</strong>
            <br />
            <strong>Debt.com</strong>&nbsp;advertises online through various
            methods (e.g., pages within our Sites and mobile apps through
            company managed social media presences, and/or through other sites
            and mobile apps not affiliated with Debt.com) and offline through
            various methods (e.g. through call centers, and direct marketing).
            In order to understand how advertising performs, we may collect
            certain PII and additionally collected information on our Site or
            through other sites and mobile apps through our advertising service
            providers or partners using cookies, IP addresses, and other
            technologies. The collected information may include, but is not
            limited to, the number of page visits, pages viewed on our Site,
            search engine referrals, browsing activities over time and across
            other sites following your visit to one of our Site or apps, and
            responses to advertisements and promotions on the Site and on sites
            and apps where we advertise.
          </p>
          <p>
            <strong>Debt.com</strong>&nbsp;uses the PII and additionally
            collected information (as applicable) described in this Notice to
            help advertise our products and services in a variety of ways. We
            may use such information to:
          </p>
          <ul>
            <li>Present tailored ads to you in the form of:</li>
            <li>
              Banner ads and splash ads and other mobility applications; or
              <ul>
                <li>
                  E-mail newsletters and communications, postal mail, and other
                  telemarketing communications;
                </li>
              </ul>
            </li>
            <li>Analyze the effectiveness of our ads; and/or</li>
            <li>
              Determine whether you might be interested in new products or
              services which may be offered by our third party service provider
              partners.
            </li>
          </ul>
          <p>
            Our services may also be offered to you in advertisements presented
            by other providers using information obtained during your visits to
            our Site.
          </p>
        </div>
      </div>
    );
  }
);
