import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text, PlanCard } from "@components/common";
import { useStore } from "@stores";

interface DebtManagementPlanCardProps {
  showConfetti?: boolean;
  displayTerms?: boolean;
  condensed?: boolean;
}

export const DebtManagementPlanCard = observer(({ showConfetti = false, ...props }: DebtManagementPlanCardProps) => {
  const { t } = useTranslation("plan_option", { keyPrefix: "planCard" });
  const { callToActionsStore } = useStore();
  const plan = callToActionsStore.debtManagementPlan;

  if (!plan) return <></>;

  return (
    <PlanCard
      months={plan.inputParameters.programLength}
      savings={plan.estimatedSavings}
      displayTerms
      showConfetti={showConfetti}
      {...props}
    >
      <Box
        sx={{
          py: 2.5,
          px: 2,
          mx: 2,
          mb: 2,
          borderRadius: 2,
          border: (theme) => `1px solid ${theme.palette.brandGreen.main}`,
          backgroundColor: (theme) => theme.palette.brandGreen.lighter,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            columnGap: 1,
            mb: { xs: 0, md: props.condensed ? 0 : 1 },
          }}
        >
          <div>
            <Text
              color="green"
              fontSize="small"
              fontWeight="bold"
              sx={{ fontSize: { xs: "14px", md: props.condensed ? "14px" : "16px" } }}
            >
              {t("dmp.monthlyPayment")}
            </Text>
            <Text
              fontSize="xsmall"
              color="gray"
              sx={{ mt: 0.5, display: { xs: "none", md: props.condensed ? "none" : "block" } }}
            >
              *{t("dmp.estimatedPayment")}
            </Text>
          </div>
          <Box sx={{ position: "relative" }}>
            <Text
              fontSize="small"
              sx={{ position: "absolute", top: "2px", left: "-8px" }}
              fontWeight="bold"
              component="span"
              color="green"
            >
              *
            </Text>
            <Text
              color="green"
              component="span"
              fontWeight="bold"
              sx={{ fontSize: { xs: " 24px", md: props.condensed ? "24px" : "36px" }, lineHeight: 1 }}
            >
              ${plan.debtManagementPayment.monthlyPayment}
            </Text>
            <Text color="green" component="span" fontSize="small">
              {t("monthly")}
            </Text>
          </Box>
        </Box>
        <Text
          fontSize="xsmall"
          color="gray"
          sx={{ mt: 0.5, mb: 1, display: { xs: "block", md: props.condensed ? "block" : "none" } }}
        >
          *{t("dmp.estimatedPayment")}
        </Text>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Text color="gray" fontWeight="medium">
            {t("dmp.vs")}
          </Text>
          <div>
            <Text
              color="gray"
              component="span"
              fontWeight="bold"
              fontSize="large"
              sx={{ textDecoration: "line-through" }}
            >
              ${plan.regularPayment.monthlyPayment}
            </Text>
            <Text color="gray" component="span" fontSize="xsmall" sx={{ textDecoration: "line-through" }}>
              {t("monthly")}
            </Text>
          </div>
        </Box>
      </Box>
    </PlanCard>
  );
});
