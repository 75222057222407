import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  formEntry: {
    width: "100% !important",
    "& .MuiTextField-root, .MuiInputBase-root": {
      width: "100%",
    },
  },

  question: {
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
  },

  resetUserButton: {
    color: "#5a5a5a",
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
    cursor: "pointer",
    outline: "inherit",
    fontSize: "1rem",
    display: "block",
    fontWeight: 700,
    "&:hover": {
      textDecoration: "underline",
    },
  },

  verificationBox: {
    maxWidth: "100%",
  },
}));
