import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode, Suspense } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

import { AnimatedColorBar, Loader } from '@components/common';
import { CallForHelpFooter } from '@components/layout/Footer/CallForHelpFooter.tsx';
import { CreditAuthorizationFooter } from '@components/layout/Footer/CreditAuthorizationFooter.tsx';
import { DebtReviewFooter } from '@components/layout/Footer/DebtReviewFooter.tsx';
import { SelectedPlanFooter } from '@components/layout/Footer/SelectedPlanFooter.tsx';
import { SelectPlanFooter } from '@components/layout/Footer/SelectPlanFooter.tsx';
import { useStyles } from '@components/layout/Form/form.styles.ts';
import { useStore } from '@stores';
import { theme } from '@styles';
import { FORM_TYPES } from '@types';

import { BudgetToolFooter } from '../Footer/BudgetToolFooter';

interface ModalProps {
  name: string;
  children: ReactNode;
  modalLoading?: boolean;
  methods: UseFormReturn<FORM_TYPES>;
  onSubmit: SubmitHandler<FORM_TYPES>;
  nextUserCtaAfterCreditPull: () => void;
}

export const ModalForm: FC<ModalProps> = observer(
  ({
    children,
    name,
    modalLoading,
    methods,
    onSubmit,
    nextUserCtaAfterCreditPull,
  }) => {
    const classes = useStyles({ name });
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { remoteConfigStore } = useStore();

    let footer: JSX.Element = <></>;

    const creditModuleFooter: JSX.Element = (
      <CreditAuthorizationFooter
        name={name}
        methods={methods}
        onSubmit={onSubmit}
      />
    );

    const budgetToolFooter: JSX.Element = <BudgetToolFooter />;

    const debtReviewFooter: JSX.Element = <DebtReviewFooter name={name} />;

    const selectPlanFooter: JSX.Element = (
      <SelectPlanFooter methods={methods} onSubmit={onSubmit} name={name} />
    );

    const planSelectedFooter: JSX.Element = <SelectedPlanFooter name={name} />;

    const callForHelpFooter: JSX.Element = (
      <CallForHelpFooter name={name} showDoneToggle />
    );

    switch (name) {
      case 'CreditModule':
        footer = creditModuleFooter;
        break;
      case 'BudgetTool':
        if (isDesktop) {
          footer = budgetToolFooter;
        }
        break;
      case 'DebtReview':
        footer = debtReviewFooter;
        break;
      case 'SelectPlan':
        footer = selectPlanFooter;
        break;
      case 'PlanSelected':
        footer = planSelectedFooter;
        break;
      case 'CallForHelp':
        footer = callForHelpFooter;
        break;
    }

    return (
      <>
        {methods && (
          <FormProvider {...methods}>
            <div
              className={classes.container}
              id="form-container"
              data-cy="Form.container"
            >
              {name === 'CreditModule' ? (
                <AnimatedColorBar
                  nextUserCtaAfterCreditPull={nextUserCtaAfterCreditPull}
                  initialAnimationRunTime={
                    remoteConfigStore.creditPullAnimationRunTime || '15'
                  }
                >
                  <>
                    <div className={classes.content}>{children}</div>
                    {footer}
                  </>
                </AnimatedColorBar>
              ) : (
                <Suspense fallback={<Loader data-cy="modal-loader-spinner" />}>
                  {modalLoading ? (
                    <Loader data-cy="modal-loader-spinner" />
                  ) : (
                    <>
                      <div className={classes.content} data-cy="Form.content">
                        {children}
                      </div>
                      {footer}
                    </>
                  )}
                </Suspense>
              )}
            </div>
          </FormProvider>
        )}
      </>
    );
  }
);
