import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  centerContainer: {
    width: "100%",
    position: "relative",
    zIndex: 99,
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
      width: "80%",
    },
  },
}));
