import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    flex: "1 0 0",
    gap: "5rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
      gap: "1rem",
    },
  },
  header: {
    flexBasis: "35%",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    flex: 1,
    gap: "20px",
  },
  textArea: {
    background: "#F6F7F8",
    fontSize: "12px",
    color: "#74808b",
    padding: "1rem",
  },
  paddingTop16: {
    paddingTop: "1rem",
  },
  paddingTop8: {
    paddingTop: "8px",
  },
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    gap: "8px",
    marginLeft: "0px",
    margin: "16px 24px",
    padding: "16px 22px",
    width: "100%",
    borderRadius: "4px",
    border: "solid 1px #e71815",
    backgroundColor: "#fff4f4",
  },
  errorHeader: {
    fontWeight: 500,
    fontSize: "16px !important",
    lineHeight: 1.38,
    color: `${theme.palette.error.main} !important`,
  },
  errorDescription: {
    lineHeight: 1.43,
    fontWeight: "normal",
    letterSpacing: "normal",
  },
}));
