import { ChatOutlined } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStore } from '@stores';
import { useSessionTimer } from '@utils';

interface ContinueChatButtonProps extends ButtonProps {
  fullWidth?: boolean;
}

export const ContinueChatButton = observer(
  ({ sx, ...props }: ContinueChatButtonProps) => {
    const { forceExtendSession } = useSessionTimer();
    const { chatStore } = useStore();
    const { t } = useTranslation('common');

    const handleOnClick = () => {
      chatStore.launchChat();
      forceExtendSession();
    };

    return (
      <Button
        onClick={handleOnClick}
        {...props}
        sx={[
          (theme) => ({
            color: theme.palette.brandBlue.dark,
            fontSize: theme.fontSizes.medium,
            fontWeight: theme.fontWeights.bold,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'none',
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <ChatOutlined sx={{ mr: 1 }} />
        {t('continueChat')}
      </Button>
    );
  }
);
