import { observer } from "mobx-react";

import { DebtSettlementPlanCard, DebtManagementPlanCard } from "@components/common";
import { useStore } from "@stores";
import { JourneyId } from "@types";

interface PlanCardProps {
  showStatus?: boolean;
  condensed?: boolean;
  showConfetti?: boolean;
}

/** Displays a plan card with the active journey's plan data */
export const PlanCardByJourney = observer((props: PlanCardProps) => {
  const {
    profileStore,
    callToActionsStore: { allDebtSettlementPlans, debtManagementPlan },
  } = useStore();

  const isMissingPlans = !allDebtSettlementPlans && !debtManagementPlan;

  if (isMissingPlans) return <></>;

  switch (profileStore.journeyId) {
    case JourneyId.DEBT_SETTLEMENT:
      return <DebtSettlementPlanCard {...props} />;
    case JourneyId.DEBT_MANAGEMENT:
      return <DebtManagementPlanCard {...props} />;
    default:
      return <></>;
  }
});
