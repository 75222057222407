import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  contentViewContainer: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    padding: "20px",
    "&.overlay": {
      opacity: "1",
      pointerEvents: "none",
      background: "rgba(52,49,49,0.5)",
      transition: "opacity 250ms ease",
      zIndex: 1,
    },
    // TODO: Once work on the Debt Journey view begins, update this to "mobile first" design
    [theme.breakpoints.down("md")]: {
      alignItems: "flex-start",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      width: "100%",
    },
  },
}));
