const postGTMEvent = (data: unknown) => {
  return window.dataLayer.push(data);
};

export const GTMPageView = (pageTitle: string) => {
  postGTMEvent({
    event: "virtualPageview",
    page_title: pageTitle,
  });
};

export const GTMButtonClick = (buttonText: string) => {
  postGTMEvent({
    event: "button_click",
    click_text: buttonText,
  });
};

export const GTMNavigationClick = (navText: string) => {
  postGTMEvent({
    event: "navigation_click",
    click_text: navText,
  });
};

export const GTMExitClick = (pageTitle: string) => {
  postGTMEvent({
    event: "exit_click",
    page_title: pageTitle,
  });
};

export const GTMOnboardingInitialization = () => {
  postGTMEvent({
    event: "onboarding_initialized",
    source: "IDA SDK",
  });
};

export const GTMProfileCreated = (affiliate_name: string, workflow: string) => {
  postGTMEvent({
    event: "profile_created",
    source: "IDA SDK",
    affiliate_name,
    workflow_variant: workflow,
  });
};

export const GTMVerificationError = () => {
  postGTMEvent({
    event: "issue",
    issue_type: "code: Verification Error, name: invalid code",
  });
};

export const GTMOffRampClicked = (pageTitle: string) => {
  postGTMEvent({
    event: "off_ramp_click",
    page_title: pageTitle,
  });
};

export const GTMOffRampConfirmed = (pageTitle: string) => {
  postGTMEvent({
    event: "off_ramp_confirmed",
    page_title: pageTitle,
  });

  switch (pageTitle) {
    case "Credit-Auth":
      postGTMEvent({
        event: "negative_end_state_no_credit",
        debt_program: "debt settlement",
      });
      break;
    case "Debt-Review":
    case "Select-Plan":
      postGTMEvent({
        event: "semi_positive_end_state_with_credit",
        debt_program: "debt settlement",
      });
      break;
  }
};

export const GTMUserLoggedIn = () => {
  postGTMEvent({
    event: "user_login",
  });
};

export const GTMCreditPullSuccess = () => {
  postGTMEvent({
    event: "credit_report_successful",
  });
};

export const GTMCreditConsent = (servicer: string) => {
  postGTMEvent({
    event: "data_sharing_consent_provided",
    servicer_name: servicer,
  });
};

export const GTMSeePlanOptions = () => {
  postGTMEvent({
    event: "see_plan_options_click",
  });
};

export const GTMPlanOptionsToolTip = () => {
  postGTMEvent({
    event: "tooltip_clicked",
    tooltip_location: "PlanOptions",
    tooltip_name: "PlanOptionsToolTip",
  });
};

export const GTMSessionTimeoutPrompted = (pageTitle: string) => {
  postGTMEvent({
    event: "session_timeout_prompted",
    page_title: pageTitle,
  });
};

export const GTMSessionExtended = () => {
  postGTMEvent({
    event: "session_extended",
  });
};

export const GTMSessionNotExtended = () => {
  postGTMEvent({
    event: "session_not_extended",
  });
};

export const GTMProvideMotivations = () => {
  postGTMEvent({
    event: "debt_motivations_provided",
    debt_motivations_selected: "",
  });
};

export const GTMUserLoggedOut = () => {
  postGTMEvent({
    event: "user_logout",
  });
};

export const GTMWorkflowExitConfirmed = (pageTitle: string) => {
  postGTMEvent({
    event: "workflow_exit_confirmed",
    page_title: pageTitle,
  });
};

export const GTMCreditReportTimeout = () => {
  postGTMEvent({
    event: "issue",
    issue_type: "credit_report_timeout",
  });
};

export const GTMCreditReportError = () => {
  postGTMEvent({
    event: "issue",
    issue_type: "credit_report_retrieval",
  });
};

export const GTMUserCallNowClicked = (pageTitle: string, endPath: string) => {
  postGTMEvent({
    event: "call_now",
    end_state_path: endPath,
    page_title: pageTitle,
  });
};

export const GTMLinkOutClicked = (externalLink: string) => {
  postGTMEvent({
    event: "link_out_click",
    external_link_url: externalLink,
  });
};

export const GTMDynamicEvent = (name: string) => {
  postGTMEvent({
    event: name,
  });
};

export const GTMNotUserClicked = () => {
  postGTMEvent({
    event: "button_click",
    click_text: `Not User`,
  });
};

export const GTMInitiate = (ga_client_id: string) => {
  postGTMEvent({
    event: "initiate",
    ga_client_id: ga_client_id,
  });
};

export const GTMPlanSubmitted = (cta: string) => {
  postGTMEvent({
    event: "plan_submitted",
    plan_submitted_cta: cta,
  });
};

export const GTMEsignViewed = () => {
  postGTMEvent({
    event: "esign_viewed",
  });
};

export const GTMEsignButtonClicked = () => {
  postGTMEvent({
    event: "esign_button_click",
  });
};

export const GTMEsignSkipped = () => {
  postGTMEvent({
    event: "esign_skipped",
  });
};

export const GTMEsignCompleted = () => {
  postGTMEvent({
    event: "esign_completed",
  });
};

export const GTMAgentConnectAttempted = () => {
  postGTMEvent({
    event: "agent_connect_attempted",
  });
};
