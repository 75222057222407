import { Box, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text, PlanCardByJourney } from "@components/common";
import { Module } from "@components/layout";
import { SelectPlanTooltip, NextStep } from "@components/layout/SelectPlan";
import { theme } from "@styles";

export const SelectPlan = observer(() => {
  const { t } = useTranslation("plan_option");
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Module data-cy="select-plan">
      <Module.Sidebar>
        <div>
          <Module.Header component="span">
            {t("header.optionsHeader")}
            <SelectPlanTooltip />
          </Module.Header>
        </div>

        <Text fontSize={"medium"} fontWeight={"semiBold"} lineHeight={"medium"}>
          {t("header.subHeader")}
        </Text>
      </Module.Sidebar>
      <Module.Content>
        <Box component="form" mb={{ md: 2 }}>
          <PlanCardByJourney />
        </Box>
        {isDesktop && <NextStep />}
      </Module.Content>
    </Module>
  );
});
