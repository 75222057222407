import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useSignUpFormStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
  },

  recaptcha: {
    width: "100%",
    left: 0,
  },

  contentViewContainer: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    padding: "32px 24px 24px 24px",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "24px",
      margin: "0 1rem",
      backgroundColor: "unset",
      width: "100% !important",
    },
  },

  viewContainer: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      flexBasis: "36rem",
      margin: "0 auto",
      backgroundColor: "white",
      padding: "24px 32px",
      boxShadow: theme.customShadows.clickable,
    },
  },

  sub: {
    paddingTop: "12px",
  },

  question: {
    paddingTop: "32px",
  },

  buttonWrapper: {
    marginTop: 20,
    display: "flex",
    width: "100%",
  },

  termsTextContainer: {
    display: "flex",
    // height: '100%',
    gap: "0.5rem",
    alignItems: "flex-end",
    boxShadow: "unset !important",
    borderRadius: "4px !important",
    overflow: "visible !important",
    "& .MuiCardContent-root": {
      background: theme.palette.brandGray.other,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "24px",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#cbd1d7",
    },
  },

  messageContentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingBottom: "50px",
  },

  alertBox: {
    paddingTop: "20px",
    borderRadius: "2px",
    width: "100%",
  },

  setButton: {
    width: "100%",
    alignSelf: "center",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      paddingTop: "1.5rem",
      width: "max-content",
    },
  },

  signIn: {
    borderRadius: "16px",
    width: "100%",
    boxShadow: theme.customShadows.clickable,
    padding: "2rem",
    backgroundColor: theme.palette.common.white,
    marginBottom: "1rem",
    [theme.breakpoints.up("md")]: {
      marginBottom: "0",
    },
  },

  header: {
    marginBottom: "2rem",
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },

  termsTextContent: {
    padding: `0 !important`,
  },

  footerButtonContainer: {
    display: "flex",
    flex: "1",
    flexDirection: "column-reverse",
    gap: "8px",
    width: "100%",
    padding: "0px 0px 24px 0px",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "15px",
      padding: 0,
    },
  },
  callMeButton: {
    margin: "0 auto",
    [theme.breakpoints.up("md")]: {
      paddingTop: "unset",
      height: "min-content",
      padding: "unset",
    },
  },
}));
