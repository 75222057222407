import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  value: number;
}

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.brandGray.light}`,
    width: "100%",
    height: "20px",
    borderRadius: "10px",
    padding: "3px",
    display: "flex",
    alignItems: "center",
  },
  bar: {
    backgroundImage: `linear-gradient(270deg, ${theme.palette.brandGreen.main} 0%, ${theme.palette.brandBlue.main} 100%) !important`,
    backgroundSize: "100%",
    marginTop: "-1px",
    height: "100%",
    borderRadius: "10px",
    width: (props: Props) => `${props.value}%`,
  },
}));
