import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DownArrow from '@assets/icons/doubleDownArrow.svg';

import { useStyles } from './ScrollIndicator.styles';
import { Text } from '../Text';

export const ScrollIndicator: FC<{
  spaceFromBottom?: number;
  scrollDivId: string;
  atBottomCallback?: () => void;
}> = ({ spaceFromBottom = 40, scrollDivId, atBottomCallback }) => {
  const { t, i18n } = useTranslation('common');
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);
  const classes = useStyles({
    isAtBottom,
    spaceFromBottom,
    language: i18n.language,
  });

  useEffect(() => {
    const handleScroll = () => {
      let isBottom = false;
      const element = document.getElementById(scrollDivId);
      if (element) {
        const scrolled = element.scrollTop;
        const maxHeight = element.scrollHeight - element.clientHeight;
        const scrollPercent = Math.ceil((scrolled / maxHeight) * 100);
        isBottom = !(scrollPercent < 100);
        setIsAtBottom(isBottom);
      }
      if (isBottom) {
        atBottomCallback?.()
      }
    };

    handleScroll()
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollDivId, atBottomCallback]);


  return (
    <div className={classes.container}>
      <Text color="white" fontSize="xsmall">
        {t('scroll')}
      </Text>
      <img src={DownArrow} alt="Scroll Indicator" />
    </div>
  );
};
