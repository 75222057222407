import { createTheme } from "@mui/material/styles";

import { palette, shadows, fontSizes, fontWeights, fontLineHeights } from "./";

export const theme = createTheme({
  palette: {
    primary: palette.brandDarkBlue,
    ...palette,
  },
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  fontLineHeights: fontLineHeights,
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  customShadows: shadows,
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          lineHeight: 0,
          padding: 0,
          textTransform: "none",
          fontWeight: "400",
          textDecoration: "underline",
          color: palette.brandGray.main,
          "&:hover": {
            background: "none",
            textDecoration: "underline",
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(3px)",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: palette.brandGreen.dark,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: palette.brandGreen.dark,
          },
          "&.Mui-error": {
            color: palette.brandRed.dark,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.brandGreen.main,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.brandGreen.dark,
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.brandRed.dark,
          },
        },
      },
    },
  },
});
