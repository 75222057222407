import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    minWidth: "87px",
    height: "24px",
    padding: "6px 8px",
    borderRadius: "4px",
    boxSizing: "border-box",
    textTransform: "uppercase",
  },
  primary: {
    backgroundColor: "none",
    border: `1px solid ${theme.palette.brandDarkBlue.main}`,
  },
  secondary: {
    backgroundColor: theme.palette.brandMagenta.main,
  },
  tertiary: {
    backgroundColor: theme.palette.brandGreen.dark,
  },
}));
