/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { ButtonBranded, NavItem } from '@components/common';
import { DashboardHeader, DashboardOutlet, NavBar } from '@components/layout';
import { AbsoluteTimerModal } from '@components/views';
import { BudgetInputModal } from '@components/views/Modals/BudgetInputModal';
import { LogOutModal } from '@components/views/Modals/LogOutModal';
import { useStore } from '@stores';
import { theme } from '@styles';
import { shouldShowMyPlanNavItem, useSessionTimer } from '@utils';

import { useStyles } from './DashboardWrapper.styles';

export const DashboardWrapper = observer(() => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { profileStore, budgetStore, commonStore, modalStore, sessionStore } =
    useStore();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('log_out');

  const handleHomeClick = () => {
    commonStore.setUserClosedCredAuthModal(true);
    navigate('/dashboard');
  };

  const { startTimer } = useSessionTimer();

  useEffect(() => {
    startTimer();
  }, []);

  useEffect(() => {
    return () => {
      if (sessionStore.sessionTimer) {
        clearInterval(sessionStore.sessionTimer);
      }
    };
  }, []);

  if (profileStore.profile) {
    return (
      <div className={classes.container}>
        {budgetStore.isBudgetEdit && (
          <Box sx={{ zIndex: 999 }}>
            <BudgetInputModal />
          </Box>
        )}
        <div className={classes.absoluteTimerModalWrapper}>
          <AbsoluteTimerModal showModal={modalStore.showExtendModal} />
        </div>
        <LogOutModal handleLogout={sessionStore.logout} />
        {!isDesktop && (
          <div className={classes.header}>
            <DashboardHeader />
          </div>
        )}

        <div className={classes.content}>
          {isDesktop && (
            <div className={classes.logOutButtonWrapper}>
              <ButtonBranded
                variant="textonly"
                data-cy="logout-button"
                className={classes.logOutButton}
                fontSize={theme.fontSizes.medium}
                fontWeight={theme.fontWeights.regular}
                lineHeight={theme.fontLineHeights.medium}
                label={t('logout_button_label')}
                onClick={sessionStore.logout}
              />
            </div>
          )}
          <div
            className={classes.innerContent}
            data-cy="DashboardWrapper.innerContent"
          >
            <DashboardOutlet />
          </div>
        </div>
        <div className={classes.footer}>
          <NavBar>
            <NavItem
              label={t('home')}
              icon="home"
              onClick={handleHomeClick}
              active={location.pathname == '/dashboard'}
            />
            {shouldShowMyPlanNavItem(profileStore.profile) && (
              <NavItem
                dataCy="my-plans"
                label={t('my_plans')}
                icon="plans"
                route="/user-plans"
                active={location.pathname == '/user-plans'}
              />
            )}
            <NavItem
              label={t('profile')}
              icon="profile"
              route="/profile"
              active={location.pathname == '/profile'}
            />
          </NavBar>
        </div>
      </div>
    );
  }
});
