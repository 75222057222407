import { Box, Grid, SvgIcon } from "@mui/material";
import { useTranslation } from "react-i18next";

import badgeIcon from "@assets/icons/securebadge.svg?react";
import { theme } from "@styles";

import { useStyles } from "./CreditAuthInfoCard.styles";
import { Text } from "../..";

export const CreditAuthInfoCard = () => {
  const { t } = useTranslation("credit_authorization");
  const bulletPoints = t("infoCard", { returnObjects: true }) as string[];
  const { icon } = useStyles();

  return (
    <Box
      sx={{
        borderRadius: "4px",
        padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
        marginTop: theme.spacing(2),
        background: theme.palette.brandGreen.lighter,
      }}
    >
      <Grid container spacing={1.5} sx={{ padding: 0 }}>
        <Grid item xs>
          <ul style={{ margin: 0 }}>
            {bulletPoints.map((bullet) => (
              <Text
                key={bullet}
                fontWeight="semiBold"
                lineHeight="medium"
                sx={{ color: theme.palette.brandGreen.dark }}
              >
                <li>{bullet}</li>
              </Text>
            ))}
          </ul>
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "center", paddingTop: 0 }}>
          <SvgIcon component={badgeIcon} inheritViewBox className={icon} />
        </Grid>
      </Grid>
    </Box>
  );
};
