import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  pendingEnrollment: boolean;
  dropShadow: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "8px",
    boxSizing: "border-box",
    boxShadow: (props: Props) => (props.dropShadow ? theme.customShadows.clickable : "unset"),
    overflow: "hidden",
    "&:hover": {
      cursor: (props: Props) => `${props.pendingEnrollment ? "" : "pointer"}`,
      backgroundColor: (props: Props) => `${props.pendingEnrollment ? "" : "theme.palette.brandGray.lighter"}`,
    },
  },
  boldText: {
    fontWeight: 800,
    color: theme.palette.brandDarkBlue.other,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "12px 16px",
    gap: "1rem",
  },
  savings: {
    whiteSpace: "pre-line",
    color: theme.palette.brandGray.main,
    fontWeight: 300,
    lineHeight: "18px",
    fontSize: "14px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0",
    margin: 0,
  },
  monthlyPayment: {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 400,
    color: theme.palette.brandGray.main,
    lineHeight: "24px",
  },
  slash: {
    marginTop: "5px",
    fontSize: "16px",
  },
  monthlyPaymentAmount: {
    fontWeight: 600,
    color: theme.palette.brandDarkBlue.other,
  },
  pending: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "8px 10px",
    backgroundColor: theme.palette.brandDarkBlue.other,
  },
  pendingDot: {
    display: "block",
    backgroundColor: theme.palette.brandYellow.main,
    height: "10px",
    width: "10px",
    borderRadius: "50%",
  },
}));
