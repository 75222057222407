import Rollbar from "rollbar";

const environment = import.meta.env.VITE_ROLLBAR_ENVIRONMENT ?? "local";
const codeVersion = import.meta.env.VITE_CODE_VERSION ?? "";

const enabled = !window.Cypress || !window.location.origin.includes("localhost");

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN_CLIENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  uncaughtErrorLevel: "critical",
  environment,
  enabled,
  verbose: true,
  scrubTelemetryInputs: true,
  captureIp: false,
  captureEmail: false,
  scrubFields: [
    "key",
    "email",
    "search",
    "address",
    "dob",
    "ssn",
    "phone",
    "firstName",
    "lastName",
    "employmentStatus",
    "hhiConsistency",
    "householdIncome",
    "analysis",
    "eligibleTradeLines",
    "planDetails",
    "debt-settlement",
  ],
  autoInstrument: {
    contentSecurityPolicy: true,
    errorOnContentSecurityPolicy: true,
  },
  payload: {
    client: {
      javascript: {
        code_version: codeVersion,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
  transform: (payload: Rollbar.Payload): void => {
    const trace = payload.body?.trace;
    if (trace?.frames) {
      trace.frames.forEach((frame: { filename?: string }) => {
        if (frame.filename) {
          frame.filename = frame.filename.replace(
            /https?:\/\/[a-zA-Z0-9.-]+(:\d+)?/,
            `https://ida-${environment}-rollbar-alias/dist`,
          );
        }
      });
    }
  },
};

export const rollbar = new Rollbar(rollbarConfig);
