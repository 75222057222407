import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    padding: "32px 24px 24px 24px",
    [theme.breakpoints.up("md")]: {
      padding: "24px",
      margin: "0 1rem",
      width: "100% !important",
    },
  },

  viewContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    width: "100%",
    padding: "4rem 1rem",
    borderRadius: "16px",
    boxShadow: theme.customShadows.clickable,
    [theme.breakpoints.up("md")]: {
      flexBasis: "36rem",
      margin: "0 auto",
    },
  },
}));
