import { useMediaQuery } from '@mui/system';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Text, SsnErrorCard } from '@components/common';
import { AutoCompleteAddress } from '@components/common/AutoCompleteAddress';
import { SimpleDateField } from '@components/common/SimpleDateField';
import { SsnInput } from '@components/common/SsnInput';
import { useStore } from '@stores';
import { theme } from '@styles';
import { CreditAuthForm } from '@types';

import { useStyles } from './styles.ts';
import { CreditAuthInfoCard } from '../../../layout/CreditAuthInfoCard/index.tsx';

export const CreditModule = () => {
  const { control, trigger, resetField } = useFormContext<CreditAuthForm>();
  const { t } = useTranslation('credit_authorization');
  const { callToActionsStore } = useStore();
  const { ssnError } = callToActionsStore;
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.textContainer}>
          <Text
            data-cy="credit-authorization-header"
            fontSize={'xxxxlarge'}
            fontWeight={'bold'}
            lineHeight={'xxxxxlarge'}
          >
            {t('header1')}
          </Text>
          <Text
            fontSize={'medium'}
            fontWeight={'semiBold'}
            lineHeight={'medium'}
            color={'accent'}
            className={classes.paddingTop16}
          >
            {t('subheader')}
          </Text>
          <Text
            fontSize={'small'}
            fontWeight={'light'}
            lineHeight={'xxsmall'}
            className={classes.paddingTop8}
          >
            {t('infoText')}
          </Text>
        </div>
        <CreditAuthInfoCard />
        {ssnError && !isDesktop && <SsnErrorCard />}
      </div>
      <form className={classes.content}>
        <div>
          {ssnError && isDesktop && <SsnErrorCard />}
          <AutoCompleteAddress
            data-cy="credit-authorization-address-input"
            control={control}
            trigger={trigger}
            resetField={resetField}
            label={t('form.addressLabel')}
          />
        </div>
        <div
          style={{ display: 'flex' }}
          data-cy="credit-authorization-dob-input"
        >
          <SimpleDateField control={control} />
        </div>
        <div
          style={{ display: 'flex' }}
          data-cy="credit-authorization-ssn-input"
        >
          <SsnInput control={control} />
        </div>
        <div
          data-cy="credit-authorization-disclaimer"
          id="outlined-multiline-static"
          className={classes.textArea}
        >
          {t('disclaimer', { buttonLabel: t('agreeAuth') })}
        </div>
      </form>
    </div>
  );
};
