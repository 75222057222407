import { ChatOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ButtonBranded, Text } from "@components/common";
import { FooterLegalInfo } from "@components/common/FooterLegalInfo";
import { Modal } from "@components/layout";
import { CallForHelpFooter, ConfirmationModalView } from "@components/layout/Footer";
import { useSignUpFormStyles } from "@components/views/Authentication/SignUp/SignUpForm.styles.ts";
import { useStore } from "@stores";
import { theme } from "@styles";
import { Engagement, StoredProfile } from "@types";
import { CTAModulesEnum, GTMOffRampClicked, useSimpleTimer } from "@utils";

import { VerificationSelection } from "../VerificationSelection";

export interface AuthProps {
  storedProfile?: StoredProfile;
  setPhone?: (phone: string) => void;
  sendSms: () => Promise<string | undefined>;
  verifyUser: () => Promise<void>;
  children?: React.ReactNode;
}
export const SignUpForm = observer(({ verifyUser, children }: AuthProps) => {
  const [open, setOpen] = useState(false);
  const [showHelpCard, setShowHelpCard] = useState(false);
  const {
    modalStore: { module, openModal },
    engagementStore: { engagement, updateEngagementStatus },
    remoteConfigStore: { timers, featureFlags },
    chatStore: { launchChat, enabled: chatEnabled, initializeChat, hasActiveChat },
  } = useStore();
  const classes = useSignUpFormStyles();

  const matchWidth = useMediaQuery("(max-width:900px)");
  const { t } = useTranslation("verification_page");

  const navigate = useNavigate();

  const handleTimeout = () => {
    // TODO: Update when we have the thank you page designs
    updateEngagementStatus(engagement!.id, "ready_for_submission").then(() => {
      if (engagement) {
        window.localStorage.setItem("engagementData", "");
        navigate("/thankyou");
      }
    });
  };

  const { startTimer, stopTimer } = useSimpleTimer(timers.sessionTimeout || 15, handleTimeout);

  useEffect(() => {
    startTimer();

    return () => {
      stopTimer();
    };
  }, [startTimer, stopTimer]);

  useEffect(() => {
    const engagementFromStorage = window.localStorage.getItem("engagementData");
    if (engagementFromStorage) {
      const engagement = JSON.parse(engagementFromStorage) as Engagement;
      if (engagement.status === "ready_for_submission") {
        setShowHelpCard(true);
        openModal(CTAModulesEnum["call-for-help"]);
      }
      initializeChat({
        firstName: engagement.profile.first_name,
        lastName: engagement.profile.last_name,
        engagementId: engagement.id,
      });
    }
  }, [openModal, initializeChat]);

  const CallMeButton = (
    <ButtonBranded
      variant="textonly"
      fontSize={theme.fontSizes.medium}
      fontWeight={theme.fontWeights.medium}
      lineHeight={theme.fontLineHeights.xxxsmall}
      className={classes.callMeButton}
      sx={{ width: "100%" }}
      onClick={() => {
        GTMOffRampClicked("Sign-up");
        setOpen(!open);
      }}
      data-cy="callMeButton"
    >
      {t("callMe")}
    </ButtonBranded>
  );

  return (
    <>
      <ConfirmationModalView
        isOpen={open}
        handlePrimary={() => {
          setShowHelpCard(true);
          setOpen(false);
          if (engagement && engagement.id) {
            updateEngagementStatus(engagement!.id, "ready_for_submission");
          }
          openModal(CTAModulesEnum["call-for-help"]);
          window.localStorage.setItem("engagementData", "");
        }}
        handleSecondary={() => {
          setOpen(false);
        }}
        handleCancel={() => {
          setOpen(false);
        }}
        name={"sign-up"}
      />
      {showHelpCard && (
        <div style={{ marginRight: "20px" }}>
          <Modal style={{ padding: "20px" }}>
            <div style={{ maxHeight: "calc(100vh - 270px)" }}>{module.component}</div>
            <CallForHelpFooter showDoneToggle />
          </Modal>
        </div>
      )}
      {!showHelpCard && (
        <Box className={classes.contentViewContainer}>
          <div className={classes.viewContainer}>
            <Text
              fontSize={matchWidth ? "xxlarge" : "xxxlarge"}
              fontWeight="semiBold"
              lineHeight={matchWidth ? "xsmall" : "large"}
            >
              {t("header")}
            </Text>
            <Text
              pt={2}
              fontSize={matchWidth ? "medium" : "large"}
              fontWeight={matchWidth ? "regular" : "light"}
              lineHeight={matchWidth ? "medium" : "large"}
            >
              {t("subHeader3")}
            </Text>
            <Text
              style={{ paddingTop: "24px" }}
              fontSize={matchWidth ? "medium" : "large"}
              fontWeight={matchWidth ? "regular" : "light"}
              lineHeight={matchWidth ? "medium" : "large"}
            >
              {t("subHeader2")}
            </Text>
            <VerificationSelection />

            {chatEnabled && (
              <>
                <Text
                  pt={4}
                  fontSize={matchWidth ? "medium" : "large"}
                  fontWeight="semiBold"
                  lineHeight={matchWidth ? "medium" : "large"}
                >
                  {t("chat.header")}
                </Text>
                <Text
                  fontSize={matchWidth ? "medium" : "large"}
                  fontWeight={matchWidth ? "regular" : "light"}
                  lineHeight={matchWidth ? "medium" : "large"}
                >
                  {t("chat.description")}
                </Text>

                <Button
                  onClick={() => launchChat()}
                  sx={(theme) => ({
                    py: 1,
                    color: theme.palette.brandBlue.dark,
                    fontSize: matchWidth ? theme.fontSizes.medium : theme.fontSizes.large,
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  })}
                >
                  <ChatOutlined sx={{ mr: 1 }} />
                  {t(hasActiveChat ? "continueChat" : "chat", { ns: "common" })}
                </Button>
              </>
            )}
            {/* //TODO: The verification selection options and flow needs to be reworked to match the designs */}

            <div className={`${classes.footerButtonContainer}`}>
              {featureFlags.enableVerifyPageOffRamp && <div className={classes.setButton}>{CallMeButton}</div>}

              <div className={classes.setButton}>
                <ButtonBranded
                  variant="primary"
                  padding={matchWidth ? "18px 32px" : "12px 24px"}
                  fontSize={matchWidth ? theme.fontSizes.xlarge : "medium"}
                  onClick={verifyUser}
                >
                  {t("sendSMSButton")}
                </ButtonBranded>
              </div>
            </div>
            {children}
            <FooterLegalInfo />
          </div>
        </Box>
      )}
    </>
  );
});
