import {
  BudgetItemData,
  BudgetType,
  CallToActionId,
  DebtManagementPlanDetails,
  DebtSettlementPlanDetails,
  EngagementDebtTypes,
  JourneyName,
  DocumentData,
} from "@types";
import { ProfileSubStatus } from "@utils";

import { Servicer } from "./servicers";

export interface ProfileAddress {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

interface ProfileVerificationMethodInfo {
  value: string;
  isPreferred: boolean;
  isVerified: boolean;
  verificationDate?: Date;
}

export interface ProfileVerificationInfo {
  email?: ProfileVerificationMethodInfo;
  phone?: ProfileVerificationMethodInfo;
}
export interface Profile {
  id?: string;
  engagementId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address?: {
    street?: string;
    city?: string;
    state: string;
    zipCode?: string;
    country?: string;
  };
  dob: {
    year: string;
    month: string;
    day: string;
  };
  ssn: string;
  employmentStatus: string;
  hhiConsistency: string;
  householdIncome: number;
  verification?: ProfileVerificationInfo;
  preferredLanguage: string;
  nextCallToAction?: CallToAction;
  expirationDate?: Date | string;
  profileCreatedDate?: Date | string;
  subStatus?: ProfileSubStatus;
  servicer?: Servicer;
  enrollmentPercentageComplete?: number;
  lastCompletedPage?: VTPageTitles;
  isBudgetPageVisited?: boolean;
  callActionEnrolled?: boolean;
  engagementLeadType?: JourneyName;
  engagementDebtTypes?: EngagementDebtTypes[];
  debtAmount?: number;
  documents: { [key: string]: DocumentData };
}

export interface ProfileResponse extends Profile {
  completedCallToActionIds: CallToActionId;
  profilePercentageComplete: number;
  enrollmentPercentageComplete: number;
  lastCompletedPage: VTPageTitles;
  fileNumber: string;
  expirationDate: Date | string;
  profileCreatedDate: Date | string;
  subStatus: ProfileSubStatus;
  journeys: ProfileJourney;
  journeyIds: string[];
  budget: BudgetType<BudgetItemData[]>;
}

export interface InvokeSweeperResponse {
  status: number;
  description: string;
}

export interface ProfileJourney {
  "debt-settlement": {
    totalEligibleDebt: number;
    selectedPlan?: DebtSettlementPlanDetails;
    callToActionIds?: string[];
    submittedDate: string;
    completedCallToActionIds: string[];
  };
  "debt-management": {
    totalEligibleDebt: number;
    selectedPlan?: DebtManagementPlanDetails;
    callToActionIds?: string[];
    submittedDate: string;
    completedCallToActionIds: string[];
  };
}

export type UpdateProfile = Partial<Profile>;

export interface TemporaryUser {
  phone?: string;
  email?: string;
}

export interface StoredProfile {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  phoneSignInMethod?: boolean;
  emailSignInMethod?: boolean;
  preferredLanguage?: string;
}

export interface CallToAction {
  id: string;
  priority: number;
  name: string;
  summary: string[];
  buttonLabel: string;
  imageUrl: string;
}

export type UserPlanCardType = {
  planType: string;
  fileNumber: string;
  submittedDate: string;
  totalEligibleDebt: number;
  programTimePeriod: number;
  monthlyPayment: number;
  servicerName?: string;
  servicerLogo?: string;
  estimatedSavings?: number;
  isPlanSelectionAbandoned: boolean;
  isPlanSelectedByUser: boolean;
  profileCreatedDate: string | Date;
};

export interface ProfileExists {
  exists: boolean;
}

export enum VTPageTitlesEnum {
  CONSUMER_VERIFIED = "Consumer Verified",
  SERVICER_CONSENT = "Servicer Consent",
  CREDIT_AUTHORIZATION = "Credit Authorization",
  DEBT_REVIEW = "Debt Review",
  BUDGET_TOOL = "Budget Tool",
  SELECT_PLAN = "Select Plan",
  DONE = "Done",
}

export type VTPageTitles = (typeof VTPageTitlesEnum)[keyof typeof VTPageTitlesEnum];

export enum MyPlanWorkflowOptionsEnum {
  DEFAULT = "default",
  POSITIVE = "positive",
  NEGATIVE = "negative",
  SEMI_POSITIVE = "semi-positive",
}

export type MyPlanWorkflowOptions = (typeof MyPlanWorkflowOptionsEnum)[keyof typeof MyPlanWorkflowOptionsEnum];
