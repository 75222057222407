import { Box, Grid, Divider, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonBranded } from '@components/common';
import { FooterLegalInfo } from '@components/common/FooterLegalInfo';
import { InfoShareAgreementModal } from '@components/index';
import { AvailableCash } from '@components/layout/BudgetTool/AvailableCash';
import { ConfirmationModalView } from '@components/layout/Footer/ConfirmationModalView';
import { useStore } from '@stores';
import { theme } from '@styles';
import { JourneyId, VTPageTitlesEnum } from '@types';
import {
  CTAModuleID,
  CTAModulesEnum,
  GTMDynamicEvent,
  GTMOffRampClicked,
  handlePercentageAndPageCompleted,
  JourneyCTAEnum,
} from '@utils';

export const BudgetToolFooter = observer(() => {
  const { t } = useTranslation('budget_tool');
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    profileStore,
    modalStore: {
      openModal,
      showOffRampModal,
      setShowOffRampModal,
      setClickSource,
      clickSource,
    },
    callToActionsStore,
    budgetStore,
    remoteConfigStore: { featureFlags },
  } = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const showSkipButton = featureFlags.enableSkipBudgetTool;
  const isDMP = profileStore.journeyId === JourneyId.DEBT_MANAGEMENT;

  const handleSubmit = async (isSkipped?: boolean) => {
    setIsLoading(true);
    if (!isSkipped) {
      GTMDynamicEvent('budget_completed');
    } else {
      GTMDynamicEvent('budget_skipped');
    }
    await handlePercentageAndPageCompleted(
      featureFlags.enableBudgetTool,
      VTPageTitlesEnum.BUDGET_TOOL,
      profileStore
    );

    openModal(CTAModulesEnum['select-plan' as CTAModuleID]);
    if (profileStore.profile?.id) {
      const profile = await callToActionsStore.updateJourneyCta(
        profileStore.profile.id,
        profileStore.journeyId!,
        JourneyCTAEnum['complete-your-budget']
      );
      profileStore.setProfile(profile);
    }
    budgetStore.resetStatuses();
    setIsLoading(false);
  };

  return (
    <Box data-cy="budget-tool-footer">
      {isDesktop && <Divider sx={{ marginBottom: 2, color: 'black' }} />}
      <Grid
        container
        direction="row"
        alignItems="center"
        width="auto"
        wrap="nowrap"
        justifyContent="space-between"
        m={2}
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
          },
        }}
        data-cy="BudgetToolFooter.grid-containter"
      >
        <Grid
          item
          minWidth={320}
          display="flex"
          justifyContent="flex-start"
          sx={{
            [theme.breakpoints.down('md')]: {
              paddingTop: 2,
            },
          }}
        >
          <AvailableCash />
        </Grid>
        {!isDesktop && (
          <Divider sx={{ paddingTop: 2, color: 'black', width: '100%' }} />
        )}
        <Grid
          item
          sx={{
            [theme.breakpoints.down('md')]: {
              padding: 2,
              width: '100%',
            },
          }}
        >
          <Grid
            container
            spacing={2}
            display="flex"
            flexWrap="wrap-reverse"
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column-reverse',
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          >
            <Grid
              display="flex"
              justifyContent="center"
              item
              sx={{
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }}
            >
              {showSkipButton && (
                <ButtonBranded
                  data-cy="skip-budget"
                  variant="textonly"
                  onClick={() => {
                    setClickSource('footer');
                    setShowOffRampModal(true);
                  }}
                >
                  {t('footer.skipButton')}
                </ButtonBranded>
              )}
            </Grid>
            <Grid
              item
              minWidth="168px"
              sx={{
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }}
            >
              <ButtonBranded
                loading={isLoading}
                disabled={!budgetStore.isComplete}
                data-cy="see-plan-options"
                variant="primary"
                onClick={handleSubmit}
              >
                {t('footer.primaryButtonLabel')}
              </ButtonBranded>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationModalView
        isOpen={showOffRampModal}
        handlePrimary={async () => {
          if (clickSource === 'footer') {
            handleSubmit(true);
          } else {
            setShowOffRampModal(false);
          }
        }}
        handleSecondary={() => {
          setShowOffRampModal(false);
          if (clickSource === 'header') {
            GTMOffRampClicked('BudgetTool');
            setShowOffRampModal(!showOffRampModal);
          }
        }}
        handleCancel={() => {
          setShowOffRampModal(false);
        }}
        name="complete-your-budget"
      />
      <FooterLegalInfo />
      <InfoShareAgreementModal
        showModal={isDMP && budgetStore.showAgreement}
        onSubmit={() => budgetStore.setUserAgreed(true)}
        setShowModal={() => {
          budgetStore.setShowAgreement(false)
        }}
      />
    </Box>
  );
});
