import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useStore } from "@stores";
import {
  BudgetType,
  CreditAuthForm,
  FORM_TYPES,
  IBudgetItemData,
  initializeForm,
  PartialCreditAuthFormType,
  PlanSelectionForm,
} from "@types";
import { CTAModuleID, CTAModulesEnum, JourneyCTAEnum, ProfileSubStatusEnum } from "@utils";

export const useFormActions = (name: string) => {
  const { t } = useTranslation("credit_authorization");
  const { profileStore, modalStore, callToActionsStore, remoteConfigStore } = useStore();

  const [enableSsnValidation, setEnableSsnValidation] = useState(
    remoteConfigStore.featureFlags.enableSsnValidation ?? true,
  );

  useEffect(() => {
    setEnableSsnValidation(remoteConfigStore.featureFlags.enableSsnValidation ?? true);
  }, [remoteConfigStore.featureFlags.enableSsnValidation]);

  const form = initializeForm(name, t, enableSsnValidation);

  const methods = useForm<
    CreditAuthForm | PlanSelectionForm | PartialCreditAuthFormType | BudgetType<IBudgetItemData[]>
  >({
    mode: "onChange",
    defaultValues: form?.defaultValues,
    resolver: form?.resolver,
  });

  const handleSubmission = useCallback(
    async (data: CreditAuthForm) => {
      const { address } = data;
      const dob = new Date(data.dob).toISOString().slice(0, 10).split("-");

      if (profileStore.profile?.id) {
        const body = {
          address: {
            street: address.street_line.trim(),
            city: address.city.trim(),
            state: address.state.trim(),
            zipCode: address.zipcode,
            country: "USA",
          },
          dob: {
            year: dob[0],
            month: dob[1],
            day: dob[2],
          },
          ssn: data.ssn,
        };
        await profileStore.updateUserProfileAPI(profileStore.profile.id, body);
        await callToActionsStore.authorizeCreditPull(profileStore.profile.id);
        await profileStore.updateProfile();
        methods?.control.unregister("address");
        methods?.control.unregister("dob");
        methods?.control.unregister("ssn");
      }
    },
    [profileStore, callToActionsStore, methods?.control],
  );

  const selectPlanSubmitHandler = useCallback(
    async (data: PlanSelectionForm) => {
      if (profileStore.profile?.id && profileStore.journeyId) {
        await callToActionsStore.saveSelectedPlan(profileStore.profile.id, data);
        await callToActionsStore.updateJourneyCta(
          profileStore.profile.id,
          profileStore.journeyId,
          JourneyCTAEnum["select-plan"],
        );
        profileStore.updateEnrollmentStatus(ProfileSubStatusEnum.IDA_ENROLLMENT_COMPLETED);
        profileStore.setIsEnrollmentComplete(true);
      }
    },
    [callToActionsStore, profileStore],
  );

  const submitHandler: SubmitHandler<FORM_TYPES> = async (data) => {
    if (name === "CreditModule") {
      await handleSubmission(<CreditAuthForm>data);
    } else {
      await selectPlanSubmitHandler(<PlanSelectionForm>data);
    }
  };

  const nextUserCtaAfterCreditPull = () => {
    if (callToActionsStore.loader.isSuccess.get()) {
      modalStore.openModal(CTAModulesEnum["review-debt-eligibility" as CTAModuleID]);
    } else if (callToActionsStore.loader.hasError.get() && callToActionsStore.ssnError === false) {
      modalStore.openModal(CTAModulesEnum["call-for-help" as CTAModuleID]);
    } else if (callToActionsStore.ssnError === true) {
      modalStore.openModal(CTAModulesEnum["authorize-credit-pull" as CTAModuleID]);
    }
  };

  return {
    submitHandler,
    methods: methods,
    nextUserCtaAfterCreditPull,
  };
};
