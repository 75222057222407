import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonBranded } from '@components/common';
import { useStyles } from '@components/layout/Form/form.styles.ts';
import { useStore } from '@stores';

import { FooterLegalInfo } from '../../common/FooterLegalInfo';

export const CallForHelpFooter = ({
  name,
  showDoneToggle,
}: {
  name?: string;
  showDoneToggle: boolean;
}) => {
  const { t } = useTranslation('call_for_help');
  const classes = useStyles({ name });
  const [showDone, setShowDone] = useState(true);
  const { profileStore, modalStore } = useStore();
  const id = profileStore.profile?.id;

  useEffect(() => {
    if (!showDoneToggle) {
      setShowDone(false);
    } else {
      setShowDone(true);
    }
  }, [showDoneToggle]);

  const handleDone = async () => {
    if (id) {
      await profileStore.getUserProfileFromAPI(id);
    }

    modalStore.closeModal();
  };

  return (
    <div className={classes.footer}>
      <div className={`${classes.footerButtonContainer}`}>
        {showDone && (
          <div className={classes.nextActionButton}>
            <ButtonBranded
              variant={'primary'}
              className={classes.doneCallButton}
              onClick={handleDone}
            >
              {t('primaryButtonLabel')}
            </ButtonBranded>
          </div>
        )}
      </div>
      <FooterLegalInfo />
    </div>
  );
};
