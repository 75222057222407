import { AxiosError } from "axios";
import { User } from "firebase/auth";
import { makeAutoObservable, reaction } from "mobx";

import { AxiosAgent } from "@services";
import {
  Engagement,
  JourneyId,
  JourneyName,
  Profile,
  ProfileResponse,
  TemporaryUser,
  UpdateProfile,
  VTPageTitles,
} from "@types";
import {
  convertEngagementToProfileObject,
  GTMDynamicEvent,
  GTMProfileCreated,
  ProfileSubStatus,
  setLanguage,
} from "@utils";

import LoadingStore from "./LoadingStore.ts";
import { store } from "./store";
import { rollbar } from "../../rollbarConfig.ts";
import { AuthError } from "../utils/errors/AuthError.ts";

export default class ProfileStore {
  profile?: ProfileResponse;
  loader: LoadingStore;
  tempUser: TemporaryUser = {};
  verificationMethodValue: string | null = null;
  userPhoneInput: string | undefined = undefined;
  userEmailInput: string | undefined = undefined;
  isEnrollmentComplete = false;

  constructor() {
    makeAutoObservable(this, {
      createProfile: false,
      updateUserProfileAPI: false,
      doesProfileExistInDatabase: false,
    });
    this.loader = new LoadingStore();
    reaction(
      () => this.profile?.journeys,
      () => {
        store.remoteConfigStore.getFeatureFlags();
      },
    );
    reaction(
      () => this.profile?.id,
      (id) => {
        if (id) {
          store.chatStore.updateProfileId(id);
        }
      },
    );
    reaction(
      () => this.profile?.lastCompletedPage,
      (pageTitle) => {
        if (pageTitle) {
          store.chatStore.updateLastPage(pageTitle);
        }
      },
    );
  }

  setIsEnrollmentComplete = (isEnrollmentComplete: boolean) => {
    this.isEnrollmentComplete = isEnrollmentComplete;
  };

  clearProfileStore = () => {
    this.profile = undefined;
    this.tempUser = {};
    this.verificationMethodValue = null;
  };

  setVerificationMethodValue = (value: string) => {
    this.verificationMethodValue = value;
  };

  setTempUser = (engagement: Partial<Engagement>) => {
    this.tempUser = {
      phone: engagement.profile?.phone_mobile,
    };
    setLanguage(engagement.profile?.language);
  };

  setUserPhoneInput = (phone: string) => {
    this.userPhoneInput = phone;
  };

  get username() {
    return `${this.profile?.firstName} ${this.profile?.lastName}`;
  }

  setProfile = (profile: ProfileResponse) => {
    this.profile = profile;
    setLanguage(profile.preferredLanguage);
  };

  doesProfileExistInDatabase = async (id: string): Promise<ProfileResponse | undefined> => {
    try {
      return await AxiosAgent.Profile.getById(id);
    } catch (error) {
      return undefined;
    }
  };

  updateProfile = async () => {
    if (this.profile?.id) {
      const profile = await AxiosAgent.Profile.getById(this.profile.id);
      this.setProfile(profile);
    }
  };

  getUserProfileFromAPI = async (id: string): Promise<ProfileResponse | void> => {
    try {
      this.loader.updateState("LOADING");
      const profile = await AxiosAgent.Profile.getById(id);
      this.setProfile(profile);
      this.loader.updateState("SUCCESS");
    } catch (error: unknown) {
      this.loader?.updateState("ERROR");
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          throw new AuthError({
            name: "api/no-profile-found",
            message: "Profile does not exist.",
            cause: error,
          });
        }
      }
    }
  };

  updateUserProfileAPI = async (id: string, profileData: UpdateProfile): Promise<void> => {
    const profile = await AxiosAgent.Profile.update(id, profileData);
    this.setProfile(profile);
  };

  createProfile = async (profileObject: Profile): Promise<ProfileResponse> => {
    return await AxiosAgent.Profile.create(profileObject);
  };

  findOrCreateProfile = async (profileObject: Profile): Promise<ProfileResponse> => {
    return await AxiosAgent.Profile.findOrCreate(profileObject);
  };

  invokeSweeper = async (id: string) => {
    return await AxiosAgent.Profile.invokeSweeper(id);
  };

  createOrGetProfile = async (user: User, verificationValue: string = this.verificationMethodValue!): Promise<void> => {
    try {
      const engagement = store.engagementStore.engagement;
      this.loader.updateState("LOADING");
      if (engagement) {
        const profileObject = convertEngagementToProfileObject(engagement, verificationValue, user.uid);
        const profile = await this.findOrCreateProfile(profileObject);
        GTMProfileCreated(engagement.partner_id ?? "Direct", engagement.experiment_name ?? "Direct");
        this.setProfile(profile);
      } else {
        const existingProfile = await this.doesProfileExistInDatabase(user.uid);
        if (existingProfile) {
          this.setProfile(existingProfile);
        }
      }
      this.loader.updateState("SUCCESS");
    } catch (error: unknown) {
      this.loader.updateState("ERROR");
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          throw new AuthError({
            name: "api/no-profile-found",
            message: "Profile does not exist.",
            cause: error,
          });
        } else if (error.response?.status === 409) {
          throw new AuthError({
            name: "api/profile-exists-with-a-different-auth-method",
            message: "Profile already exists. Please try with the previously used authentication method",
            cause: error,
          });
        } else {
          throw new AuthError({
            name: "api/internal-error",
            message: "Error - Internal error encountered.",
          });
        }
      }
    }
  };

  updateProfileExpirationDate = async (newExpirationDate: Date): Promise<void> => {
    try {
      const profile = await AxiosAgent.Profile.update(this.profile!.id!, {
        expirationDate: newExpirationDate,
      });
      this.setProfile(profile);
    } catch (error: unknown) {
      rollbar.debug("Error updating profile expiration date", error as Error);
    }
  };

  updateEnrollmentStatus = async (status: ProfileSubStatus) => {
    if (this.profile?.id) {
      const profile = await AxiosAgent.Profile.update(this.profile.id, {
        subStatus: status,
      });
      this.setProfile(profile);
    }
  };

  updatePercentageCompleteAndLastPageCompleted = async (percentage: number, page: VTPageTitles) => {
    store.chatStore.updateLastPage(page);

    if (this.profile?.id) {
      const profile = await AxiosAgent.Profile.update(this.profile.id, {
        enrollmentPercentageComplete: percentage,
        lastCompletedPage: page,
      });
      this.setProfile(profile);
    }
  };

  getServicer = async (state: string) => {
    if (this.profile?.id) {
      const servicer = await AxiosAgent.Utils.getServicer(state);
      const profile = await AxiosAgent.Profile.update(this.profile.id, {
        servicer,
      });
      this.setProfile(profile);
    }
  };

  updateIsBudgetPageVisited = async () => {
    if (this.profile?.id && !this.profile.isBudgetPageVisited) {
      const profile = await AxiosAgent.Profile.update(this.profile.id, {
        isBudgetPageVisited: true,
      });
      GTMDynamicEvent("budget_page_viewed");
      this.setProfile(profile);
    }
  };

  private getJourneyIdFromName(name: JourneyName) {
    const journeyMap = {
      [JourneyName.DEBT_MANAGEMENT]: JourneyId.DEBT_MANAGEMENT,
      [JourneyName.DEBT_SETTLEMENT]: JourneyId.DEBT_SETTLEMENT,
    };

    return journeyMap[name];
  }

  get journeyId() {
    if (this.profile?.journeys && this.profile?.engagementLeadType) {
      const leadType = this.profile.engagementLeadType;
      return this.getJourneyIdFromName(leadType);
    }
    // default debt-settlement
    return JourneyId.DEBT_SETTLEMENT;
  }

  get journey() {
    return this.profile?.journeys?.[this.journeyId];
  }
  get hasSelectedPlan() {
    const selectedPlan = this.journey?.selectedPlan;
    return !!selectedPlan && !!Object.keys(selectedPlan).length;
  }
}
