import { TextField } from '@mui/material';
import { omit } from 'lodash';
import { FC, forwardRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMask, IMaskInput } from 'react-imask';

import './styles.css';

import { CreditAuthForm } from '@types';

type CustomProps = {
  onChange: (val: string) => void;
  handleChange: (val: string) => void;
};

const formatter = new Intl.DateTimeFormat('en-US', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});

const pattern = 'MM/`DD/`YYYY';

const blocks = {
  date: {
    mask: IMask.MaskedRange,
    placeholderChar: 'D',
    from: 1,
    to: 31,
    maxLength: 2,
  },
  month: {
    mask: IMask.MaskedRange,
    placeholderChar: 'M',
    from: 1,
    to: 12,
    maxLength: 2,
  },
  year: {
    mask: IMask.MaskedRange,
    placeholderChar: 'Y',
    from: 1900,
    to: 9999,
  },
};

const imaskOptions = {
  mask: Date,
  pattern,
  lazy: false,
  autofix: true,
  overwrite: true,
  blocks: {
    DD: blocks.date,
    MM: blocks.month,
    YYYY: blocks.year,
  },
  format: function (date: Date | null) {
    return date ? formatter.format(date) : '';
  },
  parse: function (string: string) {
    return new Date(string);
  },
};

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom({ handleChange, ...other }, ref) {
    const rest = omit(other, 'onChange');
    return (
      <IMaskInput
        {...rest}
        {...imaskOptions}
        inputRef={ref}
        overwrite
        onAccept={(value) => {
          handleChange(value);
        }}
        inputMode="numeric"
      />
    );
  }
);

export const SimpleDateField = ({
  control,
}: {
  control: Control<CreditAuthForm, CreditAuthForm>;
}) => {
  const [date, setDate] = useState('');
  const { t } = useTranslation('credit_authorization');

  const handleChange = (value: string) => {
    setDate(value);
  };

  return (
    <Controller
      control={control}
      name="dob"
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          sx={{ flex: 1 }}
          autoComplete="off"
          inputProps={{
            inputMode: 'decimal',
          }}
          value={date}
          name="dob"
          label={t('form.dobLabel')}
          InputLabelProps={{ shrink: true }}
          error={!!error}
          helperText={error?.message}
          onBlur={(e) => onChange(e.target.value)}
          InputProps={{
            inputComponent: TextMaskCustom as unknown as FC,
            inputProps: {
              handleChange,
            },
          }}
        />
      )}
    />
  );
};
