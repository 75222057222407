import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Text, Tool, ToolCard } from '@components/common';

import { useStyles } from './Tools.styles.ts';

export const Tools: React.FC = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation('tools');

  const cards: Tool[] = t('cards', {
    returnObjects: true,
  });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text
          fontSize="xxlarge"
          fontWeight="semiBold"
          lineHeight="xxlarge"
          color="brandBlueOther"
        >
          {t('header')}
        </Text>
        <Text
          fontSize="medium"
          fontWeight="regular"
          lineHeight="medium"
          color="brandBlueOther"
        >
          {t('description')}
        </Text>
      </div>
      <Grid container spacing={3}>
        {cards.map((card, idx) => {
          return (
            <Grid item xs={12} md={6} key={idx}>
              <ToolCard key={idx} toolData={card} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
});
