/**
 * Font Weights being used by the design.
 * Update with any values as needed.
 *
 * light = 300 //
 * regular = normal (400) //
 * medium = 500 //
 * semiBold = 600 //
 * bold = bold (700)
 */
export const fontWeights = {
  light: "300 !important",
  regular: "normal !important",
  medium: "500 !important",
  semiBold: "600 !important",
  bold: "bold !important",
};
