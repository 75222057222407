import { Theme } from "@mui/material";

import { ColorOptions } from "src/styles/mui-custom";

export const colorMap = (theme: Theme): { [key in ColorOptions]: string | undefined } => {
  return {
    brandBlue: theme.palette.brandDarkBlue.main,
    brandBlueOther: theme.palette.brandDarkBlue.other,
    brandRed: theme.palette.brandRed.main,
    brandDarkBlue: theme.palette.brandDarkBlue.dark,
    hoverBlue: theme.palette.brandHover.main,
    accent: theme.palette.brandBlue.darker,
    hoverAccent: theme.palette.brandHoverAccent.main,
    brandTeal: theme.palette.brandTeal.main,
    gray: theme.palette.brandGray.main,
    darkGray: theme.palette.brandGray.dark,
    darkerGray: theme.palette.brandGray.darker,
    white: theme.palette.common.white,
    black: theme.palette.common.black,
    pink: theme.palette.brandPink.main,
    green: theme.palette.brandGreen.dark,
  };
};
