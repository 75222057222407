import { Stack, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PlanCard, Text } from "@components/common";
import { useStore } from "@stores";
import { formatToNearestDollar } from "@utils";

interface DebtSettlementPlanCardProps {
  showConfetti?: boolean;
}

export const DebtSettlementPlanCard = observer(({ showConfetti = true, ...props }: DebtSettlementPlanCardProps) => {
  const {
    profileStore,
    callToActionsStore: { allDebtSettlementPlans },
  } = useStore();
  const plans = allDebtSettlementPlans;
  const methods = useFormContext();
  const { t } = useTranslation("plan_option");

  const longestPlan = plans?.planDetails.slice().sort((a, b) => b.programTimePeriod - a.programTimePeriod)[0];

  useEffect(() => {
    if (methods && longestPlan && profileStore.profile?.id) {
      methods?.setValue("plan", {
        id: longestPlan.id,
        description: t("planDescription"),
      });
    }
  }, [longestPlan, methods, t, profileStore]);

  if (!longestPlan) return <></>;

  const list: { [key: string]: { value: number; type: "old" | "new" } } = {
    currentBalance: {
      value: plans.totalEligibleDebt,
      type: "old",
    },
    newBalance: {
      value: longestPlan.estimatedDebtPayoff,
      type: "new",
    },
    newMonthlyPlan: {
      value: longestPlan.estimatedPmtWithLegal,
      type: "new",
    },
  };

  return (
    <PlanCard
      months={longestPlan.programTimePeriod}
      savings={longestPlan.savingsWithLegal >= 100 ? longestPlan.savingsWithLegal : undefined}
      showConfetti={showConfetti}
      showAnimatedConfetti={longestPlan && longestPlan.savingsWithLegal >= 300}
      {...props}
    >
      <Stack sx={{ gap: 2, px: 2, pb: 2 }}>
        {Object.keys(list).map((key) => {
          const { value, type } = list[key];

          return (
            <Box key={key} display="flex" flexWrap="nowrap" alignItems="center">
              <Text
                fontWeight={type === "old" ? "regular" : "bold"}
                sx={{
                  color: (theme) => (type === "old" ? theme.palette.brandGray.dark : theme.palette.brandGreen.dark),
                }}
              >
                {t(`planCard.dsp.${key}`)}
              </Text>
              <Box
                mx={3}
                sx={{
                  borderWidth: { xs: 0, lg: "1px 0 0 0" },
                  borderTop: (theme) => `1px solid ${theme.palette.brandGray.main}`,
                  borderStyle: "dashed",
                }}
                flexGrow={1}
              />

              <Text
                fontWeight={type === "old" ? "regular" : "bold"}
                sx={{
                  textDecoration: type === "old" ? "line-through" : "none",
                  color: (theme) => (type === "old" ? theme.palette.brandGray.main : theme.palette.brandGreen.dark),
                }}
              >
                ${formatToNearestDollar(value)}
              </Text>
            </Box>
          );
        })}
      </Stack>
    </PlanCard>
  );
});
