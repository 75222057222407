import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useCallForHelpStyles = makeStyles((theme: Theme) => ({
  fontDarkGray: {
    color: `${theme.palette.brandGray.darker} !important`,
  },
  fontAccent: {
    color: `${theme.palette.brandBlue.darker} !important`,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    minWidth: "200px",
    [theme.breakpoints.up("md")]: {
      padding: "32px 0 32px 24px",
    },
  },
  containerWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  paddingTop8: {
    paddingTop: "8px",
  },
  paddingTop16: {
    paddingTop: "1rem",
  },
  imageWrapper: {
    paddingTop: "1rem",
    overflow: "hidden",
  },
  image: {
    height: "300px",
    width: "100%",
    objectFit: "cover",
    objectPosition: "0 -18px",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      objectPosition: "0 0",
      paddingLeft: "24px",
    },
  },
}));
