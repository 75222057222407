import { Box, Grid, IconButton, SvgIcon } from '@mui/material';
import { observer } from 'mobx-react-lite';

import logoIcon from '@assets/icons/logo.svg?react';
import menuIcon from '@assets/icons/menu.svg?react';
import { useStore } from '@stores';

import { useStyles } from './DashboardHeader.styles';

export const DashboardHeader: React.FC = observer(() => {
  const classes = useStyles();
  const {
    modalStore: { setShowLogOffModal },
  } = useStore();

  const handleClickShowModal = () => {
    setShowLogOffModal(true);
  };

  return (
    <Box className={classes.container}>
      <Grid
        container
        spacing={1.5}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs="auto" alignSelf={'end'}>
          <SvgIcon
            inheritViewBox
            component={logoIcon}
            className={classes.svgIconLogo}
          />
        </Grid>
        <Grid item xs="auto">
          <IconButton
            data-cy="hamburger-button"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={handleClickShowModal}
          >
            <SvgIcon
              inheritViewBox
              component={menuIcon}
              style={{ width: '32px', height: '32px' }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
});
