import { Box, Grid, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Text } from "@components/common";
import { BudgetGauge } from "@components/layout";
import {
  BUDGET_COLORS,
  BudgetToolAccordion,
  DMP_DEBT_OPTIONAL_ITEMS,
  DMP_EXPENSE_OPTIONAL_ITEMS,
  DMP_INCOME_OPTIONAL_ITEMS,
  EXPENSE_OPTIONAL_ITEMS,
  INCOME_OPTIONAL_ITEMS,
} from "@components/layout/BudgetTool";
import { BudgetToolFooter } from "@components/layout/Footer/BudgetToolFooter";
import { useStore } from "@stores";
import { theme } from "@styles";
import { BudgetTypeEnum, BudgetStatusEnum, GaugeDataValue, BudgetType, JourneyId } from "@types";

export const BudgetToolModule = observer(() => {
  const { t } = useTranslation("budget_tool");
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { budgetStore, profileStore } = useStore();
  const { profile, updateIsBudgetPageVisited } = profileStore;
  const [expanded, setExpanded] = useState<BudgetTypeEnum | null>(null);
  const { totalValues, isEmptyData } = budgetStore;
  const isDMP = profileStore.journeyId === JourneyId.DEBT_MANAGEMENT;
  const isDSP = profileStore.journeyId === JourneyId.DEBT_SETTLEMENT;
  const gaugeData = Object.values(BudgetTypeEnum).reduce(
    (acc, v, i) => ({
      ...acc,
      [v]: {
        id: i,
        value: totalValues[v] || 0,
        color: BUDGET_COLORS[v],
        budgetType: v,
      },
    }),
    {},
  ) as BudgetType<GaugeDataValue>;

  useEffect(() => {
    budgetStore.setData(profile?.budget);
    async function updatePageVisit() {
      if (profile && !profile.isBudgetPageVisited) {
        if (isDSP) {
          budgetStore.setStatus(BudgetTypeEnum.DEBT, BudgetStatusEnum.COMPLETED);
        }
        await updateIsBudgetPageVisited();
      }
    }
    updatePageVisit();
  }, [profile, budgetStore, updateIsBudgetPageVisited, isDSP]);

  const toggleAccordion = (itemType: BudgetTypeEnum) => {
    if (expanded === itemType) {
      setExpanded(null);
    } else {
      setExpanded(itemType);

      if (budgetStore.statuses[itemType] === BudgetStatusEnum.PENDING) {
        budgetStore.setStatus(itemType, BudgetStatusEnum.STARTED);
      }
    }
  };

  const handleSectionSubmission = (itemType: BudgetTypeEnum) => {
    budgetStore.setStatus(itemType, BudgetStatusEnum.COMPLETED);
    const budgetTypes = Object.values(BudgetTypeEnum);
    const itemIndex = budgetTypes.indexOf(itemType);
    // lastElement
    if (itemIndex === budgetTypes.length - 1) {
      setExpanded(null);
    } else {
      toggleAccordion(budgetTypes[itemIndex + 1]);
    }
  };

  return (
    <Grid flexDirection="column" display="flex" justifyContent="space-between" height="100%">
      <Box
        display="flex"
        data-cy="BudgetTool-box-container"
        flexDirection="row"
        alignItems="flex-start"
        flex="'1 0 0'"
        gap="5rem"
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            gap: "1rem",
          },
        }}
      >
        <Box
          display="flex"
          flexBasis="35%"
          flexDirection="column"
          sx={{
            [theme.breakpoints.down("md")]: {
              padding: theme.spacing(4),
            },
          }}
        >
          <Text data-cy="budget-tool-header" fontSize="xxxxlarge" fontWeight="bold" lineHeight="xxxxxlarge">
            {t("header")}
          </Text>
          <Text sx={{ fontSize: "15px", fontWeight: 600, lineHeight: 1.6 }} pt={2}>
            {t("subHeaderLabel")}
          </Text>
          <Text fontSize="small" fontWeight="light" lineHeight="xxsmall">
            {t("subHeader")}
          </Text>
        </Box>
        <Grid container direction="column" alignItems="center">
          <Grid item>{isEmptyData ? <BudgetGauge /> : !expanded && <BudgetGauge data={gaugeData} />}</Grid>
          {profile && (
            <Grid item sx={{ width: "100%" }}>
              <BudgetToolAccordion
                budgetType={BudgetTypeEnum.DEBT}
                optionalItems={isDMP ? DMP_DEBT_OPTIONAL_ITEMS : []}
                expanded={expanded === BudgetTypeEnum.DEBT}
                onChange={budgetStore.agreementWrapper(!isDMP, () => toggleAccordion(BudgetTypeEnum.DEBT))}
                handleSubmit={handleSectionSubmission}
                sx={{ "&:before": { display: "none" } }}
              />

              <BudgetToolAccordion
                budgetType={BudgetTypeEnum.INCOME}
                optionalItems={isDMP ? DMP_INCOME_OPTIONAL_ITEMS : INCOME_OPTIONAL_ITEMS}
                expanded={expanded === BudgetTypeEnum.INCOME}
                onChange={budgetStore.agreementWrapper(!isDMP, () => toggleAccordion(BudgetTypeEnum.INCOME))}
                handleSubmit={handleSectionSubmission}
              />
              <BudgetToolAccordion
                budgetType={BudgetTypeEnum.EXPENSE}
                optionalItems={isDMP ? DMP_EXPENSE_OPTIONAL_ITEMS : EXPENSE_OPTIONAL_ITEMS}
                expanded={expanded === BudgetTypeEnum.EXPENSE}
                onChange={budgetStore.agreementWrapper(!isDMP, () => toggleAccordion(BudgetTypeEnum.EXPENSE))}
                handleSubmit={handleSectionSubmission}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      {!isDesktop && <BudgetToolFooter />}
    </Grid>
  );
});
