import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerModal } from '@components/common/DrawerModal';
import { TermsEnVersion, TermsEsVersion } from '@components/layout/Footer';

import { useStyles } from './TermsAndPolicy.styles';

export const TermsAndPolicy = () => {
  const {
    t: termsAndPolicyTranslation,
    i18n: { language },
  } = useTranslation('footer_links');
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const classes = useStyles();

  const hideModal = () => {
    setShowTermsOfUse(false);
    setShowPrivacyPolicy(false);
  };

  const displayTermsLangVersion = () => {
    if (language === 'es') {
      return <TermsEsVersion hideModal={hideModal} />;
    }
    return <TermsEnVersion hideModal={hideModal} />;
  };

  const displayPrivacyPolicyLangVersion = () => {
    if (language === 'es') {
      return (
        <TermsEsVersion hideModal={hideModal} scrollToPrivacyPolicyRef={true} />
      );
    }
    return (
      <TermsEnVersion hideModal={hideModal} scrollToPrivacyPolicyRef={true} />
    );
  };
  return (
    <div className={classes.termsAndPolicyContainer}>
      <Button
        className={classes.button}
        onClick={() => {
          setShowTermsOfUse(true);
          setShowPrivacyPolicy(false);
        }}
      >
        {termsAndPolicyTranslation('termsOfUseButton')}
      </Button>

      <Button
        className={classes.button}
        onClick={() => {
          setShowPrivacyPolicy(true);
          setShowTermsOfUse(false);
        }}
      >
        {termsAndPolicyTranslation('privacyPolicyButton')}
      </Button>

      <DrawerModal
        show={showTermsOfUse || showPrivacyPolicy}
        setShow={hideModal}
      >
        <div>
          {showTermsOfUse && displayTermsLangVersion()}
          {showPrivacyPolicy && displayPrivacyPolicyLangVersion()}
        </div>
      </DrawerModal>
    </div>
  );
};
