import { BudgetTypeEnum, DMP_SUB_CATEGORIES, DSP_SUB_CATEGORIES } from "@types";

export const BUDGET_COLORS = {
  [BudgetTypeEnum.INCOME]: "#6DA852",
  [BudgetTypeEnum.DEBT]: "#44ACCD",
  [BudgetTypeEnum.EXPENSE]: "#E71915",
};

export const INCOME_OPTIONAL_ITEMS = [
  DSP_SUB_CATEGORIES.SECONDARY_INCOME,
  DSP_SUB_CATEGORIES.CHILD_SUPPORT_ALIMONY,
  DSP_SUB_CATEGORIES.SOCIAL_SECURITY,
  DSP_SUB_CATEGORIES.PENSION,
  DSP_SUB_CATEGORIES.DISABILITY,
  DSP_SUB_CATEGORIES.UNEMPLOYMENT,
  DSP_SUB_CATEGORIES.RENTAL_INCOME,
];
export const EXPENSE_OPTIONAL_ITEMS = [
  DSP_SUB_CATEGORIES.HOME_OWNERS_INSURANCE,
  DSP_SUB_CATEGORIES.ELECTRICITY,
  DSP_SUB_CATEGORIES.GROCERIES,
  DSP_SUB_CATEGORIES.RESTAURANTS,
  DSP_SUB_CATEGORIES.CAR_PAYMENT,
  DSP_SUB_CATEGORIES.CAR_INSURANCE,
  DSP_SUB_CATEGORIES.INTERNET_CABLE,
  DSP_SUB_CATEGORIES.TELEPHONE,
  DSP_SUB_CATEGORIES.PERSONAL_CARE,
];

export const DMP_DEBT_OPTIONAL_ITEMS = [
  DMP_SUB_CATEGORIES.PERSONAL_LOAN,
  DMP_SUB_CATEGORIES.STUDENT_LOAN,
  DMP_SUB_CATEGORIES.MEDICAL,
  DMP_SUB_CATEGORIES.HOME_EQUITY,
  DMP_SUB_CATEGORIES.OTHER_LOAN,
];

export const DMP_INCOME_OPTIONAL_ITEMS = [
  DMP_SUB_CATEGORIES.DISABILITY,
  DMP_SUB_CATEGORIES.OTHER_INCOME,
  DMP_SUB_CATEGORIES.SECONDARY_INCOME,
  DMP_SUB_CATEGORIES.SOCIAL_SECURITY,
  DMP_SUB_CATEGORIES.ALIMONY,
  DMP_SUB_CATEGORIES.PENSION,
  DMP_SUB_CATEGORIES.UNEMPLOYMENT,
  DMP_SUB_CATEGORIES.RENTAL_INCOME,
];

export const DMP_EXPENSE_OPTIONAL_ITEMS = [
  DMP_SUB_CATEGORIES.SECOND_MORTGAGE,
  DMP_SUB_CATEGORIES.ASSOCIATION_FEES,
  DMP_SUB_CATEGORIES.REAL_ESTATE,
  DMP_SUB_CATEGORIES.HOME_OWNERS_INSURANCE,
  DMP_SUB_CATEGORIES.RENTER_INSURANCE,
  DMP_SUB_CATEGORIES.BUNDLE_TV,
  DMP_SUB_CATEGORIES.PHONE,
  DMP_SUB_CATEGORIES.INTERNET,
  DMP_SUB_CATEGORIES.WATER,
  DMP_SUB_CATEGORIES.GAS_OIL,
  DMP_SUB_CATEGORIES.CABLE,
  DMP_SUB_CATEGORIES.LUNCHES,
  DMP_SUB_CATEGORIES.AUTO_BOAT_1,
  DMP_SUB_CATEGORIES.CAR_INSURANCE,
  DMP_SUB_CATEGORIES.GAS,
  DMP_SUB_CATEGORIES.RAPAIRS,
  DMP_SUB_CATEGORIES.BUS_FARE,
  DMP_SUB_CATEGORIES.HEALTH_INSURANCE,
  DMP_SUB_CATEGORIES.PRESCRIPTION,
  DMP_SUB_CATEGORIES.LIFE_INSURANCE,
  DMP_SUB_CATEGORIES.CHILD_CARE,
  DMP_SUB_CATEGORIES.CHILD_SUPPORT,
  DMP_SUB_CATEGORIES.CLOTHING,
  DMP_SUB_CATEGORIES.HAIR_CARE,
  DMP_SUB_CATEGORIES.PETS,
  DMP_SUB_CATEGORIES.CIGARETTES,
  DMP_SUB_CATEGORIES.EDUCATION,
  DMP_SUB_CATEGORIES.CHURCH,
  DMP_SUB_CATEGORIES.CLEANING,
  DMP_SUB_CATEGORIES.HEALTH_MEMBERSHIP,
  DMP_SUB_CATEGORIES.HOME_REPAIR,
  DMP_SUB_CATEGORIES.MISC,
  DMP_SUB_CATEGORIES.SAVINGS,
];
