import { useLocation, useNavigate } from 'react-router-dom';

import { ButtonBranded, Text } from '@components/common';
import { useStyles } from '@components/views/Errors/Errors.styles';
import { theme } from '@styles';

export const ErrorView = () => {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.viewContainer}>
          <Text
            fontSize="large"
            fontWeight="regular"
            lineHeight="xxxxxlarge"
            color="darkGray"
          >
            {location.state.message}
          </Text>
          <ButtonBranded
            variant="textonly"
            fontSize={theme.fontSizes.medium}
            onClick={() => navigate('/auth')}
          >
            Return to login
          </ButtonBranded>
        </div>
      </div>
    </>
  );
};

export default ErrorView;
