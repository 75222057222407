import { Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './InvalidSignInLink.styles';

export const InvalidSignInLink = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Box className={classes.contentViewContainer}>
      <div>Invalid verification link. Please verify again.</div>
      <Link
        component={'button'}
        sx={{ fontWeight: 'bold' }}
        onClick={async () => {
          navigate('/auth');
        }}
      >
        Verify
      </Link>
    </Box>
  );
};

export default InvalidSignInLink;
