import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  tooltipContainer: {
    display: "flex",
    padding: "1rem",
    background: "#ffffff",
    [theme.breakpoints.up("md")]: {
      gap: "1rem",
      flexDirection: "column",
    },
  },
  tooltip: {
    display: "inline-block",
    color: theme.palette.brandBlue.darker,

    "&:hover": {
      cursor: "pointer",
    },
  },
}));
