import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import { useStore } from '@stores';

import { useStyles } from './DashboardOutlet.styles.ts';
import { ModuleProcessor } from '../ModuleProcessor';

/**
 * DashboardOutlet renders the ModuleProcessor if a module is active.
 * Otherwise, renders an Outlet component
 */

export const DashboardOutlet = observer(() => {
  const classes = useStyles();
  const {
    modalStore: { module },
  } = useStore();

  if (!module.component) {
    return <Outlet />;
  }

  return (
    <div
      className={classes.centerContainer}
      data-cy="DashboardOutlet.centerContainer"
    >
      <ModuleProcessor />
    </div>
  );
});
