import { makeAutoObservable } from "mobx";

import { AxiosAgent } from "@services";
import { Engagement, EngagementStatus } from "@types";

import LoadingStore from "./LoadingStore.ts";
import { rollbar } from "../../rollbarConfig.ts";

export default class EngagementStore {
  engagement: Engagement | undefined = undefined;
  engagementLoading: boolean = false;
  loader: LoadingStore;

  constructor() {
    makeAutoObservable(this);
    this.loader = new LoadingStore();
  }

  setEngagementLoading = (isLoading: boolean): void => {
    this.engagementLoading = isLoading;
  };

  clearEngagementStore = (): void => {
    this.engagement = undefined;
  };

  setEngagement = (newEngagement: Engagement): void => {
    this.engagement = newEngagement;
  };

  getEngagementById = async (id: string) => {
    try {
      this.loader.updateState("LOADING");
      const engagement = await AxiosAgent.Engagement.getById(id);
      this.setEngagement(engagement);
      this.loader.updateState("SUCCESS");
      return engagement;
    } catch (error) {
      rollbar.debug("Error retrieving the engagement", error as Error);
      this.loader.updateState("ERROR");
    }
  };
  updateEngagementStatus = async (id: string, status: EngagementStatus) => {
    const engagement = await AxiosAgent.Engagement.updateStatus(id, status.toString());
    this.setEngagement(engagement);
  };
}
