import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  emailSliderBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "24px",
  },

  modalCard: {
    zIndex: "99",
    position: "absolute",
    bottom: 0,
    right: 0,
    height: "auto",
    minHeight: "25vh",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "0 !important",
    [theme.breakpoints.up("md")]: {
      top: 0,
      right: 0,
      width: "23rem",
      alignItems: "flex-start",
      height: "100vh",
    },
  },
}));
