import { action, makeAutoObservable, reaction, runInAction } from "mobx";
import { ReactNode } from "react";

import {
  DebtReviewModule,
  BudgetToolModule,
  CreditModule,
  SelectPlan,
  PlanSelected,
  CallForHelpModule,
} from "@components/views/Modules";
import { ClickSource, creditAuthFormValue, JourneyId } from "@types";
import {
  MODULE_AUTH_CREDIT,
  MODULE_REVIEW_DEBT,
  MODULE_SELECT_PLAN,
  MODULE_PLAN_SELECTED,
  MODULE_VERIFY_CREDIT,
  CTAModule,
  MODULE_CALL_FOR_HELP,
  GTMPageView,
  MODULE_BUDGET_TOOL,
} from "@utils";

import LoadingStore from "./LoadingStore";
import { store } from "./store";
import { rollbar } from "../../rollbarConfig";
import { AxiosAgent } from "../services";

// eslint-disable-next-line react-refresh/only-export-components
const MODULES = {
  [MODULE_AUTH_CREDIT]: {
    component: <CreditModule />,
    formInput: creditAuthFormValue,
    name: "CreditModule",
  },
  [MODULE_BUDGET_TOOL]: {
    component: <BudgetToolModule />,
    name: "BudgetTool",
  },
  [MODULE_REVIEW_DEBT]: {
    component: <DebtReviewModule />,
    name: "DebtReview",
  },
  [MODULE_SELECT_PLAN]: {
    component: <SelectPlan />,
    name: "SelectPlan",
  },
  [MODULE_PLAN_SELECTED]: {
    component: <PlanSelected />,
    name: "PlanSelected",
  },
  [MODULE_VERIFY_CREDIT]: {
    component: (
      <>
        <div>Credit Review</div>
      </>
    ),
    name: "VerifyCredit",
  },
  [MODULE_CALL_FOR_HELP]: {
    component: <CallForHelpModule />,
    name: "CallForHelp",
  },
};

export default class ModalStore {
  display = false;
  module: {
    name: string;
    component?: JSX.Element;
    buttons?: ReactNode[];
  } = {
    name: "",
  };
  modalLoading?: boolean;
  loader: LoadingStore;
  showExtendModal: boolean = false;
  showOffRampModal: boolean = false;
  clickSource: ClickSource = "footer";
  showLogOffModal: boolean = false;
  isWithinServicerHours: boolean | undefined = undefined;
  declineAgentDirectContact: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.loader = new LoadingStore();

    reaction(
      () => this.module.name,
      (moduleName) => {
        if (moduleName === "SelectPlan") {
          this.retrievePlansAndServicerHours();
        }
      },
    );
  }

  getServicerHours = async () => {
    try {
      const servicerId = store.profileStore.profile?.servicer?.id;
      if (servicerId) {
        const servicerStatus = await AxiosAgent.Utils.getIsServicerOpen(servicerId);
        runInAction(() => {
          this.isWithinServicerHours = servicerStatus.isOpen;
        });
      } else {
        throw new Error("Could not retrieve hours, servicer not available.");
      }
    } catch (error) {
      rollbar.error("Error retrieving servicer hours", error as Error);
      this.isWithinServicerHours = false;
    }
  };

  setModalLoading = action((isLoading: boolean): void => {
    this.modalLoading = isLoading;
  });

  openModal = (module: CTAModule) => {
    GTMPageView(MODULES[module].name);
    this.display = true;
    this.module = MODULES[module];
  };

  closeModal = () => {
    this.display = false;
    this.module = { name: "" };
  };

  clearModalStore = () => {
    this.display = false;
    this.module = { name: "" };
    this.showExtendModal = false;
  };

  setShowExtendModal = (showExtendModal: boolean) => {
    this.showExtendModal = showExtendModal;
  };

  setShowOffRampModal = (showOffRampModal: boolean) => {
    this.showOffRampModal = showOffRampModal;
  };

  setClickSource = (clickSource: ClickSource) => {
    this.clickSource = clickSource;
  };

  setShowLogOffModal = (showLogOffModal: boolean) => {
    this.showLogOffModal = showLogOffModal;
  };

  retrievePlansAndServicerHours = async () => {
    try {
      const { journeyId, profile } = store.profileStore;
      const { enableAgentDirectContact } = store.remoteConfigStore.featureFlags;
      const { allDebtSettlementPlans, debtManagementPlan, getCreditReport, calculateDebtManagementPlan } =
        store.callToActionsStore;

      // flags to check if servicer hours or credit response are missing
      const isMissingServicerHours = enableAgentDirectContact && this.isWithinServicerHours === undefined;

      const isMissingDebtSettlementPlans = journeyId === JourneyId.DEBT_SETTLEMENT && !allDebtSettlementPlans;

      const isMissingDebtManagementPlan = journeyId === JourneyId.DEBT_MANAGEMENT && !debtManagementPlan;

      const promises = [];

      if (isMissingDebtSettlementPlans || isMissingDebtManagementPlan || isMissingServicerHours) {
        this.setModalLoading(true);
      }

      if (isMissingDebtSettlementPlans) {
        promises.push(getCreditReport(profile!.id!));
      }

      if (isMissingDebtManagementPlan) {
        promises.push(calculateDebtManagementPlan(profile!.id!));
      }

      if (isMissingServicerHours) {
        promises.push(this.getServicerHours());
      }

      await Promise.all(promises);
      this.setModalLoading(false);
    } catch (error) {
      rollbar.error("Error retrieving plans and/or servicer hours", error as Error);
    } finally {
      this.setModalLoading(false);
    }
  };

  setDeclineAgentDirectContact = (declineAgentDirectContact: boolean) => {
    this.declineAgentDirectContact = declineAgentDirectContact;
  };
}
