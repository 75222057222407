import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    color: theme.palette.background.overlay,
    zIndex: "3",
  },

  offRampCard: {
    padding: "24px",
    borderRadius: "16px !important",
  },

  slideCard: {
    padding: "24px",
    zIndex: "99",
    position: "absolute",
    bottom: 0,
    right: 0,
    height: "auto",
    width: "100%",
    borderRadius: "0 !important",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      top: 0,
      right: 0,
      width: "23rem",
      minHeight: "400px",
    },
  },
}));
