import { Grid, InputAdornment, TextField, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Text } from '@components/common';
import { useStore } from '@stores';
import { StoredProfile } from '@types';
import { GTMNotUserClicked, maskPhoneNumber } from '@utils';

import { useStyles } from './VerificationSelection.styles.ts';

interface VerificationMethodProps {
  setPhone?: (type: string) => void;
}

export const VerificationSelection = observer(
  ({ setPhone }: VerificationMethodProps) => {
    const { profileStore } = useStore();
    const [phoneInput, setPhoneInput] = useState('');

    const { t } = useTranslation('verification_page');
    const navigate = useNavigate();

    const { tempUser } = profileStore;
    const classes = useStyles();
    const lastUserStored = window.localStorage.getItem('lastUsedProfile');

    const storedProfile = lastUserStored
      ? (JSON.parse(lastUserStored) as StoredProfile)
      : undefined;

    const clearPreviousUser = () => {
      GTMNotUserClicked();
      window.localStorage.removeItem('lastUsedProfile');
      navigate(0);
    };

    const matchWidth = useMediaQuery('(max-width:900px)');

    return (
      <>
        {tempUser.phone && (
          <Text
            fontSize={matchWidth ? 'medium' : 'large'}
            fontWeight="semiBold"
            lineHeight="medium"
            color="darkerGray"
            display={'inline-block'}
          >
            +1 {tempUser.phone}
          </Text>
        )}
        {storedProfile && (
          <>
            <Text
              fontSize={matchWidth ? 'small' : 'medium'}
              fontWeight="light"
              lineHeight="medium"
              className={classes.question}
            >
              {t('questionReturning')} {maskPhoneNumber(storedProfile.phone)}
            </Text>
            <button
              className={classes.resetUserButton}
              onClick={clearPreviousUser}
            >
              {t('notMe')} {storedProfile.firstName}?
            </button>
          </>
        )}
        {!tempUser.phone && !storedProfile && (
          <Grid
            container
            alignItems={'flex-start'}
            justifyContent={'center'}
            direction={'column'}
            mt={3}
          >
            <Grid item className={classes.formEntry}>
              <TextField
                data-cy="sign-in-phone-input"
                variant="outlined"
                autoComplete="off"
                label={t('phoneInput')}
                value={phoneInput}
                inputProps={{
                  inputMode: 'decimal',
                }}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.length > 10) {
                    return;
                  }
                  const regexValue = value.replace(/\D/g, '');
                  const groupNumber = regexValue.match(
                    /^(1|)?(\d{3})(\d{3})(\d{4})$/
                  );
                  if (groupNumber) {
                    const formattedNumber = [
                      '(',
                      groupNumber[2],
                      ') ',
                      groupNumber[3],
                      '-',
                      groupNumber[4],
                    ].join('');
                    profileStore.setVerificationMethodValue(formattedNumber);
                  }
                  setPhoneInput(regexValue);
                  if (value.length === 10) {
                    setPhone?.(regexValue);
                  }
                  profileStore.setUserPhoneInput(regexValue);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+1</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
);

export default VerificationSelection;
