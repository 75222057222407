import { Box } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

import { useStore } from "@stores";
import { BudgetType, BudgetTypeEnum, GaugeDataValue } from "@types";

import { ChartCenter } from "./ChartCenter";
import { ChartNavigation } from "./ChartNavigation";

interface ValidationFunc {
  (val: string): string;
}

export interface IChartBranded {
  data?: BudgetType<GaugeDataValue>;
  defaultValue?: string;
  valueValidation?: ValidationFunc;
}

const size = {
  width: 226,
  height: 226,
};

export const BudgetGauge = observer(({ data, ...props }: IChartBranded) => {
  const { budgetStore } = useStore();
  const { isEmptyData, currGaugeCategoryIdx } = budgetStore;

  const formatedData = useMemo(() => {
    if (!data) {
      return [];
    }
    if (currGaugeCategoryIdx === 0) {
      return [data[BudgetTypeEnum.INCOME], data[BudgetTypeEnum.DEBT], data[BudgetTypeEnum.EXPENSE]];
    }

    const budgetType = Object.values(BudgetTypeEnum)[currGaugeCategoryIdx - 1];
    switch (budgetType) {
      case BudgetTypeEnum.INCOME:
        return [data[budgetType]];
      default:
        return [data[BudgetTypeEnum.INCOME], data[budgetType]];
    }
  }, [data, currGaugeCategoryIdx]);

  return (
    <Box data-testid="pie-chart-box-container" sx={{ width: 226, marginBottom: 4 }}>
      <Box
        sx={{
          position: "absolute",
          width: size.width,
          height: size.height,
        }}
      >
        <svg viewBox="0 0 226 226 " fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="chart">
            <g id="outline">
              <mask id="path-1-inside-1_504_249" fill="white">
                <path d="M194.451 173.899C199.449 177.636 206.585 176.639 209.804 171.292C218.632 156.632 224.042 140.111 225.56 122.966C227.411 102.057 223.402 81.0463 213.982 62.2883C204.562 43.5303 190.103 27.7668 172.227 16.765C154.351 5.76322 133.763 -0.0419688 112.773 0.000228334C91.7824 0.0424255 71.2186 5.93034 53.3867 17.0039C35.5548 28.0775 21.1597 43.899 11.8151 62.6947C2.47059 81.4905 -1.454 102.518 0.481321 123.419C2.06827 140.557 7.54393 157.056 16.4312 171.681C19.6721 177.015 26.8118 177.983 31.795 174.226C36.7781 170.469 37.7021 163.412 34.5956 157.999C28.1623 146.79 24.1847 134.291 22.9851 121.335C21.4368 104.614 24.5765 87.7924 32.0521 72.7558C39.5277 57.7192 51.0438 45.062 65.3093 36.2031C79.5749 27.3443 96.0259 22.6339 112.818 22.6002C129.611 22.5664 146.08 27.2106 160.382 36.012C174.683 44.8134 186.249 57.4242 193.785 72.4306C201.321 87.4371 204.529 104.246 203.048 120.973C201.9 133.934 197.973 146.449 191.585 157.684C188.5 163.109 189.452 170.162 194.451 173.899Z" />
              </mask>
              <path
                d="M194.451 173.899C199.449 177.636 206.585 176.639 209.804 171.292C218.632 156.632 224.042 140.111 225.56 122.966C227.411 102.057 223.402 81.0463 213.982 62.2883C204.562 43.5303 190.103 27.7668 172.227 16.765C154.351 5.76322 133.763 -0.0419688 112.773 0.000228334C91.7824 0.0424255 71.2186 5.93034 53.3867 17.0039C35.5548 28.0775 21.1597 43.899 11.8151 62.6947C2.47059 81.4905 -1.454 102.518 0.481321 123.419C2.06827 140.557 7.54393 157.056 16.4312 171.681C19.6721 177.015 26.8118 177.983 31.795 174.226C36.7781 170.469 37.7021 163.412 34.5956 157.999C28.1623 146.79 24.1847 134.291 22.9851 121.335C21.4368 104.614 24.5765 87.7924 32.0521 72.7558C39.5277 57.7192 51.0438 45.062 65.3093 36.2031C79.5749 27.3443 96.0259 22.6339 112.818 22.6002C129.611 22.5664 146.08 27.2106 160.382 36.012C174.683 44.8134 186.249 57.4242 193.785 72.4306C201.321 87.4371 204.529 104.246 203.048 120.973C201.9 133.934 197.973 146.449 191.585 157.684C188.5 163.109 189.452 170.162 194.451 173.899Z"
                stroke="#5A5A5A"
                strokeWidth="2"
                mask="url(#path-1-inside-1_504_249)"
              />
            </g>
          </g>
        </svg>
      </Box>
      <PieChart
        {...size}
        data-testid="pie-chart-main-component"
        disableAxisListener
        sx={{
          text: {
            display: "none",
          },
        }}
        slotProps={{
          popper: {
            sx: {
              ["& .MuiChartsTooltip-root"]: {
                display: "none",
              },
            },
          },
        }}
        series={[
          {
            data: formatedData,
            innerRadius: 96,
            outerRadius: 108,
            paddingAngle: 1,
            cornerRadius: 15,
            startAngle: -124,
            endAngle: 124,
            cx: 108,
            cy: 108,
          },
        ]}
        {...props}
      />
      <ChartCenter data={data} />
      {!isEmptyData && <ChartNavigation />}
    </Box>
  );
});
