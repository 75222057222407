import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  group: {
    borderRadius: "unset !important",
    display: "flex",
    backgroundColor: theme.palette.common.white,
    justifyContent: "space-around",
    paddingTop: "0.5rem",
    [theme.breakpoints.up("md")]: {
      padding: "64px 1rem 1rem 0",
      flexDirection: "column",
      gap: "24px",
      width: "100%",
    },
  },

  sideBarWrapper: {
    width: "304px",
    height: "100%",
    padding: "1rem 0 1rem 1rem",
  },

  sidebarViewContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    borderRadius: "20px",
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.customShadows.clickable,
    alignItems: "flex-start",
  },

  svgContainer: {
    alignItems: "center",
    position: "relative",
    display: "flex",
    "& .svgElement": {
      background: "url(logoMobile.svg)",
      height: "111px",
      width: "393px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
      "& .svgElement": {
        background: "url(logo.svg)",
        height: "105px",
        width: "155px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "155px 155px",
        backgroundPosition: "center",
      },
    },
  },

  svgText: {
    paddingTop: "1rem",
    [theme.breakpoints.up("md")]: {
      display: "block",
      paddingTop: "24px",
    },
  },

  caption: {
    paddingTop: "8px",
  },

  svgBrandLogo: {
    position: "relative",
    display: "flex",
    "& .svgElement": {
      background: "url(dcom.svg)",
      height: "70px",
      width: "70px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "115px 115px",
      backgroundPosition: "center",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "auto",
      display: "flex",
      marginLeft: "-24px",
      "& .svgElement": {
        background: "ur(dcom).svg)",
        width: "89px",
        height: "44px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "89px 44px",
        backgroundPosition: "center",
      },
    },
  },
}));
