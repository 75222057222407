import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useSignInStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
  },

  recaptcha: {
    width: "100%",
    left: 0,
  },

  contentViewContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "32px 24px 24px 24px",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "24px",
      margin: "0 1rem",
      backgroundColor: "unset",
      width: "100% !important",
      justifyContent: "center",
    },
  },

  viewContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
      maxWidth: "459px",
    },
  },

  header: {
    marginBottom: "2rem",
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },

  signIn: {
    borderRadius: "16px",
    width: "100%",
    boxShadow: theme.customShadows.clickable,
    padding: "2rem",
    backgroundColor: theme.palette.common.white,
    marginBottom: "1rem",
    [theme.breakpoints.up("md")]: {
      marginBottom: "0",
    },
  },

  termsTextContainer: {
    "& div": {
      marginBottom: "1.5vh",
    },
    marginTop: "0",
    paddingTop: "2.75rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    boxShadow: "unset !important",
    borderRadius: "4px !important",
    textAlign: "center",
    backgroundColor: "unset !important",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  termsTextContent: {
    padding: `0 !important`,
  },

  messageContentBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingBottom: "50px",
  },

  alertBox: {
    paddingTop: "20px",
    borderRadius: "2px",
    width: "100%",
  },

  setButton: {
    width: "100%",
    marginTop: "1rem",
    [theme.breakpoints.up("md")]: {
      width: "10rem",
      padding: "0",
    },
  },
}));
