import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

interface CloseButtonProps {
  onClick: () => void;
  top?: number;
  right?: number;
}

export const CloseButton = (props: CloseButtonProps) => {
  const { onClick, top = 10, right = 10 } = props;

  return (
    <IconButton
      aria-label="close"
      sx={{
        position: 'absolute',
        top,
        right,
        color: 'black',
        backgroundColor: 'white',
        zIndex: 100,
      }}
      onClick={onClick}
    >
      <CloseIcon />
    </IconButton>
  );
};
