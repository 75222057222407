import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  isSelected: boolean;
  pendingEnrollment: boolean;
  dropShadow: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "8px",
    outline: (props: Props) =>
      `${props.isSelected && !props.pendingEnrollment ? "2px" : "0px"} solid ${theme.palette.brandHoverAccent.main}`,
    boxSizing: "border-box",
    boxShadow: (props: Props) => (props.dropShadow ? theme.customShadows.clickable : "unset"),
    overflow: "hidden",
    "&:hover": {
      cursor: (props: Props) => `${props.pendingEnrollment ? "" : "pointer"}`,
      backgroundColor: (props: Props) => `${props.pendingEnrollment ? "" : "theme.palette.brandGray.lighter"}`,
    },
  },
  boldText: {
    fontWeight: 800,
    color: theme.palette.brandDarkBlue.other,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "12px 16px",
    gap: "1rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0",
    margin: 0,
  },
  pending: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "8px 10px",
    backgroundColor: theme.palette.brandDarkBlue.other,
  },
  pendingDot: {
    display: "block",
    backgroundColor: theme.palette.brandYellow.main,
    height: "10px",
    width: "10px",
    borderRadius: "50%",
  },
  subHeading: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  planCardContentWrapper: {
    padding: 16,
  },
  planCardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 24,
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "40px",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    width: "100%",
  },
  borderBottom: {
    borderBottom: "1px solid rgba(203, 209, 215, 1)",
  },
}));
