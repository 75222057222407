import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  termsAndPolicyContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "0 auto",
    gap: "8px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  button: {
    "&.MuiButton-root": {
      textTransform: "capitalize",
      width: "initial",
      lineHeight: "initial",
      paddingLeft: "0",
      fontSize: "12px",
      color: "#7d7d7d",
      fontWeight: "600",
    },
  },
}));
