import { SvgIcon } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import profileIcon from '@assets/icons/profile.svg?react';
import { MiniDashItem, ProgressBar, Text } from '@components/common';
import { useStore } from '@stores';
import { CallToActionIdEnum, JourneyId } from '@types';
import { GTMDynamicEvent } from '@utils';

import { useStyles } from './Profile.styles';

export const Profile: React.FC = observer(() => {
  const { profileStore, modalStore, commonStore } = useStore();
  const { profile } = profileStore;
  const { openModal } = modalStore;
  const { t } = useTranslation('profile_page');
  const classes = useStyles();
  const [helpStatus, setHelpStatus] = useState(false);

  useEffect(() => {
    if (commonStore.getHelpPageView() === 'helpA') {
      setHelpStatus(true);
    } else {
      setHelpStatus(false);
    }
  }, [commonStore]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {!helpStatus && (
          <div className={`${classes.content} ${classes.marginBottom}`}>
            <Text
              fontSize="large"
              fontWeight="semiBold"
              lineHeight="medium"
              color="accent"
              data-cy="profile-tracker-percentage-complete"
            >
              {profile?.profilePercentageComplete &&
                t('complete', {
                  percentage: profile.profilePercentageComplete,
                })}
            </Text>
            <ProgressBar
              value={profileStore.profile?.profilePercentageComplete || 0}
            />
          </div>
        )}
        <div className={classes.contentWrapper}>
          <div className={classes.iconContainer}>
            <div>
              <SvgIcon
                component={profileIcon}
                className={classes.icon}
                inheritViewBox
                data-cy="profile-avatar"
              />
            </div>
            <div className={classes.nameContainer}>
              <Text
                className={classes.textWrap}
                fontSize="xlarge"
                fontWeight="semiBold"
                lineHeight="medium"
                color="darkerGray"
                data-cy="profile-avatar-firstname"
              >
                {profile?.firstName}
              </Text>
              <Text
                className={classes.textWrap}
                fontSize="xlarge"
                fontWeight="semiBold"
                lineHeight="medium"
                color="darkerGray"
                data-cy="profile-avatar-lastname"
              >
                {profile?.lastName}
              </Text>
            </div>
          </div>
          <div className={classes.miniDashContainer}>
            {profileStore.journeyId !== JourneyId.DEBT_MANAGEMENT && (
              <MiniDashItem
                data-cy="profile-dashitem-auth-cred-pull"
                variant="profile"
                title={t('authCreditPullCTA')}
                summary={
                  helpStatus
                    ? t('negativeAuthCreditPullSummary')
                    : t('authCreditPullSummary')
                }
                taskCompleted={profile?.completedCallToActionIds.includes(
                  CallToActionIdEnum.AUTHORIZE_CREDIT_PULL
                )}
                disabled={
                  profile?.completedCallToActionIds.includes(
                    CallToActionIdEnum.AUTHORIZE_CREDIT_PULL
                  ) || helpStatus
                }
                needHelp={helpStatus}
                onClick={() => {
                  GTMDynamicEvent('credit_authorization_profile_click');
                  openModal('MODULE_AUTH_CREDIT');
                }}
              />
            )}
            <MiniDashItem
              data-cy="profile-dashitem-verify-phone"
              variant="profile"
              title={
                profile?.verification?.phone
                  ? t('phoneVerifyComplete')
                  : t('phoneVerifyCTA')
              }
              summary={profile ? profile.phone : 'No Phone Number Available'}
              route=""
              taskCompleted={profile?.verification?.phone ? true : false}
              disabled={profile?.verification?.phone ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
/////////////////////////////////////////////////////////////////////////////////////////////////////////
//TODO: determine regex expression to adjust font sizes and when to word wrap - first/last name and email
