import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: "0.5rem",
    overflow: "hidden",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      filter: "brightness(110%)",
      transition: "1s",
      "& $titleText": {
        color: theme.palette.brandGreen.darker,
        transition: ".4s",
      },
    },
  },
  titleText: {
    color: theme.palette.brandBlue.darker,
  },
  imageContainer: {
    height: "100px",
    overflow: "hidden",
    "& img": {
      objectFit: "cover",
      height: "100px",
      width: "100%",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    padding: "1rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "0.5rem",
    position: "absolute",
    bottom: "5px",
    right: "10px",
  },
  arrowIcon: {
    color: theme.palette.brandBlue.darker,
  },

  carImage: {
    objectPosition: "50% 20%",
  },
  savingsImage: {
    objectPosition: "50% 90%",
  },
  creditCardImage: {
    objectPosition: "50% 50%",
  },
  debtToIncomeImage: {
    objectPosition: "50% 80%",
  },
  mortgageImage: {
    objectPosition: "50% 40%",
  },
  incomeTaxImage: {
    objectPosition: "50% 30%",
  },
}));
