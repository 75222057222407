import { makeAutoObservable, reaction } from "mobx";

import { store } from "./store";
import i18n from "../../i18n.config";
import { rollbar } from "../../rollbarConfig";
import { AxiosAgent } from "../services";
import { Profile, VTPageTitles } from "../types";

export default class ChatStore {
  enabled = false;
  initialized = false;
  hasActiveChat = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.enabled,
      (enabled: boolean) => {
        if (enabled) this.init();
      },
    );
  }

  private getChatStatusFromStorage = (): { enabled: boolean } => {
    const chatStatus: { enabled: boolean } | null = JSON.parse(sessionStorage.getItem("chat-status") as string);

    if (chatStatus) {
      return chatStatus;
    }

    return { enabled: false };
  };

  private setInitialized = (initialized: boolean) => {
    this.initialized = initialized;
  };

  initializeChat = (config: Partial<Profile>) => {
    if (!this.initialized) {
      this.setConfig(config);
      this.checkIfEnabled();
      this.setInitialized(true);
    }
  };

  async checkIfEnabled() {
    try {
      const featureFlagEnabled = store.remoteConfigStore.featureFlags.enableLiveGuidedChat;

      if (featureFlagEnabled && i18n.language === "en") {
        // check if chat was already initialized if user is not authenticated (ex: if they refreshed on sign-in page)
        const chatStatusFromStorage = this.getChatStatusFromStorage();
        if (!store.sessionStore.authUser && chatStatusFromStorage.enabled === true) {
          this.enabled = true;
          return;
        } else {
          // otherwise, get latest chat enabled status from the api
          const status = await AxiosAgent.Chat.getStatus();
          if (status.enabled) {
            this.enabled = true;
            sessionStorage.setItem("chat-status", JSON.stringify({ enabled: true }));
          }
        }
      }
    } catch (error) {
      rollbar.error("Error retrieving chat status", error as Error);
    }
  }

  private setConfig({ firstName, lastName, engagementId, id, lastCompletedPage }: Partial<Profile>) {
    window.fcWidgetMessengerConfig = {
      config: {
        disableNotifications: true,
        headerProperty: {
          hideChatButton: true,
        },
      },
      externalId: engagementId,
      firstName: firstName,
      lastName: lastName,
      meta: {
        cf_last_page: lastCompletedPage,
        cf_profile_id: id,
        cf_engagement_id: engagementId,
      },
    };
  }

  private init() {
    if (!document.getElementById("freshchatScript")) {
      const freshchatScript = document.createElement("script");
      freshchatScript.src = "//fw-cdn.com/11873153/4458200.js";
      freshchatScript.setAttribute("chat", "true");
      freshchatScript.id = "freshchatScript";
      document.head.appendChild(freshchatScript);
    }
  }

  updateLastPage(pageTitle: VTPageTitles) {
    if (window.fcWidget) {
      window.fcWidget.user.setProperties({ cf_last_page: pageTitle });
    }
  }

  updateProfileId(id: string) {
    if (window.fcWidget) {
      window.fcWidget.user.setProperties({ cf_profile_id: id });
    }
  }

  launchChat = () => {
    if (window.fcWidget) {
      window.fcWidget.open();
      window.fcWidget.on("widget:closed", () => {
        if (!this.hasActiveChat) {
          this.hasActiveChat = true;
        }
      });
    } else {
      rollbar.error("User attempted to launch Freshchat widget but it was not loaded properly.");
    }
  };
}
