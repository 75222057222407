export const errorCodes = [
  "auth/invalid-verification-code",
  "auth/invalid-verification-id",
  "auth/invalid-phone-number",
  "auth/too-many-requests",
  "auth/invalid-email",
  "internalAuthError",
  "auth/invalid-action-code",
  "api/no-profile-found",
  "api/profile-exists-with-a-different-auth-method",
  "api/internal-error",
  "auth/recaptcha-render-error",
] as const;

type ErrorId = (typeof errorCodes)[number];

export const AuthErrorMessages: Record<ErrorId, string> = {
  "auth/invalid-verification-code": "invalid-verification-code",
  "auth/invalid-verification-id": "invalid-verification-id",
  "auth/invalid-phone-number": "invalid-phone-number",
  "auth/too-many-requests": "too-many-requests",
  "auth/invalid-email": "invalid-email",
  "auth/invalid-action-code": "invalid-email-verification-link",
  internalAuthError: "internalAuthError",
  "api/no-profile-found": "no-profile-found",
  "api/profile-exists-with-a-different-auth-method": "profile-exists-with-a-different-auth-method",
  "api/internal-error": "internalAuthError",
  "auth/recaptcha-render-error": "recaptcha-render-error",
} as const;

export const getErrorMessage = (errorCode: string) => {
  return AuthErrorMessages[errorCode as ErrorId] || (AuthErrorMessages.internalAuthError as string);
};
