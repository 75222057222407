/**
 * Font Line-Heigths being used by the design.
 * Update with any values as needed.
 *
 * xxxsmall = 16px //
 * xxsmall = 18px //
 * xsmall = 20px //
 * small = 22px //
 * medium = 24px //
 * large = 28px //
 * xlarge = 30px //
 * xxlarge = 32px //
 * xxxlarge = 34px //
 * xxxxlarge = 36px //
 * xxxxxlarge = 38px
 */
export const fontLineHeights = {
  xxxsmall: "16px !important",
  xxsmall: "18px !important",
  xsmall: "20px !important",
  small: "22px !important",
  medium: "24px !important",
  large: "28px !important",
  xlarge: "30px !important",
  xxlarge: "32px !important",
  xxxlarge: "34px !important",
  xxxxlarge: "36px !important",
  xxxxxlarge: "38px !important",
};
