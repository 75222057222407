import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonBranded, Text } from '@components/common';
import { useStore } from '@stores';
import { CallToAction } from '@types';
import { CTAModuleID, CTAModulesEnum } from '@utils';

import { useStyles } from './CallToActionCard.styles';

interface CallToActionCardProps {
  data: CallToAction;
}
export const CallToActionCard = observer(({ data }: CallToActionCardProps) => {
  const { id } = data;
  const { t } = useTranslation('call_to_action');
  const summary = t(`callToActions.${id}.summary`, {
    returnObjects: true,
  }) as string[];

  const classes = useStyles();
  const { modalStore } = useStore();
  const [gtmEvent, setGtmEvent] = useState<string>('');

  useEffect(() => {
    switch (id) {
      case 'authorize-credit-pull':
        setGtmEvent('credit_authorization_cta');
        break;
      case 'review-debt-eligibility':
        setGtmEvent('debt_review_cta');
        break;
      case 'select-plan':
        setGtmEvent('select_plan_cta');
        break;
      default:
        break;
    }
  }, [id]);

  const launchModal = () => {
    modalStore.openModal(CTAModulesEnum[id as CTAModuleID]);
  };

  const renderSummary = () => {
    if (summary.length > 1) {
      return (
        <ul data-cy="cta-summary">
          {summary.map((item, idx) => {
            return <li key={idx}>{item}</li>;
          })}
        </ul>
      );
    } else {
      return (
        <Text
          data-cy="cta-summary"
          fontSize="medium"
          fontWeight="regular"
          lineHeight="medium"
        >
          {summary[0]}
        </Text>
      );
    }
  };

  return (
    <div className={classes.container}>
      <img src={data.imageUrl} alt={data.name} />
      <div className={classes.content}>
        <Text
          fontSize="medium"
          fontWeight="semiBold"
          lineHeight="medium"
          color="accent"
        >
          {t('nextStep')}
        </Text>
        <Text
          data-cy="cta-header"
          fontSize="xxlarge"
          fontWeight="bold"
          lineHeight="medium"
        >
          {t(`callToActions.${id}.name`)}
        </Text>
        {renderSummary()}
        <div className={classes.setButtonWidth}>
          <ButtonBranded
            onClick={launchModal}
            variant="primary"
            className={`${classes.setButtonPadding} ${classes.setButtonFont}`}
            data-cy="cta-button"
            gtmEvent={gtmEvent}
          >
            {t(`callToActions.${id}.buttonLabel`)}
          </ButtonBranded>
        </div>
      </div>
    </div>
  );
});
