import { PaletteOptions } from "@mui/material";

export const palette: PaletteOptions = {
  brandDarkBlue: {
    main: "#0711E8",
    dark: "#2A353D",
    other: "#28333E",
  },
  brandBlue: {
    main: "#44accd",
    dark: "#2f90af",
    darker: "#246f87",
    light: "#6cbed8",
    lighter: "#94d0e3",
  },
  brandLightBlue: {
    main: "#6FEEDB",
  },
  brandTeal: {
    main: "#44accd",
  },
  brandMagenta: {
    light: "#F1AAA9",
    main: "#DA30EA",
  },
  brandYellow: {
    main: "#EFE34C",
  },
  brandGray: {
    other: "#F6F7F8",
    lighter: "#F6F6F6",
    light: "#E8E8E8",
    main: "#7d7d7d",
    dark: "#5a5a5a",
    darker: "#464646",
  },
  background: {
    default: "#F6F7F8",
    overlay: "rgba(40, 51, 62, 0.75)",
  },
  brandAccent: {
    main: "#CA16DA",
  },
  brandHover: {
    light: "#8fcf8f",
    main: "#6da852",
    dark: "#416331",
  },
  brandHoverAccent: {
    main: "#D545E1",
  },
  buttonDisabledBKG: {
    main: "#A2ADB9",
  },
  buttonDisabledText: {
    main: "#424D57",
  },
  brandPink: {
    main: "#ca16da",
  },
  brandGreen: {
    main: "#6da852",
    dark: "#578641",
    darker: "#416331",
    light: "#8fcf8f",
    lighter: "#f8fff5",
  },
  brandRed: {
    main: "#e71915",
    dark: "#b81411",
    darker: "#8a0f0d",
    light: "#ee4541",
    lighter: "#f27270",
  },
};
