import { useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text, SelectedPlanDetailsCard } from "@components/common";
import { useStore } from "@stores";
import { theme } from "@styles";

import { useStyles } from "./HelpViewC.styles";

export const HelpViewC = observer(() => {
  const { t } = useTranslation("call_for_help_pageC");
  const classes = useStyles();
  const { profileStore } = useStore();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div>
      <div className={classes.helpViewBackground}>
        <div className={classes.mainContainer}>
          <div className={classes.helpContent}>
            <div className={classes.header}>
              {!isDesktop ? (
                <>
                  <Text
                    fontSize="xxxxlarge"
                    fontWeight="semiBold"
                    lineHeight="xxxxxlarge"
                    color="white"
                    className={classes.newLine}
                  >
                    {t("header")}
                  </Text>
                  <div className={classes.slogan}>
                    <Text
                      fontSize="xlarge"
                      fontWeight="light"
                      lineHeight="medium"
                      color="white"
                      className={classes.newLine}
                    >
                      {t("subHeader", {
                        servicer: profileStore.profile?.servicer?.displayName,
                      })}
                    </Text>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div>
                    <Text fontSize="xxxlarge" fontWeight="semiBold" lineHeight="xxsmall" color="white">
                      {t("header")}
                    </Text>
                  </div>
                  <div className={classes.slogan}>
                    <Text
                      fontSize="xlarge"
                      fontWeight="light"
                      lineHeight="medium"
                      color="white"
                      className={classes.newLine}
                    >
                      {t("subHeader", {
                        servicer: profileStore.profile?.servicer?.displayName,
                      })}
                    </Text>
                  </div>
                </div>
              )}
            </div>
            <div className={classes.container}>
              <div className={classes.content}>
                <SelectedPlanDetailsCard
                  data-cy="plan-selected-card"
                  pendingEnrollment
                  dropShadow={false}
                  description={t("planDescription")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
