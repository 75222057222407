import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common';
import { useStore } from '@stores';
import { formatNumberToCurrencyString } from '@utils';

export const AvailableCash = observer(() => {
  const { t } = useTranslation('budget_tool');
  const {
    budgetStore: { availableCash },
  } = useStore();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      sx={{ whiteSpace: 'nowrap' }}
      justifyContent="center"
    >
      <Text fontSize="medium" noWrap fontWeight="semiBold">
        {t('availableCash')}
      </Text>
      {availableCash < 0 && <WarningAmberOutlinedIcon color="error" />}
      <Text fontSize="xxlarge" fontWeight="bold" data-cy="available-cash">
        ${formatNumberToCurrencyString(availableCash)}/
        {t('frequencyLabel.Abb.monthly')}.
      </Text>
    </Box>
  );
});
