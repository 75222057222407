import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ButtonBranded, Text } from '@components/common';
import { SlideModal } from '@components/layout';
import { useStore } from '@stores';
import {
  GTMSessionNotExtended,
  GTMUserLoggedOut,
  useSessionTimer,
} from '@utils';

import { useStyles } from './AbsoluteTimerModal.styles';
import { auth } from '../../../../../firebase.config';

interface AbsoluteTimerModalProps {
  showModal: boolean;
}

export const AbsoluteTimerModal: React.FC<AbsoluteTimerModalProps> = observer(
  ({ showModal }: AbsoluteTimerModalProps) => {
    const { t } = useTranslation('absolute_timer');
    const classes = useStyles();
    const { extendSession } = useSessionTimer();
    const { commonStore, sessionStore } = useStore();

    return (
      <SlideModal show={showModal}>
        <div className={classes.container}>
          <Text
            fontSize="xlarge"
            fontWeight="semiBold"
            color="darkerGray"
            lineHeight="medium"
          >
            {t('header')}
          </Text>
          <Text fontSize="small" color="darkerGray" lineHeight="xsmall">
            {t('subHeader')}
          </Text>
          <div className={classes.timer}>
            <Text fontSize="xxxxxlarge" color="darkerGray" lineHeight="medium">
              {sessionStore.timeLeft}
            </Text>
          </div>
          <div className={classes.buttonContainer}>
            <ButtonBranded
              className={classes.button}
              label={t('extendButton')}
              onClick={extendSession}
            />
            <ButtonBranded
              className={classes.button}
              variant="secondary"
              data-cy="timeout-logout-button"
              label={t('logOutButton')}
              onClick={async () => {
                GTMSessionNotExtended();
                GTMUserLoggedOut();
                auth.signOut().then(() => {
                  sessionStore.userIsLoggingOut();
                  commonStore.clearStores();
                });
              }}
            />
          </div>
        </div>
      </SlideModal>
    );
  }
);

export default AbsoluteTimerModal;
