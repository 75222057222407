import { observer } from 'mobx-react-lite';

import { Modal } from '@components/layout';
import { ModalForm } from '@components/layout/Form';
import { useStore } from '@stores';
import { useFormActions } from '@utils';

export const ModuleProcessor = observer(() => {
  const {
    modalStore: { module, modalLoading },
  } = useStore();

  const actions = useFormActions(module.name);

  return (
    <Modal>
      <ModalForm
        name={module.name ?? ''}
        modalLoading={modalLoading}
        methods={actions.methods}
        onSubmit={actions.submitHandler}
        nextUserCtaAfterCreditPull={actions.nextUserCtaAfterCreditPull}
      >
        {module.component}
      </ModalForm>
    </Modal>
  );
});
