import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CreditAuthForm } from '@types';

type MaskedState = {
  actualSsn: string;
  maskedSsn: string;
};

export interface ISsnInput {
  control: Control<CreditAuthForm>;
}

export const SsnInput = ({ control }: ISsnInput) => {
  const { t } = useTranslation('credit_authorization');
  const [state, setState] = useState<MaskedState>({
    actualSsn: '',
    maskedSsn: '',
  });

  const [showPassword, setShowPassword] = useState(false);

  const formatSSN = (value: string, showLast4: boolean, actualSsn?: string) => {
    if (value.length < 3) {
      return value;
    }

    if (value.length === 3) {
      return value + '-';
    }

    if (value.length < 5) {
      return value.slice(0, 3) + '-' + value.slice(3, 5);
    }

    if (value.length === 5) {
      return value.slice(0, 3) + '-' + value.slice(3, 5) + '-';
    }

    if (showLast4) {
      return (
        value.slice(0, 3) +
        '-' +
        value.slice(3, 5) +
        '-' +
        actualSsn?.substring(5)
      );
    } else {
      return (
        value.slice(0, 3) + '-' + value.slice(3, 5) + '-' + value.slice(5, 9)
      );
    }
  };

  const starredMask = (actual: string, showLast4 = true) => {
    const masked = actual.replace(/\d/g, '*');
    return formatSSN(masked, showLast4, actual);
  };

  const handleBlur = (onChange: (arg0: string) => void) => {
    if (state.actualSsn.length < 9) {
      setState({
        actualSsn: '',
        maskedSsn: '',
      });
      onChange('');
    }
  };

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    onChange: (...event: string[]) => void
  ) => {
    if (state.actualSsn.length > 9) {
      return;
    }

    const prevValue = state.maskedSsn;
    const newValue = event.target.value;
    let actual = state.actualSsn;

    if (newValue.length > 11) {
      return;
    }

    if (newValue.length > prevValue.length) {
      const newChar = newValue.slice(-1);
      if (!newChar.match(/[0-9]/g)) {
        return;
      }
      actual = `${actual}${newChar}`;
    } else {
      const charsRemovedCount = prevValue.length - newValue.length;
      actual = actual.substring(0, actual.length - charsRemovedCount);
    }

    onChange(actual);
    setState({
      actualSsn: actual,
      maskedSsn: starredMask(actual, false),
    });
  };

  return (
    <Controller
      control={control}
      name="ssn"
      render={({ field: { onChange }, formState: { errors } }) => (
        <FormControl sx={{ flex: 1 }}>
          <InputLabel htmlFor="outlined-adornment-ssn" error={!!errors.ssn}>
            {t('form.ssnLabel')}
          </InputLabel>
          <OutlinedInput
            autoComplete="off"
            id="outlined-adornment-ssn"
            onPaste={(e) => e.preventDefault()}
            name="ssn"
            type="text"
            label={t('form.ssnLabel')}
            inputProps={{
              inputMode: 'decimal',
              maxLength: 11,
              minLength: 11,
            }}
            value={
              showPassword ? formatSSN(state.actualSsn, false) : state.maskedSsn
            }
            onChange={(event) => handleChange(event, onChange)}
            onBlur={() => handleBlur(onChange)}
            error={!!errors.ssn}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id="my-helper-text" error={!!errors.ssn}>
            {errors.ssn?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
