/**
 * Font Sizes being used by the design.
 * Update with any values as needed.
 *
 * xxsmall = 10px //
 * xsmall = 12px //
 * small = 14px //
 * medium = 16px //
 * large = 18px //
 * xlarge = 20px //
 * xxlarge = 24px //
 * xxxlarge = 30px //
 * xxxxlarge = 32px //
 * xxxxxlarge = 48px
 */
export const fontSizes = {
  xxsmall: "10px !important",
  xsmall: "12px !important",
  small: "14px !important",
  medium: "16px !important",
  large: "18px !important",
  xlarge: "20px !important",
  xxlarge: "24px !important",
  xxxlarge: "30px !important",
  xxxxlarge: "32px !important",
  xxxxxlarge: "48px !important",
};
