import { Box, BoxProps } from '@mui/material';
import React from 'react';

import { Text, TextProps } from '@components/common';
import { theme } from '@styles';

interface ModuleProps extends BoxProps {
  children: React.ReactNode;
}

interface ModuleTextProps extends TextProps {
  children: React.ReactNode;
}

interface ModuleComponent extends React.FC<ModuleProps> {
  Sidebar: React.FC<ModuleProps>;
  Content: React.FC<ModuleProps>;
  Header: React.FC<ModuleTextProps>;
  SubHeader: React.FC<ModuleTextProps>;
  Description: React.FC<ModuleTextProps>;
}

export const Module: ModuleComponent = ({ children, ...props }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      flex="1 0 0"
      justifyContent="space-between"
      gap={4}
      sx={{
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          padding: 1,
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const Sidebar: React.FC<ModuleProps> = ({ children, ...props }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexBasis={'35%'}
      gap={2}
      sx={{
        [theme.breakpoints.down('md')]: {
          width: '100%',
          gap: 1,
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const Content: React.FC<ModuleProps> = ({ children, ...props }) => {
  return (
    <Box
      display="flex"
      flex={1}
      gap={3}
      flexDirection="column"
      sx={{
        [theme.breakpoints.down('md')]: {
          width: '100%',
          gap: 2,
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const Header: React.FC<ModuleTextProps> = ({ children, ...props }) => {
  return (
    <Text
      fontSize={'xxxxlarge'}
      fontWeight={'bold'}
      lineHeight={'xxxxxlarge'}
      {...props}
    >
      {children}
    </Text>
  );
};

const SubHeader: React.FC<ModuleTextProps> = ({ children, ...props }) => {
  return (
    <Text
      fontSize={'medium'}
      fontWeight={'semiBold'}
      lineHeight={'medium'}
      color={'accent'}
      {...props}
    >
      {children}
    </Text>
  );
};

const Description: React.FC<ModuleTextProps> = ({ children, ...props }) => {
  return (
    <Text
      fontSize={'small'}
      fontWeight={'light'}
      lineHeight={'xxsmall'}
      {...props}
    >
      {children}
    </Text>
  );
};

Module.Sidebar = Sidebar;
Module.Content = Content;
Module.Header = Header;
Module.SubHeader = SubHeader;
Module.Description = Description;
