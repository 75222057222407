import { autorun } from 'mobx';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@components/common';
import { useStore } from '@stores';
import { theme } from '@styles';

export const TotalDebt: FC = () => {
  const { t: drt } = useTranslation('debt_review');
  const [totalDebt, setTotalDebt] = useState<string>();
  const { callToActionsStore } = useStore();

  useEffect(() => {
    autorun(() => {
      setTotalDebt(callToActionsStore.getTotalDebt());
    });
  });

  const fontDarkGray = {
    color: `${theme.palette.brandGray.darker} !important`,
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <Text
          fontSize="medium"
          fontWeight="semiBold"
          lineHeight="medium"
          classes={fontDarkGray}
          data-cy="totalDebt"
        >
          {drt('totalDebt')}
        </Text>
      </div>
      <div>
        <Text
          data-cy="review-debt-total-debt-amount"
          fontSize="xxlarge"
          fontWeight="semiBold"
          lineHeight="xxlarge"
          classes={fontDarkGray}
        >
          {`${totalDebt}`}
        </Text>
      </div>
    </div>
  );
};
