/* eslint-disable react-hooks/exhaustive-deps */

import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonBranded } from '@components/common';
import { SlideModal } from '@components/layout';
import { useStore } from '@stores';
import { theme } from '@styles';

interface LogOutModalProps {
  handleLogout: () => void;
}

export const LogOutModal: React.FC<LogOutModalProps> = observer(
  ({ handleLogout }: LogOutModalProps) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const { t } = useTranslation('log_out');
    const {
      modalStore: { showLogOffModal, setShowLogOffModal },
    } = useStore();

    const handleClickAway = () => {
      setShowLogOffModal(false);
    };

    useEffect(() => {
      if (isDesktop) {
        handleClickAway();
        setShowLogOffModal(false);
      }
    }, [isDesktop, showLogOffModal]);

    return (
      <SlideModal
        show={showLogOffModal && !isDesktop}
        allowClickAway={true}
        setShow={handleClickAway}
      >
        <>
          <ButtonBranded
            data-cy="logout"
            type="submit"
            label={t('logout_button_label')}
            variant="secondary"
            onClick={handleLogout}
          />
        </>
      </SlideModal>
    );
  }
);

export default LogOutModal;
