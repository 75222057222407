import { useRollbar } from '@rollbar/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { UseFormReturn, SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonBranded } from '@components/common';
import { ScrollIndicator } from '@components/common/ScrollIndicator';
import { ConfirmationModalView } from '@components/layout/Footer/ConfirmationModalView.tsx';
import { useStyles } from '@components/layout/Form/form.styles.ts';
import { InfoShareAgreementModal } from '@components/views';
import { useStore } from '@stores';
import { theme } from '@styles';
import {
  CreditAuthForm,
  FORM_TYPES,
  ServicerIdEnum,
  VTPageTitlesEnum,
} from '@types';
import {
  CTAModulesEnum,
  GTMDynamicEvent,
  GTMOffRampClicked,
  GTMOffRampConfirmed,
  handlePercentageAndPageCompleted,
  ProfileSubStatusEnum,
} from '@utils';

import { FooterLegalInfo } from '../../common/FooterLegalInfo';

interface IFooterProps {
  methods: UseFormReturn<FORM_TYPES>;
  onSubmit: SubmitHandler<FORM_TYPES>;
  name: string;
}

export const CreditAuthorizationFooter = observer(
  ({ methods, onSubmit, name }: IFooterProps) => {
    const rollbar = useRollbar();
    const { watch } = useFormContext<CreditAuthForm>();
    const [servicerLoading, setServicerLoading] = useState<boolean>(false);
    const { t } = useTranslation('credit_authorization');
    const classes = useStyles({ name });
    const [showServicerModal, setShowServicerModal] = useState(false);

    const {
      modalStore: {
        openModal,
        showOffRampModal,
        setShowOffRampModal,
        setClickSource,
      },
      profileStore,
      remoteConfigStore: { featureFlags },
    } = useStore();

    const address = watch('address');

    const handleOffRamp = () => {
      GTMOffRampClicked('Credit-Auth');
      setClickSource('footer');
      setShowOffRampModal(!showOffRampModal);
    };

    const handleSubmit = async () => {
      GTMDynamicEvent('credit_authorization_click');
      await handlePercentageAndPageCompleted(
        featureFlags.enableBudgetTool,
        VTPageTitlesEnum.SERVICER_CONSENT,
        profileStore
      );

      try {
        setServicerLoading(true);

        if (address) {
          const state = address.state;
          await profileStore.getServicer(state);

          if (
            profileStore.profile?.servicer?.id === ServicerIdEnum.NO_SERVICER
          ) {
            profileStore.updateEnrollmentStatus(
              ProfileSubStatusEnum.IDA_DECLINED_CREDIT
            );
            openModal(CTAModulesEnum['call-for-help']);
          } else {
            setShowServicerModal(true);
          }
        }
      } catch (error) {
        rollbar.error('Error in CreditAuthorizationFooter', error as Error);
      } finally {
        setServicerLoading(false);
      }
    };

    return (
      <>
        <ConfirmationModalView
          isOpen={showOffRampModal}
          handlePrimary={() => {
            GTMOffRampConfirmed('Credit-Auth');
            setShowOffRampModal(false);
            profileStore.updateEnrollmentStatus(
              ProfileSubStatusEnum.IDA_DECLINED_CREDIT
            );
            openModal(CTAModulesEnum['call-for-help']);
          }}
          handleSecondary={() => {
            setShowOffRampModal(false);
          }}
          handleCancel={() => {
            setShowOffRampModal(false);
          }}
          name={'credit-authorization'}
        />
        <div className={classes.footer}>
          <div className={classes.footerButtonContainer}>
            {featureFlags.enableCreditAuthOffRamp && (
              <div className={classes.callMeButton}>
                <ButtonBranded
                  data-cy="callMeButton"
                  variant="textonly"
                  fontSize={theme.fontSizes.medium}
                  fontWeight={theme.fontWeights.medium}
                  lineHeight={theme.fontLineHeights.xxxsmall}
                  className={classes.debtReviewCallButton}
                  onClick={handleOffRamp}
                >
                  {t('callMe')}
                </ButtonBranded>
              </div>
            )}
            <div className={classes.nextActionButton}>
              <ButtonBranded
                loading={servicerLoading}
                data-cy="credit-authorization-button-agree"
                variant={'primary'}
                disabled={
                  !!Object.keys(methods.formState.errors).length ||
                  !methods.formState.isValid ||
                  (servicerLoading && showServicerModal)
                }
                onClick={handleSubmit}
                type={'submit'}
                name={'CreditAuthSubmit'}
              >
                {t('agreeAuth')}
              </ButtonBranded>
            </div>
          </div>
          <FooterLegalInfo />
          <ScrollIndicator scrollDivId={'form-container'} />
        </div>
        <InfoShareAgreementModal
          showModal={showServicerModal}
          methods={methods}
          setShowModal={setShowServicerModal}
          onSubmit={onSubmit}
        />
      </>
    );
  }
);
