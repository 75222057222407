import { Box, useMediaQuery, Drawer } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ButtonBranded, ESign } from "@components/common";
import { FooterLegalInfo } from "@components/common";
import { ConfirmationModalView } from "@components/layout/Footer/ConfirmationModalView.tsx";
import { useStyles } from "@components/layout/Form/form.styles.ts";
import { useStore } from "@stores";
import { theme } from "@styles";
import { FORM_TYPES, VTPageTitlesEnum } from "@types";
import {
  CTAModulesEnum,
  getServicerPhoneNumber,
  GTMAgentConnectAttempted,
  GTMUserCallNowClicked,
  handlePercentageAndPageCompleted,
  ProfileSubStatusEnum,
  GTMPlanSubmitted,
  GTMOffRampConfirmed,
} from "@utils";

import { rollbar } from "../../../../rollbarConfig.ts";
import { NextStep } from "../SelectPlan/index.ts";

interface ISelectPlanFooter {
  methods: UseFormReturn<FORM_TYPES>;
  onSubmit: SubmitHandler<FORM_TYPES>;
  name: string;
}

export const SelectPlanFooter = observer(({ methods, onSubmit, name }: ISelectPlanFooter) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation("plan_option");
  const classes = useStyles({ name });
  const {
    profileStore,
    modalStore: {
      closeModal,
      openModal,
      showOffRampModal,
      setShowOffRampModal,
      setDeclineAgentDirectContact,
      isWithinServicerHours,
      showExtendModal,
    },
    remoteConfigStore: { featureFlags, redirectHomePageDelay },
  } = useStore();
  const { enableDigitalSignature, enableAgentDirectContact, enableBudgetTool, enableSelectPlansOffRamp } = featureFlags;

  const [isLoading, setIsLoading] = useState(false);
  const [showEsign, setShowEsign] = useState(false);

  const eSignRef = useRef<HTMLDivElement | null>(null);

  const shouldDirectConnect = enableAgentDirectContact && isWithinServicerHours;

  const handleNavigation = async () => {
    if (isDesktop) {
      closeModal();
    } else {
      window.location.href = `tel:${getServicerPhoneNumber()}`;
      if (redirectHomePageDelay) {
        setTimeout(closeModal, redirectHomePageDelay * 1000);
      }
    }
  };

  const handleDirectConnect = async () => {
    const promises = [profileStore.updatePercentageCompleteAndLastPageCompleted(100, VTPageTitlesEnum.SELECT_PLAN)];

    if (onSubmit && !profileStore.isEnrollmentComplete) promises.push(methods?.handleSubmit(onSubmit)());

    await Promise.all(promises);

    if (profileStore.profile?.id && profileStore.isEnrollmentComplete && !isDesktop) {
      await profileStore.updateUserProfileAPI(profileStore.profile.id, {
        callActionEnrolled: true,
      });
      profileStore.invokeSweeper(profileStore.profile.id);
    } else if (profileStore.profile?.id && profileStore.isEnrollmentComplete && isDesktop) {
      profileStore.invokeSweeper(profileStore.profile.id);
    }
  };

  const handleCallLater = async () => {
    handlePercentageAndPageCompleted(enableBudgetTool, VTPageTitlesEnum.SELECT_PLAN, profileStore);

    if (onSubmit && !profileStore.isEnrollmentComplete) {
      methods?.handleSubmit(onSubmit)();
    }

    openModal("MODULE_PLAN_SELECTED");
  };

  const handleSubmit = async (directConnect?: boolean) => {
    try {
      setIsLoading(true);
      if (directConnect) {
        GTMAgentConnectAttempted();
        await handleDirectConnect();
        handleNavigation();
        if (!isDesktop) {
          GTMUserCallNowClicked(document.title, "positive");
        }
      } else {
        handleCallLater();
      }
    } catch (error) {
      rollbar.error(`Error in Select Plan: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleESign = (skip?: boolean) => {
    const { documents } = profileStore.profile ?? {};
    const documentStatus = documents?.NTLOI?.documentStatus;
    const directConnect = skip ? false : shouldDirectConnect;

    if (enableDigitalSignature && documentStatus !== "signed") {
      setShowEsign(true);
    } else {
      handleSubmit(directConnect);
    }
  };

  return (
    <div className={classes.footer}>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {/* E-Sign Wrapper */}
        {isDesktop ? (
          <Drawer
            anchor="right"
            open={showEsign && !showExtendModal}
            onClose={() => {
              setShowEsign(false);
              setTimeout(() => setDeclineAgentDirectContact(false), 225);
            }}
            PaperProps={{
              sx: {
                width: "400px",
                padding: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <Box sx={{ maxWidth: "100%" }}>
              <ESign handleSubmit={handleSubmit} isLoading={isLoading} t={t} />
            </Box>
          </Drawer>
        ) : (
          <Drawer
            open={showEsign && !showExtendModal}
            anchor="bottom"
            onClose={() => {
              setShowEsign(false);
              setTimeout(() => setDeclineAgentDirectContact(false), 225);
            }}
          >
            <Box
              ref={eSignRef}
              sx={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box sx={{ maxWidth: "100%" }}>
                <ESign handleSubmit={(directConnect) => handleSubmit(directConnect)} isLoading={isLoading} t={t} />
                <FooterLegalInfo />
              </Box>
            </Box>
          </Drawer>
        )}
        {/* End E-Sign Wrapper */}
        <Box
          sx={{
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className={classes.footerButtonContainer}>
            {shouldDirectConnect ? (
              <>
                {enableSelectPlansOffRamp && (
                  <ButtonBranded
                    data-cy="callMeButton"
                    variant="textonly"
                    id="direct-connect-call-me-later-button"
                    fontSize={theme.fontSizes.medium}
                    fontWeight={theme.fontWeights.medium}
                    lineHeight={theme.fontLineHeights.xxxsmall}
                    className={classes.debtReviewCallButton}
                    onClick={() => {
                      GTMPlanSubmitted("off-ramp");
                      setDeclineAgentDirectContact(true);
                      handleESign(true);
                    }}
                  >
                    {t("footer.secondaryButtonLabel")}
                  </ButtonBranded>
                )}
                <div>
                  <ButtonBranded
                    loading={isLoading}
                    data-cy="select-plan-button"
                    className={classes.nextActionButton}
                    type={"submit"}
                    variant={"primary"}
                    onClick={() => {
                      if (isDesktop) {
                        GTMPlanSubmitted("okay");
                      } else {
                        handleESign();
                      }
                      handleESign();
                    }}
                  >
                    {isDesktop ? t("footer.desktopPrimaryButtonLabel") : t("footer.mobilePrimaryButtonLabel")}
                  </ButtonBranded>
                </div>
              </>
            ) : (
              <div>
                <ButtonBranded
                  loading={isLoading}
                  id="call-me-later-button"
                  data-cy="select-plan-button"
                  className={classes.nextActionButton}
                  type={"submit"}
                  variant={"primary"}
                  onClick={() => {
                    GTMPlanSubmitted("okay");
                    handleESign();
                  }}
                >
                  {t("footer.callMeLater")}
                </ButtonBranded>
              </div>
            )}

            {!isDesktop && (
              <Box mt={2} sx={{ position: "relative" }}>
                <NextStep />
              </Box>
            )}

            <ConfirmationModalView
              isOpen={showOffRampModal}
              handlePrimary={async () => {
                GTMOffRampConfirmed("Select-Plan");
                setShowOffRampModal(false);
                await profileStore.updateEnrollmentStatus(ProfileSubStatusEnum.IDA_DECLINED_SELECTION);
                openModal(CTAModulesEnum["plan-selected"]);
              }}
              handleSecondary={() => setShowOffRampModal(false)}
              handleCancel={() => setShowOffRampModal(false)}
              name={"select-plan"}
            />
          </div>
        </Box>
      </Box>

      <FooterLegalInfo />
    </div>
  );
});

export default SelectPlanFooter;
