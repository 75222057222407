import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { NavItemOrientation } from ".";

type Props = {
  active?: boolean;
  accentColor?: string;
  orientation?: NavItemOrientation;
};

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "fit-content",
    height: "fit-content",
    zIndex: 1,
  },
  label: {
    marginLeft: (props: Props) => (props.orientation === "horizontal" ? "1rem !important" : "0"),
    marginTop: (props: Props) => (props.orientation === "horizontal" ? "0" : "0.5rem !important"),
  },
  button: {
    margin: `${0} !important`,
    padding: `${0} !important`,
    display: "flex",
    flexDirection: (props: Props) => (props.orientation === "horizontal" ? "row" : "column"),

    "&:hover": {
      backgroundColor: "transparent !important",
      "& $hoverCircle": {
        opacity: 1,
      },
      "& $icon": {
        color: theme.palette.common.black,
      },
      "& $label": {
        color: `${theme.palette.common.black} !important`,
      },
    },
  },
  icon: {
    width: "45px !important",
    height: "45px !important",
    color: (props: Props) => (props.active ? theme.palette.common.black : theme.palette.brandGray.main),
    [theme.breakpoints.up("md")]: {
      width: "40px !important",
      height: "40px !important",
    },
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  hoverCircle: {
    position: "absolute",
    width: "31px",
    height: "31px",
    backgroundColor: (props: Props) => props.accentColor || theme.palette.brandBlue.light,
    opacity: (props: Props) => (props.active ? 0.5 : 0),
    borderRadius: "50%",
    transition: "background-color 0.3s ease",
    zIndex: -1,
    [theme.breakpoints.up("md")]: {
      width: "28px",
      height: "28px",
    },
  },

  navDesktop: {
    "&.MuiTypography-root": {
      fontSize: theme.fontSizes.xlarge,
      fontWeight: theme.fontWeights.medium,
      lineHeight: theme.fontLineHeights.medium,
    },
  },

  navMobile: {
    "&.MuiTypography-root": {
      fontSize: theme.fontSizes.xxsmall,
      fontWeight: theme.fontWeights.semiBold,
      lineHeight: theme.fontLineHeights.medium,
    },
  },
}));
