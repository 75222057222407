import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  emailSliderBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "24px",
  },
  sliderContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  modalCard: {
    zIndex: "99",
    position: "absolute",
    bottom: 0,
    right: 0,
    height: "auto",
    width: "100%",
    borderRadius: "0 !important",
    boxShadow: "none !important",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      position: "relative",
      borderRadius: "16px !important",
      flexBasis: "50%",
    },
  },
  modalContainer: {
    padding: "16px 24px 24px 24px",
  },
  buttonPrimary: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "4px",
    },
  },
  buttonFormatting: {
    textWrap: "nowrap",
    [theme.breakpoints.up("md")]: {
      padding: "12px 24px",
      fontSize: theme.fontSizes.medium,
      fontWeight: theme.fontWeights.semiBold,
      lineHeight: theme.fontLineHeights.xxxsmall,
    },
  },
  disclaimerContainer: {
    padding: "16px",
    borderRadius: "4px !important",
    backgroundColor: theme.palette.brandGray.lighter,
  },
}));
