import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import houseMobile from "../../../assets/MyPlansReEntryImageMobile.jpg";
import house from "../../../assets/MyPlansReEntryImageOptimized.jpg";

type Props = {
  updateBackground: boolean;
};

export const useStyles = makeStyles((theme: Theme) => ({
  rootContainer: {
    width: "100%",
    display: "flex",
    height: "100%",
    position: "relative",
    alignItems: "flex-start",
    backgroundColor: theme.palette.common.white,
    backgroundImage: (props: Props) =>
      props.updateBackground
        ? "unset"
        : `linear-gradient(to top, white 65%, rgba(255, 255, 255, 0)), url(${houseMobile})`,
    overflowX: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center 111px",
    [theme.breakpoints.up("md")]: {
      backgroundImage: (props: Props) =>
        props.updateBackground
          ? "unset"
          : `linear-gradient(to top, white 40%, rgba(255, 255, 255, 0.5)), url(${house})`,
      backgroundColor: (props: Props) => (props.updateBackground ? "unset" : theme.palette.common.white),
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
  },

  cardContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.02) !important",
    height: "100%",
    "&.MuiContainer-root": {
      padding: 0,
      maxWidth: "900px",
    },
    [theme.breakpoints.up("md")]: {
      height: "unset",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "unset",
      "&.MuiContainer-root": {
        maxWidth: "900px",
      },
    },
  },
}));
