import { InfoOutlined } from "@mui/icons-material";
import { Box, Stack, Divider } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { patternFormatter } from "react-number-format";

import confetti from "@assets/confetti.svg";
import callNow from "@assets/selectedPlanCallLady.svg";
import { Text, Confetti } from "@components/common";
import { useStore } from "@stores";
import { getServicerLogo, getServicerPhoneNumber, isPlanSelectionAbandoned, formatToNearestDollar } from "@utils";

interface PlanCardProps {
  months?: number;
  showConfetti?: boolean;
  showAnimatedConfetti?: boolean;
  savings?: number;
  displayTerms?: boolean;
  children?: React.ReactNode;
  showStatus?: boolean;
}

/** A scaffold for the PlanCardByJourney component. Use that compoent if trying to display plan data */

export const PlanCard = ({
  months,
  showConfetti = false,
  showAnimatedConfetti = false,
  savings,
  displayTerms = false,
  children,
  showStatus,
}: PlanCardProps) => {
  const [isDeclined, setIsDeclined] = useState(false);

  const { t } = useTranslation("plan_option");
  const animateConfetti =
    useRef(!localStorage.getItem("hasSeenConfetti")).current && showConfetti && showAnimatedConfetti;

  const { profileStore } = useStore();
  const servicer = profileStore.profile!.servicer!.id;
  const servicerLogo = getServicerLogo(servicer!);

  const phoneNumber = patternFormatter(getServicerPhoneNumber().replace(/\D/g, ""), {
    format: "#-###-###-####",
  });

  useEffect(() => {
    if (showStatus && profileStore.profile) {
      setIsDeclined(isPlanSelectionAbandoned(profileStore.profile));
    }
  }, [profileStore.profile, showStatus]);

  return (
    <Box
      borderRadius={2}
      width={1}
      boxShadow={(theme) => theme.customShadows.clickable}
      bgcolor="white"
      sx={{
        backgroundImage: !animateConfetti && showConfetti ? `url("${confetti}")` : "",
        backgroundRepeat: "repeat-x",
      }}
    >
      {showStatus && (
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          padding="8px 10px"
          bgcolor={(theme) => theme.palette.brandDarkBlue.other}
          sx={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Box bgcolor={(theme) => theme.palette.brandYellow.main} height="10px" width="10px" borderRadius="50%" />
          <Text color="white" fontSize="xsmall" fontWeight="semiBold" data-cy="plan-card-heading">
            {isDeclined ? t("idaDeclined") : t("pending")}
          </Text>
        </Box>
      )}
      <Box position="relative">
        <Stack sx={{ gap: 1, mb: showConfetti ? 2 : 0, p: 2, position: "relative", zIndex: 1 }}>
          <Box textAlign="center">
            <Text
              display="inline-block"
              padding={1}
              borderRadius={1}
              fontWeight="bold"
              color="white"
              textTransform="uppercase"
              fontSize="small"
              bgcolor={(theme) => theme.palette.brandGreen.dark}
            >
              {t("planCard.debtFree", { months })}
            </Text>
          </Box>
          {savings && (
            <Box textAlign="center">
              <Box display="inline-block" bgcolor="white" px={1}>
                <Text fontWeight="bold" textTransform="uppercase" mb={0.75} component="span">
                  {displayTerms && "*"}
                  {t("planCard.estimatedSavings")}
                </Text>
                <br />
                <Box position="relative" display="inline-block">
                  <Text
                    fontSize="xxlarge"
                    position="absolute"
                    top="5px"
                    left="-17px"
                    component="span"
                    fontWeight="bold"
                    sx={{ color: (theme) => `${theme.palette.brandGreen.dark}CC` }}
                  >
                    $
                  </Text>
                  <Text sx={{ fontSize: "68px", lineHeight: 1 }} fontWeight="bold" component="span" color="green">
                    {formatToNearestDollar(savings)}
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
        </Stack>
        {animateConfetti && <Confetti />}
      </Box>

      {children}
      {showStatus && <Divider />}
      <Stack sx={{ p: 2, gap: 2 }}>
        {showStatus ? (
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Stack gap={1.5}>
              <Text fontWeight="bold">{t("selectedPlanNextStep.header")}</Text>
              <Text lineHeight="small">{t("selectedPlanNextStep.description")}</Text>
              <Text fontWeight="bold">
                {t("selectedPlanNextStep.call", {
                  phoneNumber,
                })}
              </Text>
            </Stack>
            <img src={callNow} />
          </Box>
        ) : (
          <>
            {displayTerms && (
              <Box display="flex" alignItems="center" gap={1.5}>
                <InfoOutlined />

                <Box display="flex" alignItems="start" gap={0.5}>
                  <Text fontSize="small" color="black" fontWeight="bold" sx={{ mt: 0.5 }}>
                    *
                  </Text>
                  <Text fontSize="xxsmall" lineHeight="xxxsmall" color="black">
                    <Trans i18nKey={`planCard.dmp.terms`} ns="plan_option" values={{ length: months }} />
                  </Text>
                </Box>
              </Box>
            )}
            <Text
              fontSize="xsmall"
              fontWeight="medium"
              color="gray"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              {t("planCard.planServicedBy")}
              <img src={servicerLogo} alt="servicerLogo" height="24px" />
            </Text>
          </>
        )}
      </Stack>
    </Box>
  );
};
